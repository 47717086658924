<template>
  <div class="menu wrap">
    <router-view  ref="ChildmainDevice"/>
     <div style="width:100%;height:80px; position: fixed; bottom: 0;" v-loading="loading"  element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.8)"></div>
   <el-dialog
        title="安全提示"
        :visible.sync="IPdialogVisible"
        width="30%"
        :before-close="handleClose">
        <span>该账户已在{{IP}}登录，请确认是否本人操作</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="signout">确 定</el-button>
        </span>
      </el-dialog>
  </div>
  
</template>

<script>
import { formatDate,formatDate2 } from "@/common/common.js";
export default {
  name: "Pages",
  props: {
    msg: String,
  },
  data() {
    return {
        loading:false,
        IP:'',
        IPdialogVisible:false,
        NewList:[]
    };
  },
  created() {},
  mounted(){
     this.initWebSocket()
  },
  methods: {
   initWebSocket: function () {
      if(this.websock) {
        this.websock.close()
      }else {
      }
        // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
        console.log("调用了链接websock  ，获取的用户id为   ："+JSON.parse(localStorage.getItem("loginData")).OwnGuid)
        var url = this.websockUrl
        console.log(url);
        this.websock = new WebSocket(url);
        this.websock.onopen = this.websocketOnopen;
        this.websock.onerror = this.websocketOnerror;
        this.websock.onmessage = this.websocketOnmessage;
        this.websock.onclose = this.websocketOnclose;
      },
      websocketOnopen: function () {
        console.log("WebSocket连接成功");
        //心跳检测重置
        //this.heartCheck.reset().start();
        // var OwnGuid = JSON.parse(localStorage.getItem("loginData")).OwnGuid
        var LoginKey = JSON.parse(localStorage.getItem("loginData")).LoginKey
        this.websocketSend(LoginKey)
      },
      websocketOnerror: function (e) {
        console.log("WebSocket连接发生错误");
        this.reconnect();
      },
      websocketOnmessage: function (e) {
      	 console.log('监听关闭' + e.data)
        //  var list = eval("(" + e.data + ")"); //解析对象
         var list = JSON.parse(e.data)
        console.log("-----接收消息-------", e.data,);
        if(list.Ponline == 404) {
          this.IP = list.ID
          this.IPdialogVisible = true
        } else if(list.Ponline == 0) {
              this.$message({
                message: "设备"+ list.ID + "已下线",
                type: "sunccess",
                duration: 2000,
              });
              this.$refs.ChildmainDevice.getDevice(1)
            }else if(list.Ponline == 1) {
              this.$message({
                message: "设备"+  list.ID + "已上线",
                type: "sunccess",
                duration: 2000,
              });
              this.$refs.ChildmainDevice.getDevice(1)
            }else if(list.Ponline == -1) {
              this.$message({
                message: "设备"+  list.ID + "故障",
                type: "sunccess",
                duration: 2000,
              });
              this.$refs.ChildmainDevice.getDevice(1)
            }else {
              var dateTime = new Date();
              var that = this
              list.datas.forEach(function(item){
                if(item.value == "True") {
                   that.$store.state.DSBC[item.name] = true
                }else if (item.value == "False") {
                  that.$store.state.DSBC[item.name] = false
                }else {
                  if( item.name == "P2401" || item.name == "P2404" || item.name == "P2405" || item.name == "P2406" || item.name == "P2407" || item.name == "P2408" || item.name == "P2409"|| item.name == "P2101" || item.name == "P2102" || item.name == "P2201") {
                    that.$store.state.DSBC[item.name] = JSON.parse(item.value)
                  }else {
                     that.$store.state.DSBC[item.name] = item.value
                  }
                  
                }
                
                  var newS = "时间：" + formatDate2(dateTime) + ' ID：' + list.ID + ' 参数名：' + item.name + " 参数值：" +  item.value 
                 that.NewList.unshift(newS)
                 that.$store.commit("newList",that.NewList);
                console.log(that.$store.state.DSBC[item.name],'bbbbbbbbbbbbbbb',item.name,that.$store.state.DSBC)
              }) 
            }
        
        
      },
      websocketOnclose: function (e) {
        console.log("connection closed (" + e.code + ")");
         this.$message({
            message: "接收到消息"+ e.code,
            type: "warning",
            duration: 1500,
          });
        
      },
      
      websocketSend(text) { // 数据发送
        try {
          this.websock.send(text);
        } catch (err) {
          console.log("send failed (" + err.code + ")");
        }
        console.log('发送',text)
      },

      reconnect() {
        var that = this;
        if(that.lockReconnect) return;
        that.lockReconnect = true;
        //没连接上会一直重连，设置延迟避免请求过多
        setTimeout(function () {
          console.info("尝试重连...");
          that.initWebSocket();
          that.lockReconnect = false;
        }, 5000);
      },
      signout() {
      this.$router.push({ path: "/" });
      localStorage.clear();
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  computed: {},
   beforeDestroy() {
    this.websock.close()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrap {
    ::v-deep .el-loading-spinner {
    margin-top: -35px;
  }
}
</style>
