<template>
  <div class="login wrap">
    <div class="loginBox boxsize">
      <el-row class="company_Title">用户登录</el-row>
      <el-row class="company_button">
        <el-form :ref="form" :model="form" :rules="rules">
          <el-form-item prop="username">
            <el-input v-model="form.username" placeholder="请输入账号">
              <i class="el-icon-user" slot="prefix"></i
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" style="margin: 20px 0 0 0 ">
            <el-input
              v-model="form.password"
              placeholder="请输入密码"
              show-password
              ><i class="el-icon-lock" slot="prefix"></i
            ></el-input>
          </el-form-item>
          <el-col style="font-size: 12px; height:56px;line-height:56px;">
            <el-checkbox
              v-model="checkLogin"
              style="float: left"
              @change="openLogin"
              >自动登录</el-checkbox
            >
            <el-checkbox v-model="checkPassword" @change="openPassword"
              >记住密码</el-checkbox
            >
            <label for="" style="float: right">找回密码</label>
          </el-col>
          <el-form-item>
            <el-button type="primary" @click="submitForm">登录</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "home",
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "账号不能为空！", trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空！",
            trigger: ["blur", "change"],
          },
        ],
      },
      checkLogin: false,
      checkPassword: false,
    };
  },
  created() {},
  mounted() {
    if (JSON.parse(localStorage.getItem('checkLogin')) == true && JSON.parse(localStorage.getItem('checkPassword'))  == true) {
        this.form.username =  JSON.parse(localStorage.getItem('username'))
        this.form.password =  JSON.parse(localStorage.getItem('password'))
        this.checkLogin =  JSON.parse(localStorage.getItem('checkLogin'))
        this.checkPassword =  JSON.parse(localStorage.getItem('checkPassword'))
        console.log(  JSON.parse(localStorage.getItem('checkLogin')), "7777777777777")
        this.submitForm()
      }else if ( JSON.parse(localStorage.getItem('checkPassword')) == true) {
        this.form.username =  JSON.parse(localStorage.getItem('username'))
        this.form.password =  JSON.parse(localStorage.getItem('password'))
        this.checkPassword =  JSON.parse(localStorage.getItem('checkPassword'))
        console.log(  JSON.parse(localStorage.getItem('checkLogin')),"66666666666")
      }
      if(this.$store.state.timer) {
        clearInterval(this.$store.state.timer);
        this.$store.state.timer = null;
      }
  },
  methods: {
    submitForm() {
      
      if (this.checkLogin == true && this.checkPassword == true) {
        localStorage.setItem("username", JSON.stringify(this.form.username));
        localStorage.setItem("password", JSON.stringify(this.form.password));
        localStorage.setItem("checkLogin", JSON.stringify(true));
        localStorage.setItem("checkPassword", JSON.stringify(true));
      }else if (this.checkPassword == true) {
        localStorage.setItem("username", JSON.stringify(this.form.username));
        localStorage.setItem("password", JSON.stringify(this.form.password));
        localStorage.setItem("checkLogin", JSON.stringify(false));
        localStorage.setItem("checkPassword", JSON.stringify(true));
      }
      localStorage.clear()
      this.$refs[this.form].validate((valid) => {
        if (valid) {
          
          const loading = this.$loading({
            lock: true,
            text: "登录中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });

          this.$axios
            .post("/api/main/Login/Submit", {
              username: this.form.username,
              password: this.form.password,
            })
            .then((res) => {
              if (res.data.Code == 0) {
                localStorage.setItem(
                  "loginData",
                  JSON.stringify(res.data.Data)
                );
                localStorage.setItem(
                  "Token",
                  JSON.stringify(res.data.Data.token)
                );
                this.$message({
                  message: "登录成功",
                  type: "success",
                  duration: 1500,
                });
                this.$router.push({ path: "/Weigh" });
                loading.close();
              } else {
                this.$message({
                  message: res.data.Message,
                  type: "warning",
                  duration: 1500,
                });
                loading.close();
              }
            })
            .catch(() => {
              loading.close();
              this.$message({
                type: "warning",
                message: "登录失败",
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    openLogin(value) {
      console.log(value, "666666666666");
      if (value == true) {
        this.checkPassword = true;
      } else {
        this.checkPassword = false;
      }
    },
    openPassword(value) {
      if (value == false) {
        this.checkLogin = false
      } 
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.wrap {
  background-image: url(../assets/login.jpg);
  background-size: 100% 100%;
  position: relative;
  .loginBox {
    width: 400px;
    height: 418px;
    position: absolute;
    left: 1280px;
    top: 340px;
    color: #333333;
    text-align: center;
  }
  .company_Title {
    background-color: #ffffff;
    height: 72px;
    font-size: 24px;
    text-align: center;
    padding: 20px 0 16px 0;
    box-sizing: border-box;
  }
  .company_button {
    margin-top: 6px;
    height: 340px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 30px 24px 0;
  }
  ::v-deep .el-form-item__label {
    font-size: 2.4rem;
    color: #333333;
  }
  ::v-deep .el-input,
  ::v-deep.el-input__inner {
    height: 56px;
    line-height: 56px;
  }
  ::v-deep .el-form-item__content {
    line-height: 56px;
  }
  .el-icon-user,
  .el-icon-lock {
    width: 54px;
    font-size: 32px;
    line-height: 56px;
  }
  ::v-deep .el-input__inner {
    padding: 0 0 0 56px;
    font-size: 2.4rem;
  }
  ::v-deep .el-form-item__error {
    font-size: 1.8rem;
  }
  ::v-deep .el-button--primary {
    width: 100%;
    font-size: 2.4rem;
  }
}

</style>
