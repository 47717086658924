
<template>
  <div class="register wrap">
    <el-row class="userButton">
      <el-col :span="6">设备管理</el-col>
      <el-col :span="8">
            <label for="">客户</label>
            <el-select
              v-model="form.AdminUserId"
              placeholder="请选择"
              @change="getSetAdminUserId()"
            >
              <el-option
                v-for="item in companyData"
                :key="item.OwnGuid"
                :label="item.FullName"
                :value="item.OwnGuid"
              >
              </el-option>
            </el-select>
        </el-col>
      <el-col :span="12" style="text-align: right"> </el-col>
    </el-row>
    <el-row class="userList">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        height="100%"
      >
        <el-table-column label="序号" type="index" width="70"></el-table-column>
        <el-table-column prop="ID" label="设备ID"></el-table-column>
        <el-table-column prop="FullName" label="设备名称"> </el-table-column>
        <!-- <el-table-column prop="Phone" label="手机号"> </el-table-column> -->
        <el-table-column prop="Company" label="公司"> </el-table-column>
        <el-table-column prop="UserName" label="用户"> </el-table-column>
        <!-- <el-table-column prop="Addr" label="地址"> </el-table-column> -->
        <el-table-column prop="Disable" label="禁用">
          <template slot-scope="scope">
            <span v-if="scope.row.Disable == true">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="openDetail(scope.$index, scope.row)"
              >详情</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="branch(scope.$index, scope.row)"
              >分配</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pageSizes">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        background
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="Count"
      >
      </el-pagination>
    </el-row>
    <el-dialog
      title="分配"
      :visible.sync="centerDialogVisible"
      width="25%"
      center
      @close="closed"
    >
      <el-form
        :model="form"
        status-icon
        :rules="rules"
        label-width="0"
        :ref="'form'"
        class="dialogbox"
      >
        <el-col>
          <el-form-item prop="ID">
            <label for="">设备ID</label>
            <el-select
              v-model="form.ID"
              placeholder="请选择"
              @change="getIdDevice"
            >
              <el-option
                v-for="item in tableData"
                :key="item.ID"
                :label="item.ID"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        
        <el-col>
          <el-form-item prop="UserId">
            <label for="">用户</label>
            <el-select v-model="form.UserId" placeholder="请选择">
              <el-option
                v-for="item in userData"
                :key="item.OwnGuid"
                :label="item.FullName"
                :value="item.OwnGuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="FullName">
            <label for="">设备名称</label>
            <el-input
              placeholder="请输入用户名"
              style="width: 247px"
              v-model="form.FullName"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="Disable">
            <label for="">禁用</label>
            <el-switch
              v-model="form.Disable"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closed">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="详情"
      :visible.sync="detailDialogVisible"
      width="25%"
      center
      @close="closed"
    >
      <el-form
        :model="form2"
        status-icon
        label-width="0"
        :ref="'form2'"
        class="dialogbox"
      >
        <el-col>
          <el-form-item prop="ID">
            <label for="">设备ID</label>
            <el-input
              placeholder="请输入设备ID"
              style="width: 300px"
              v-model="form2.ID"
              disabled
            >
            </el-input>
          </el-form-item>
        </el-col>
        
        <el-col>
          <el-form-item prop="FullName">
            <label for="">设备名称</label>
            <el-input
              placeholder="请输入用户名"
              style="width: 300px"
              v-model="form2.FullName"
              disabled
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="Phone">
            <label for="">手机号</label>
            <el-input
              placeholder="请输入手机号"
              style="width: 300px"
              v-model="form2.Phone"
              disabled
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="Company">
            <label for="">公司</label>
            <el-input
              placeholder="请输入公司"
              style="width: 300px"
              v-model="form2.Company"
              disabled
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="Addr">
            <label for="">地址</label>
            <el-input
              placeholder="请输入地址"
              style="width: 300px"
              v-model="form2.Addr"
              disabled
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="UserName">
            <label for="">用户</label>
            <el-input
              placeholder="请输入用户"
              style="width: 300px"
              v-model="form2.UserName"
              disabled
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="Disable">
            <label for="">禁用</label>
            <el-switch
              v-model="form2.Disable"
              active-color="#13ce66"
              inactive-color="#ff4949"
              disabled
            >
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="Status">
            <label for="">状态</label>
             <el-input
              placeholder="请输入用户"
              style="width: 300px"
              v-model="form2.Status"
              disabled
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="CreateDate">
            <label for="">上线</label>
             <el-input
              placeholder="请输入用户"
              style="width: 300px"
              v-model="form2.CreateDate"
              disabled
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="UpdateDate">
            <label for="">下线</label>
            <el-input
              placeholder="请输入用户"
              style="width: 300px"
              v-model="form2.UpdateDate"
              disabled
            >
            </el-input>
            
          </el-form-item>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <div style="width:100%;height:80px; position: absolute; bottom: 0;" v-loading="loading"  element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.8)"></div>
  </div>
</template>
<script>
import { formatDate,formatDate2 } from "@/common/common.js";
export default {
  name: "Manage",
  props: {
    msg: String,
  },
  data() {
    return {
      loading:false,
      currentPage: 1,
      pagesize: 10,
      Count:0,
      tableData: [], //设备列表
      companyData: [], //客户列表
      userData: [], //用户列表
      form: {
        ID: "", //设备ID
        AdminUserId: "", //客户ID
        UserId: "", //用户ID
        FullName: "", //设备名称
        Disable: false, //是否启用

      },
      
      rules: {
        ID: [{ required: true, message: "请选择设备ID", trigger: "blur" }],
        UserId: [{ required: true, message: "请选择用户", trigger: "blur" }],
        FullName: [
          { required: true, message: "请输入设备名称", trigger: "blur" },
        ],
      },
      form2:{
        ID:"",
        FullName:"",
        Phone:"",
        Company:"",
        Addr:"",
        UserName:"",
        Disable:"",
        CreateDate:"",
        UpdateDate:"",
        Status:"",
      },
      centerDialogVisible: false,
      detailDialogVisible:false
    };
  },
  created() {},
  mounted() {
    this.getDevice(0);
    this.getCompany();
    this.getUser()
  },
  methods: {
    warnNewing(value) {
      var NewList = this.$store.state.newList
      var dateTime = new Date();
      var newS = "时间：" + formatDate2(dateTime) + ' 请求信息' + value
      NewList.unshift(newS)
      this.$store.commit("newList",NewList);
    },
    // 保存
    save() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$myaxios("post", "/api/main/common/ExecuteSqlTable", {
        table: "Table:System_SetDevice",
        moduleName: "System",
        dynParmRules: [
          {
            field: "@ID",
            value: this.form.ID,
            type: "string",
          },
          {
            field: "@FullName",
            value: this.form.FullName,
            type: "string",
          },
          {
            field: "@OpUserId",
            value: this.form.UserId,
            type: "string",
          },
          {
            field: "@Disable",
            value: this.form.Disable,
            type: "string",
          },
        ],
      }).then((res) => {
        if (res.data.Code == 0) {
          if(JSON.parse(localStorage.getItem("loginData")).UserName  == 'admin' || JSON.parse(localStorage.getItem("loginData")).UserName  == 'dpt' || JSON.parse(localStorage.getItem("loginData")).UserName  == 'highsor' || JSON.parse(localStorage.getItem("loginData")).UserName == 'haishuo') {
            this.$message({
            message: "保存成功！",
            type: "success",
            duration: 1500,
          });
          }
          this.closed()
          this.getDevice(1)
        } else {
          if(res.data.Message == 'TokenError') {
            this.getBack()
            return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
          
        }
        this.loading = false
         this.warnNewing(res.data.Message)
      }).catch(() => {this.loading = false; });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 分配
    branch(index, row) {
      this.form.ID = row.ID //设备ID
      
      // this.form.AdminUserId = row.AdminUserId //客户ID
      this.form.FullName = row.FullName//设备名称
      this.form.Disable = row.Disable //是否启用
     
      // this.form.UserId = row.UserId //用户ID
      var arrays = [];
      (this.userData).forEach((item) => {
        arrays.push(item.OwnGuid)
      })
      if(arrays.indexOf(row.UserId) == -1) {
        this.form.UserId = ''
      }else {
        this.form.UserId = row.UserId
      }
       this.centerDialogVisible = true
    },
    closed() {
      this.form.ID = "" //设备ID
        this.form.UserId = "" //用户ID
        this.form.FullName = "" //设备名称
        this.form.Disable = false //是否启用
        this.centerDialogVisible = false
    },
    // 设备名称
    getIdDevice() {
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.form.ID == this.tableData[i].ID) {
          this.form.FullName = this.tableData[i].FullName;
          this.form.Disable = this.tableData[i].Disable;
        }
      }
    },
    // 获取客户
    getCompany() {
      this.loading = true
      this.$myaxios("post", "/api/main/common/GeneralDynamicPageList", {
        SearchRules: [
          {
            op: "like",
            field: "CreateUserId",
            value: JSON.parse(localStorage.getItem("loginData")).OwnGuid,
            type: "string",
          },
        ],
        moduleName: "System",
        table: "Table:System_GetAllUser",
        orderby: "UserName",
        pageNumber: 1,
        rowsPerPage: 1000,
      }).then((res) => {
        var data = JSON.parse(res.data.Data);
        var data = JSON.parse(res.data.Data);
        var object = {
          OwnGuid: "",
          FullName: "全部",
        };
        data.unshift(object);
        if (res.data.Code == 0) {
          this.companyData = data;
        } else {
           if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
         
        }
        this.loading = false
         this.warnNewing(res.data.Message)
      }).catch(() => {this.loading = false; });
    },
    // 获取用户
    getUser() {
      this.loading = true
      this.$myaxios("post", "/api/main/common/GeneralDynamicPageList", {
        SearchRules: [
          {
            op: "like",
            field: "CreateUserId",
            value: this.form.AdminUserId,
            type: "string",
          },
        ],
        moduleName: "System",
        table: "Table:System_GetAllUser",
        orderby: "UserName",
        pageNumber: 1,
        rowsPerPage: 1000,
      }).then((res) => {
        var data = JSON.parse(res.data.Data);
        var array = []
        data.forEach((item) => {
              if (item.field == "UserId") {
                array.push(item);
              }
            });
        if (res.data.Code == 0) {
          this.userData = array;
        } else {
           if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
        }
        this.loading = false
         this.warnNewing(res.data.Message)
      }).catch(() => {this.loading = false; });
    },
    getSetAdminUserId () {
      this.getDevice(1)
      this.getUser();
    },
    // 获取设备名称
    getDevice(value) {
      var seachData;
      if (value == 0) {
        seachData = [
          {
            op: "like",
            field:  JSON.parse(localStorage.getItem("loginData")).Field,
            value: JSON.parse(localStorage.getItem("loginData")).OwnGuid,
            type: "string",
          },
        ];
      } else {
        seachData = [
          {
            op: "like",
            field: JSON.parse(localStorage.getItem("loginData")).Field == "CreateUserId" ? "AdminUserId" : "UserId",
            value: this.form.AdminUserId,
            type: "string",
          },
        ];
      }
      this.$nextTick(() => { 
        this.loading = true
        this.$myaxios("post", "/api/main/common/GeneralDynamicPageList", {
        moduleName: "System",
        table: "Table:System_GetAllDevices",
        orderby: "FullName",
        pageNumber: this.currentPage,
        rowsPerPage: this.pagesize,
        SearchRules: seachData
      }).then((res) => {
        var data = JSON.parse(res.data.Data);
        if (res.data.Code == 0) {
          this.tableData = data;
          this.Count = res.data.Count
        } else {
           if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
        }
        this.loading = false
         this.warnNewing(res.data.Message)
      }).catch(() => {this.loading = false; });
      })
      
    },
    // 选择每页多少条
    handleSizeChange(val) {
      this.pagesize = val;
      this.getDevice();
    },
    // 选择第几页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDevice();
    },
    // 详情
    openDetail(index, row) {
      console.log(row)
        this.form2.ID = row.ID
        this.form2.FullName = row.FullName
        this.form2.Phone = row.Phone
        this.form2.Company = row.Company
        this.form2.Addr = row.Addr
        this.form2.UserName = row.UserName
        this.form2.Disable = row.Disable
        this.form2.CreateDate = row.CreateDate.replace("T"," ")
        this.form2.UpdateDate = row.UpdateDate.replace("T"," ")
        if(row.Ponline == true) {
          if(row.P1004 == '正常') {
            this.form2.Status = '在线'
          }else {
            this.form2.Status = '在线故障'
          }
        }else {
          if(row.P1004 == '正常') {
            this.form2.Status = '下线'
          }else {
            this.form2.Status = '下线故障'
          }
        }
        
      this.detailDialogVisible = true
    },
    getBack() {
       this.$message({
        message: '该账户已在其他地方登录，请重新登录',
        type: "warning",
        duration: 1500,
      });
      this.$router.push({ path: "/" });
      localStorage.clear();
    }
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrap {
  // padding: 40px;
  width: 100%;
  position: relative;
  .userList {
    height: calc(100% - 110px);
  }
  .pageSizes {
    height: 35px;
    text-align: center;
     z-index: 1200;
  }
  label {
    display: inline-block;
    width: 100px !important;
  }
  ::v-deep .el-loading-spinner {
    margin-top: -35px;
  }
  .el-input,::v-deep .el-select .el-input__inner {
    font-size: 18px;
    color: #333333;
  }
}
</style>