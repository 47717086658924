<template>
  <div class="debugging wrap">
    <el-row class="weigh_box">
      <el-col :span="6" class="box_left">
        <el-menu
          :default-active="defaultactive"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
          <el-menu-item index="1">
            <span slot="title">信息</span>
            <img  src="@/assets/iconRightMi.png"  alt="" v-if="boxdialogVisible == 1" style="float:right;margin:23px 0"  />
            <img  src="@/assets/iconRightAn.png"  alt="" v-else  style="float:right;margin:23px 0" />
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title">重量码</span>
            <img  src="@/assets/iconRightMi.png"  alt="" v-if="boxdialogVisible == 2" style="float:right;margin:23px 0"  />
            <img  src="@/assets/iconRightAn.png"  alt="" v-else  style="float:right;margin:23px 0" />
          </el-menu-item>
          <el-menu-item index="3">
            <span slot="title">地址</span>
            <img  src="@/assets/iconRightMi.png"  alt="" v-if="boxdialogVisible == 3" style="float:right;margin:23px 0"  />
            <img  src="@/assets/iconRightAn.png"  alt="" v-else  style="float:right;margin:23px 0" />
          </el-menu-item>
          <el-menu-item index="4">
            <span slot="title">调角</span>
            <img  src="@/assets/iconRightMi.png"  alt="" v-if="boxdialogVisible == 4" style="float:right;margin:23px 0"  />
            <img  src="@/assets/iconRightAn.png"  alt="" v-else  style="float:right;margin:23px 0" />
          </el-menu-item>
          <el-menu-item index="5">
            <span slot="title">标定</span>
            <img  src="@/assets/iconRightMi.png"  alt="" v-if="boxdialogVisible == 5" style="float:right;margin:23px 0"  />
            <img  src="@/assets/iconRightAn.png"  alt="" v-else  style="float:right;margin:23px 0" />
          </el-menu-item>
          <!-- <el-menu-item index="7">
            <span slot="title">滤波</span>
          </el-menu-item> -->
          <!-- <el-menu-item index="6">
            <span slot="title">功能</span>
          </el-menu-item> -->
          <!-- <el-menu-item index="8">
            <span slot="title">时钟</span>
          </el-menu-item> -->
          <!-- <el-menu-item index="9">
            <span slot="title">密码</span>
          </el-menu-item> -->
          <el-menu-item index="10">
            <span slot="title">经典</span>
            <img  src="@/assets/iconRightMi.png"  alt="" v-if="boxdialogVisible == 10" style="float:right;margin:23px 0"  />
            <img  src="@/assets/iconRightAn.png"  alt="" v-else  style="float:right;margin:23px 0" />
          </el-menu-item>
        </el-menu>
      </el-col>
      <!-- 信息 -->
      <el-col :span="18" class="box-right" v-if="boxdialogVisible == 1">
        <div class="topbox"></div>
        <div class="bottombox">
          <el-tabs
            type="border-card"
            v-model="activeMessage"
            @tab-click="BiaoClick"
          >
            <el-tab-pane label="重量" name="activeMessageB">
              <el-col :span="8" class="zhidu">
                <label for="">毛重</label><br>
                <span>{{ this.$store.state.DSBC.P1111 }}</span>
                <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1111"
                    @blur="save('inputP1111')"
                    @input="open('inputP1111')"
                    ref="inputP1111"
                  ></el-input> -->
              </el-col>
              <el-col :span="8" class="zhidu">
                <label for="">皮重</label><br>
                <span>{{ this.$store.state.DSBC.P1112 }}</span>
                <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1112"
                    @blur="save('inputP1112')"
                    @input="open('inputP1112')"
                    ref="inputP1112"
                  ></el-input> -->
              </el-col>
              <el-col :span="8" class="zhidu">
                <label for="">净重</label><br>
                <span>{{ this.$store.state.DSBC.P1113 }}</span>
                <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1113"
                    @blur="save('inputP1113')"
                    @input="open('inputP1113')"
                    ref="inputP1113"
                  ></el-input> -->
              </el-col>
              <el-col :span="8" class="marbox zhidu">
                <label for="">重量</label><br>
                <span>{{ this.$store.state.DSBC.P1114 }}</span>
                <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1114"
                    @blur="save('inputP1114')"
                    @input="open('inputP1114')"
                    ref="inputP1114"
                  ></el-input> -->
              </el-col>
              <el-col :span="8" class="marbox zhidu">
                <label for="">重量码</label><br>
                <span>{{ this.$store.state.DSBC.P1115 }}</span>
              </el-col>
              <!-- <el-col :span="8" class="marbox zhidu">
                <label for="">内码</label><br>
                <span>{{ this.$store.state.DSBC.P1116 }}</span>
              </el-col> -->
            </el-tab-pane>
            <el-tab-pane label="传感器" name="activeMessageA">
              <el-row>
                <el-col :span="9" class="zhidu">
                  <label for="">个数</label><br>
                  <span>{{ this.$store.state.DSBC.P2301 }}</span>
                </el-col>
                <el-col :span="9" class="disabledBg">
                  <label for="">加密</label><br>
                  <el-select
                   style="background-color:#f5f5f5 !important;"
                    v-model="$store.state.DSBC.P2302"
                    placeholder=""
                    disabled
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="24" class="marbox zhidu">
                  <label for="">状态</label><br>
                  <span style="width: 590px">{{
                    this.$store.state.DSBC.P1201
                  }}</span>
                </el-col>
              </el-row>
            </el-tab-pane>

            <el-tab-pane label="标定" name="activeMessageC">
              <el-col :span="8" class="zhidu">
                <label for="">日期</label><br>
                <span>{{ this.$store.state.DSBC.P2421 }}</span>
              </el-col>
               <el-col :span="8" class="zhidu">
                <label for="">时间</label><br>
                <span>{{ this.$store.state.DSBC.P2422 }}</span>
              </el-col>
              <el-col :span="8" class="zhidu">
                <label for="">点数</label><br>
                <span>{{ this.$store.state.DSBC.P2430 }}</span>
              </el-col>
              <el-col :span="8" class="zhidu marbox" >
                <label for="">0#重量码</label><br>
                <span>{{ this.$store.state.DSBC.P2431 }}</span>
              </el-col>
              <el-col :span="12" class="zhidu marbox">
                <label for="">0#重量</label><br>
                <span>{{ this.$store.state.DSBC.P2432 }}</span>
              </el-col>
              <el-col :span="8" class="zhidu marbox">
                <label for="">1#重量码</label><br>
                <span>{{ this.$store.state.DSBC.P2433 }}</span>
              </el-col>
              <el-col :span="12" class="zhidu marbox">
                <label for="">1#重量</label><br>
                <span>{{ this.$store.state.DSBC.P2434 }}</span>
              </el-col>
              <el-col :span="8" class="zhidu marbox" v-if=" this.$store.state.DSBC.P2430 == 3">
                <label for="">2#重量码</label><br>
                <span>{{ this.$store.state.DSBC.P2435 }}</span>
              </el-col>
              <el-col :span="12" class="zhidu marbox" v-if=" this.$store.state.DSBC.P2430 == 3">
                <label for="">2#重量</label><br>
                <span>{{ this.$store.state.DSBC.P2436 }}</span>
              </el-col>
            </el-tab-pane>
            <el-tab-pane label="滤波" name="activeMessageD">
              <el-col :span="8" class="marbox zhidu">
                <label for="">方式</label><br>
                <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P2501"
                    @blur="save('inputP2501')"
                    @input="open('inputP2501')"
                    ref="inputP2501"
                  ></el-input> -->
                  <span>{{this.$store.state.DSBC.P2501}}</span>
              </el-col>
              <el-col :span="8" class="marbox zhidu">
                <label for="">强度</label><br>
                <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P2502"
                    @blur="save('inputP2502')"
                    @input="open('inputP2502')"
                    ref="inputP2502"
                  ></el-input> -->
                  <span>{{this.$store.state.DSBC.P2502}}</span>
              </el-col>
            </el-tab-pane>
            <el-tab-pane label="时钟" name="activeMessageE">
               <el-col :span="8" class="marbox zhidu">
                <label for="">日期</label><br>
                <span>{{this.$store.state.DSBC.P1021}}</span>
              </el-col>
              <el-col :span="8" class="marbox zhidu">
                <label for="">时间</label><br>
                <span>{{this.$store.state.DSBC.P1022}}</span>
              </el-col>
            </el-tab-pane>
            <el-tab-pane label="定时" name="activeMessageF">
              <el-col :span="8" class="marbox zhidu">
                <label for="">试用期</label><br>
                <!-- <el-input
                    placeholder="请输入内容"
                    style="font-size:20px;color:#000000;"
                    v-model="$store.state.DSBC.P2021"
                    @blur="save('inputP2021')"
                    @input="open('inputP2021')"
                    ref="inputP2021"
                  ></el-input> -->
                  <span>{{this.$store.state.DSBC.P2021}}</span>
                
              </el-col>
              <el-col :span="12" class="marbox zhidu">
                <label for="">剩余</label><br>
                <span>{{this.$store.state.DSBC.P1023}}</span>天
              </el-col>
              <el-col :span="8" class="marbox zhidu">
                <label for="">检定</label><br>
                <span>{{this.$store.state.DSBC.P2022}}</span>
                <!-- <el-input
                 style="font-size:20px;color:#000000;"
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P2022"
                    @blur="save('inputP2022')"
                    @input="open('inputP2022')"
                    ref="inputP2022"
                  ></el-input> -->
              </el-col>
              <el-col :span="12" class="marbox zhidu">
                <label for="">剩余</label><br>
                <span>{{this.$store.state.DSBC.P1024}}</span>天
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <!-- 重量码 -->
      <el-col :span="18" class="box-right" v-else-if="boxdialogVisible == 2">
        <div class="topbox"></div>
        <div class="bottombox">
          <el-tabs
            type="border-card"
            v-model="activeWeigh"
            @tab-click="BiaoClick"
          >
            <el-tab-pane label="初始" name="activeWeighA">
              <el-row>
                <el-col :span="8"  class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 1">
                  <label for="">1#</label><br>
                  <span>{{ this.$store.state.DSBC.P1211 }}</span>
                </el-col>
                <el-col :span="8"  class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 2">
                  <label for="">2#</label><br>
                  <span>{{ this.$store.state.DSBC.P1212 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1212"
                    @blur="save('inputP1203')"
                    @input="open('inputP1203')"
                    ref="inputP1203"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 3">
                  <label for="">3#</label><br>
                  <span>{{ this.$store.state.DSBC.P1213 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1213"
                    @blur="save('inputP1204')"
                    @input="open('inputP1204')"
                    ref="inputP1204"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 4">
                  <label for="">4#</label><br>
                  <span>{{ this.$store.state.DSBC.P1214 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1214"
                    @blur="save('inputP1205')"
                    @input="open('inputP1205')"
                    ref="inputP1205"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 5">
                  <label for="">5#</label><br>
                  <span>{{ this.$store.state.DSBC.P1215 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1215"
                    @blur="save('inputP1206')"
                    @input="open('inputP1206')"
                    ref="inputP1206"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 6">
                  <label for="">6#</label><br>
                  <span>{{ this.$store.state.DSBC.P1216 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1216"
                    @blur="save('inputP1206')"
                    @input="open('inputP1207')"
                    ref="inputP1207"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 7">
                  <label for="">7#</label><br>
                  <span>{{ this.$store.state.DSBC.P1217 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1217"
                    @blur="save('inputP1208')"
                    @input="open('inputP1208')"
                    ref="inputP1208"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 8">
                  <label for="">8#</label><br>
                  <span>{{ this.$store.state.DSBC.P1218 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1218"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 9">
                  <label for="">9#</label><br>
                  <span>{{ this.$store.state.DSBC.P1219 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1219"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 10">
                  <label for="">10#</label><br>
                  <span>{{ this.$store.state.DSBC.P1220 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1220"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 11">
                  <label for="">11#</label><br>
                  <span>{{ this.$store.state.DSBC.P1221 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1221"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 12">
                  <label for="">12#</label><br>
                  <span>{{ this.$store.state.DSBC.P1222 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1222"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
                 <el-col :span="24" class="marbox zhidu">
                  <label for="">总计</label><br>
                  <span>{{ this.$store.state.DSBC.P1210 }}</span>
                  
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1210"
                    @blur="save('inputP1230')"
                    @input="open('inputP1230')"
                    ref="inputP1230"
                  ></el-input> -->
                </el-col>
              </el-row>
              <el-row style="margin-top:40px">
                提示：重量已乘以角差系数
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="净重" name="activeWeighB">
              <el-row>
                <el-col :span="8"  class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 1">
                  <label for="">1#</label><br>
                  <span>{{ this.$store.state.DSBC.P1231 }}</span>
                </el-col>
                <el-col :span="8"  class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 2">
                  <label for="">2#</label><br>
                  <span>{{ this.$store.state.DSBC.P1232 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1232"
                    @blur="save('inputP1203')"
                    @input="open('inputP1203')"
                    ref="inputP1203"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 3">
                  <label for="">3#</label><br>
                  <span>{{ this.$store.state.DSBC.P1233 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1233"
                    @blur="save('inputP1204')"
                    @input="open('inputP1204')"
                    ref="inputP1204"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 4">
                  <label for="">4#</label><br>
                  <span>{{ this.$store.state.DSBC.P1234 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1234"
                    @blur="save('inputP1205')"
                    @input="open('inputP1205')"
                    ref="inputP1205"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 5">
                  <label for="">5#</label><br>
                  <span>{{ this.$store.state.DSBC.P1235 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1235"
                    @blur="save('inputP1206')"
                    @input="open('inputP1206')"
                    ref="inputP1206"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 6">
                  <label for="">6#</label><br>
                  <span>{{ this.$store.state.DSBC.P1236 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1236"
                    @blur="save('inputP1206')"
                    @input="open('inputP1207')"
                    ref="inputP1207"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 7">
                  <label for="">7#</label><br>
                  <span>{{ this.$store.state.DSBC.P1237 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1237"
                    @blur="save('inputP1208')"
                    @input="open('inputP1208')"
                    ref="inputP1208"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 8">
                  <label for="">8#</label><br>
                  <span>{{ this.$store.state.DSBC.P1238 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1238"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 9">
                  <label for="">9#</label><br>
                  <span>{{ this.$store.state.DSBC.P1239 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1239"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 10">
                  <label for="">10#</label><br>
                  <span>{{ this.$store.state.DSBC.P1240 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1240"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 11">
                  <label for="">11#</label><br>
                  <span>{{ this.$store.state.DSBC.P1241 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1241"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 12">
                  <label for="">12#</label><br>
                  <span>{{ this.$store.state.DSBC.P1242 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1242"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
                 <el-col :span="24" class="marbox zhidu">
                  <label for="">总计</label><br>
                   <span>{{ this.$store.state.DSBC.P1230 }}</span>
                   <el-button
                  type="primary"
                  style="margin:0 0 0 50px;"
                  @click="save('inputP1202')"
                  >置零</el-button
                >
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1230"
                    @blur="save('inputP1230')"
                    @input="open('inputP1230')"
                    ref="inputP1230"
                  ></el-input> -->
                </el-col>
              </el-row>
              <el-row style="margin-top:40px">
                提示：重量已乘以角差系数
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <!-- 地址 -->
      <el-col :span="18" class="box-right" v-else-if="boxdialogVisible == 3">
        <div class="topbox"></div>
        <div class="bottombox">
          <el-tabs
            type="border-card"
            v-model="activeDevice"
            @tab-click="BiaoClick"
          >
          <el-tab-pane label="个数" name="activeDeviceD">
              <el-col :span="8">
                <label for="">数量</label><br>
                <el-input
                  placeholder="请输入内容"
                  v-model="$store.state.DSBC.P2301"
                  @blur="save('inputP2301')"
                  @input="open('inputP2301')"
                  ref="inputP2301"
                ></el-input>
              </el-col>
            </el-tab-pane>
            <el-tab-pane label="自动" name="activeDeviceA">
               <el-row style="padding:26px 0 0 0">
                <el-row style="text-align:center" class="inputCent">
                    <el-input
                      placeholder="请输入内容"
                      v-model="$store.state.DSBC.P1002"
                      @blur="save('inputP1002')"
                      @input="open('inputP1002')"
                      ref="inputP1002"
                      style="width: 484px;text-align:center;font-size:48px; font-family: Bahnschrift;"
                    ></el-input>
                    <p
                      class="weigh_col weigh_p"
                      style="display: inline-block; margin: 0 0 0 30px"
                    >
                      <span
                        class="state"
                        style="background-color: #409eff"
                        v-if="$store.state.DSBC.P1101 == true"
                      >
                        <span></span>
                      </span>
                      <span class="state" v-else>
                        <span></span>
                      </span>
                      <label for="">稳定</label><br>
                    </p>
                </el-row>
                <el-row style="margin:55px 0 55px 0;text-align:center">
                  <i
                    class="el-icon-arrow-left"
                    style="
                      margin: 0 50px 0 0px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(36)"
                  ></i>
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                    "
                    @click="save(211)"
                    >进入</el-button
                  >
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                      margin: 0 65px;
                    "
                    @click="openbox"
                    >修改</el-button
                  >
                  <el-button
                    type="primary"
                    style="width: 96px; vertical-align: middle"
                    @click="save(13)"
                    >确认</el-button
                  >
                  <i
                    class="el-icon-arrow-right"
                    style="
                      margin: 0 0 0 50px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(39)"
                  ></i>
                </el-row>
              </el-row>
              <el-row style="height: 2px; background-color: #f4f4f4"></el-row>
              <el-row class="bottomNum">
                <el-col> 重量:{{ $store.state.DSBC.P1111 }} </el-col>
                <el-col style="margin: 12px 0;padding:0 0 0 40px;"> 重量码 </el-col>
                <el-col class="weightBottom" style="padding:0 0 0 40px;">
                  <p  v-if="this.$store.state.DSBC.P2301 >= 1">
                    <span>1#</span>
                    <span>{{ this.$store.state.DSBC.P1231 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 2">
                    <span>2#</span>
                    <span>{{ this.$store.state.DSBC.P1232 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 3">
                    <span>3#</span>
                    <span>{{ this.$store.state.DSBC.P1233 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 4">
                    <span>4#</span>
                    <span>{{ this.$store.state.DSBC.P1234 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2335 >= 5">
                    <span>5#</span>
                    <span>{{ this.$store.state.DSBC.P1235 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 6">
                    <span>6#</span>
                    <span>{{ this.$store.state.DSBC.P1236 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 7">
                    <span>7#</span>
                    <span>{{ this.$store.state.DSBC.P1237 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 8">
                    <span>8#</span>
                    <span>{{ this.$store.state.DSBC.P1238 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 9">
                    <span>9#</span>
                    <span>{{ this.$store.state.DSBC.P1239 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 10">
                    <span>10#</span>
                    <span>{{ this.$store.state.DSBC.P1240 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 11">
                    <span>11#</span>
                    <span>{{ this.$store.state.DSBC.P1241 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 12">
                    <span>12#</span>
                    <span>{{ this.$store.state.DSBC.P1242 }}</span>
                  </p>
                </el-col>
                <el-col  class="weightBottom" style="padding:0 0 0 40px;margin:12px 0;">
                  <p>
                    <span>总计</span>
                    <span>{{ this.$store.state.DSBC.P1230 }}</span>
                  </p>
                </el-col>
              </el-row>
              <!-- <el-row style="padding: 0 0 20px 0">
                <el-row>
                  <el-col :span="8">
                    <label for="">状态</label>
                    <span>{{ $store.state.DSBC.P1004 }}</span>
                  </el-col>
                  <el-col :span="12">
                    <label for="">输入</label>
                    <el-input
                      placeholder="请输入内容"
                      v-model="$store.state.DSBC.P1008"
                      @blur="save('inputP1008')"
                      @input="open('inputP1008')"
                      ref="inputP1008"
                    ></el-input>
                  </el-col>
                </el-row>
                <el-row class="marbox">
                  <el-col :span="8">
                    <label for="">显示</label>
                    <span>{{ this.$store.state.DSBC.P1002 }}</span>
                  </el-col>
                  <el-col :span="12">
                    <label for="">提示</label>
                    <span>{{ $store.state.DSBC.P1003 }}</span>
                  </el-col>
                </el-row>
              </el-row>
              <el-row style="height: 2px; background-color: #f4f4f4"></el-row>
               <el-row class="bottomNum">
                <el-col> 重量:{{ $store.state.DSBC.P1111 }} </el-col>
                <el-col style="margin: 12px 0;padding:0 0 0 40px;"> 重量码 </el-col>
                <el-col class="weightBottom" style="padding:0 0 0 40px;">
                  <p  v-if="this.$store.state.DSBC.P2301 >= 1">
                    <span>1#</span>
                    <span>{{ this.$store.state.DSBC.P1231 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 2">
                    <span>2#</span>
                    <span>{{ this.$store.state.DSBC.P1232 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 3">
                    <span>3#</span>
                    <span>{{ this.$store.state.DSBC.P1233 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 4">
                    <span>4#</span>
                    <span>{{ this.$store.state.DSBC.P1234 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2335 >= 5">
                    <span>5#</span>
                    <span>{{ this.$store.state.DSBC.P1235 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 6">
                    <span>6#</span>
                    <span>{{ this.$store.state.DSBC.P1236 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 7">
                    <span>7#</span>
                    <span>{{ this.$store.state.DSBC.P1237 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 8">
                    <span>8#</span>
                    <span>{{ this.$store.state.DSBC.P1238 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 9">
                    <span>9#</span>
                    <span>{{ this.$store.state.DSBC.P1239 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 10">
                    <span>10#</span>
                    <span>{{ this.$store.state.DSBC.P1240 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 11">
                    <span>11#</span>
                    <span>{{ this.$store.state.DSBC.P1241 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 12">
                    <span>12#</span>
                    <span>{{ this.$store.state.DSBC.P1242 }}</span>
                  </p>
                </el-col>
                <el-col  class="weightBottom" style="padding:0 0 0 40px;margin:12px 0;">
                  <p>
                    <span>总计</span>
                    <span>{{ this.$store.state.DSBC.P1230 }}</span>
                  </p>
                </el-col>
              </el-row>
              <el-row style="height: 2px; background-color: #f4f4f4"></el-row>
              <el-row style="text-align: right; padding: 40px 0 0 0">
                <el-button
                  type="primary"
                  style="width: 136px"
                  @click="save('inputP1008')"
                  >确定</el-button
                >
              </el-row> -->
            </el-tab-pane>
            <el-tab-pane label="压角" name="activeDeviceB">
               <el-row style="padding:26px 0 0 0">
                <el-row style="text-align:center" class="inputCent">
                    <el-input
                      placeholder="请输入内容"
                      v-model="$store.state.DSBC.P1002"
                      @blur="save('inputP1002')"
                      @input="open('inputP1002')"
                      ref="inputP1002"
                      style="width: 484px;text-align:center;font-size:48px; font-family: Bahnschrift;"
                    ></el-input>
                    <p
                      class="weigh_col weigh_p"
                      style="display: inline-block; margin: 0 0 0 30px"
                    >
                      <span
                        class="state"
                        style="background-color: #409eff"
                        v-if="$store.state.DSBC.P1101 == true"
                      >
                        <span></span>
                      </span>
                      <span class="state" v-else>
                        <span></span>
                      </span>
                      <label for="">稳定</label><br>
                    </p>
                </el-row>
                <el-row style="margin:55px 0 55px 0;text-align:center">
                  <i
                    class="el-icon-arrow-left"
                    style="
                      margin: 0 50px 0 0px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(36)"
                  ></i>
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                    "
                    @click="save(212)"
                    >进入</el-button
                  >
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                      margin: 0 65px;
                    "
                    @click="openbox"
                    >修改</el-button
                  >
                  <el-button
                    type="primary"
                    style="width: 96px; vertical-align: middle"
                    @click="save(13)"
                    >确认</el-button
                  >
                  <i
                    class="el-icon-arrow-right"
                    style="
                      margin: 0 0 0 50px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(39)"
                  ></i>
                </el-row>
              </el-row>
              <el-row style="height: 2px; background-color: #f4f4f4"></el-row>
              <el-row class="bottomNum">
                <el-col> 重量:{{ $store.state.DSBC.P1111 }} </el-col>
                <el-col style="margin: 12px 0;padding:0 0 0 40px;"> 重量码 </el-col>
                <el-col class="weightBottom" style="padding:0 0 0 40px;">
                  <p  v-if="this.$store.state.DSBC.P2301 >= 1">
                    <span>1#</span>
                    <span>{{ this.$store.state.DSBC.P1231 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 2">
                    <span>2#</span>
                    <span>{{ this.$store.state.DSBC.P1232 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 3">
                    <span>3#</span>
                    <span>{{ this.$store.state.DSBC.P1233 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 4">
                    <span>4#</span>
                    <span>{{ this.$store.state.DSBC.P1234 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2335 >= 5">
                    <span>5#</span>
                    <span>{{ this.$store.state.DSBC.P1235 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 6">
                    <span>6#</span>
                    <span>{{ this.$store.state.DSBC.P1236 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 7">
                    <span>7#</span>
                    <span>{{ this.$store.state.DSBC.P1237 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 8">
                    <span>8#</span>
                    <span>{{ this.$store.state.DSBC.P1238 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 9">
                    <span>9#</span>
                    <span>{{ this.$store.state.DSBC.P1239 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 10">
                    <span>10#</span>
                    <span>{{ this.$store.state.DSBC.P1240 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 11">
                    <span>11#</span>
                    <span>{{ this.$store.state.DSBC.P1241 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 12">
                    <span>12#</span>
                    <span>{{ this.$store.state.DSBC.P1242 }}</span>
                  </p>
                </el-col>
                <el-col  class="weightBottom" style="padding:0 0 0 40px;margin:12px 0;">
                  <p>
                    <span>总计</span>
                    <span>{{ this.$store.state.DSBC.P1230 }}</span>
                  </p>
                </el-col>
              </el-row>
               </el-tab-pane>
            <el-tab-pane label="单个" name="activeDeviceC">
               <el-row style="padding:26px 0 0 0">
                <el-row style="text-align:center" class="inputCent">
                    <el-input
                      placeholder="请输入内容"
                      v-model="$store.state.DSBC.P1002"
                      @blur="save('inputP1002')"
                      @input="open('inputP1002')"
                      ref="inputP1002"
                      style="width: 484px;text-align:center;font-size:48px; font-family: Bahnschrift;"
                    ></el-input>
                    <p
                      class="weigh_col weigh_p"
                      style="display: inline-block; margin: 0 0 0 30px"
                    >
                      <span
                        class="state"
                        style="background-color: #409eff"
                        v-if="$store.state.DSBC.P1101 == true"
                      >
                        <span></span>
                      </span>
                      <span class="state" v-else>
                        <span></span>
                      </span>
                      <label for="">稳定</label><br>
                    </p>
                </el-row>
                <el-row style="margin:55px 0 55px 0;text-align:center">
                  <i
                    class="el-icon-arrow-left"
                    style="
                      margin: 0 50px 0 0px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(36)"
                  ></i>
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                    "
                    @click="save(213)"
                    >进入</el-button
                  >
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                      margin: 0 65px;
                    "
                    @click="openbox"
                    >修改</el-button
                  >
                  <el-button
                    type="primary"
                    style="width: 96px; vertical-align: middle"
                    @click="save(13)"
                    >确认</el-button
                  >
                  <i
                    class="el-icon-arrow-right"
                    style="
                      margin: 0 0 0 50px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(39)"
                  ></i>
                </el-row>
              </el-row>
              <el-row style="height: 2px; background-color: #f4f4f4"></el-row>
              <el-row class="bottomNum">
                <el-col> 重量:{{ $store.state.DSBC.P1111 }} </el-col>
                <el-col style="margin: 12px 0;padding:0 0 0 40px;"> 重量码 </el-col>
                <el-col class="weightBottom" style="padding:0 0 0 40px;">
                  <p  v-if="this.$store.state.DSBC.P2301 >= 1">
                    <span>1#</span>
                    <span>{{ this.$store.state.DSBC.P1231 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 2">
                    <span>2#</span>
                    <span>{{ this.$store.state.DSBC.P1232 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 3">
                    <span>3#</span>
                    <span>{{ this.$store.state.DSBC.P1233 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 4">
                    <span>4#</span>
                    <span>{{ this.$store.state.DSBC.P1234 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2335 >= 5">
                    <span>5#</span>
                    <span>{{ this.$store.state.DSBC.P1235 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 6">
                    <span>6#</span>
                    <span>{{ this.$store.state.DSBC.P1236 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 7">
                    <span>7#</span>
                    <span>{{ this.$store.state.DSBC.P1237 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 8">
                    <span>8#</span>
                    <span>{{ this.$store.state.DSBC.P1238 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 9">
                    <span>9#</span>
                    <span>{{ this.$store.state.DSBC.P1239 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 10">
                    <span>10#</span>
                    <span>{{ this.$store.state.DSBC.P1240 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 11">
                    <span>11#</span>
                    <span>{{ this.$store.state.DSBC.P1241 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 12">
                    <span>12#</span>
                    <span>{{ this.$store.state.DSBC.P1242 }}</span>
                  </p>
                </el-col>
                <el-col  class="weightBottom" style="padding:0 0 0 40px;margin:12px 0;">
                  <p>
                    <span>总计</span>
                    <span>{{ this.$store.state.DSBC.P1230 }}</span>
                  </p>
                </el-col>
              </el-row> </el-tab-pane>
            
          </el-tabs>
        </div>
      </el-col>
      <!--调角  -->
      <el-col :span="18" class="box-right" v-else-if="boxdialogVisible == 4">
        <div class="topbox"></div>
        <div class="bottombox">
          <el-tabs
            type="border-card"
            v-model="activeTiao"
            @tab-click="BiaoClick"
          >
            <el-tab-pane label="系数" name="activeTiaoA">
              <el-row>
                <el-col :span="8"  class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 1">
                  <label for="">1#</label><br>
                  <span>{{ this.$store.state.DSBC.P2303 }}</span>
                </el-col>
                <el-col :span="8"  class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 2">
                  <label for="">2#</label><br>
                  <span>{{ this.$store.state.DSBC.P2304 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1212"
                    @blur="save('inputP1203')"
                    @input="open('inputP1203')"
                    ref="inputP1203"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 3">
                  <label for="">3#</label><br>
                  <span>{{ this.$store.state.DSBC.P2305 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1213"
                    @blur="save('inputP1204')"
                    @input="open('inputP1204')"
                    ref="inputP1204"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 4">
                  <label for="">4#</label><br>
                  <span>{{ this.$store.state.DSBC.P2306 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1214"
                    @blur="save('inputP1205')"
                    @input="open('inputP1205')"
                    ref="inputP1205"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 5">
                  <label for="">5#</label><br>
                  <span>{{ this.$store.state.DSBC.P2307 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1215"
                    @blur="save('inputP1206')"
                    @input="open('inputP1206')"
                    ref="inputP1206"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 6">
                  <label for="">6#</label><br>
                  <span>{{ this.$store.state.DSBC.P2308 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1216"
                    @blur="save('inputP1206')"
                    @input="open('inputP1207')"
                    ref="inputP1207"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 7">
                  <label for="">7#</label><br>
                  <span>{{ this.$store.state.DSBC.P2309 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1217"
                    @blur="save('inputP1208')"
                    @input="open('inputP1208')"
                    ref="inputP1208"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 8">
                  <label for="">8#</label><br>
                  <span>{{ this.$store.state.DSBC.P2310 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1218"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 9">
                  <label for="">9#</label><br>
                  <span>{{ this.$store.state.DSBC.P2311 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1219"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 10">
                  <label for="">10#</label><br>
                  <span>{{ this.$store.state.DSBC.P2312 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1220"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 11">
                  <label for="">11#</label><br>
                  <span>{{ this.$store.state.DSBC.P2313 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1221"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox zhidu" v-if="this.$store.state.DSBC.P2301 >= 12">
                  <label for="">12#</label><br>
                  <span>{{ this.$store.state.DSBC.P2314 }}</span>
                  <!-- <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P1222"
                    @blur="save('inputP1209')"
                    @input="open('inputP1209')"
                    ref="inputP1209"
                  ></el-input> -->
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="自动" name="activeTiaoB">
               <el-row style="padding:26px 0 0 0">
                <el-row style="text-align:center" class="inputCent">
                    <el-input
                      placeholder="请输入内容"
                      v-model="$store.state.DSBC.P1002"
                      @blur="save('inputP1002')"
                      @input="open('inputP1002')"
                      ref="inputP1002"
                      style="width: 484px;text-align:center;font-size:48px; font-family: Bahnschrift;"
                    ></el-input>
                    <p
                      class="weigh_col weigh_p"
                      style="display: inline-block; margin: 0 0 0 30px"
                    >
                      <span
                        class="state"
                        style="background-color: #409eff"
                        v-if="$store.state.DSBC.P1101 == true"
                      >
                        <span></span>
                      </span>
                      <span class="state" v-else>
                        <span></span>
                      </span>
                      <label for="">稳定</label><br>
                    </p>
                </el-row>
                <el-row style="margin:55px 0 55px 0;text-align:center">
                  <i
                    class="el-icon-arrow-left"
                    style="
                      margin: 0 50px 0 0px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(36)"
                  ></i>
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                    "
                    @click="save(231)"
                    >进入</el-button
                  >
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                      margin: 0 65px;
                    "
                    @click="openbox"
                    >修改</el-button
                  >
                  <el-button
                    type="primary"
                    style="width: 96px; vertical-align: middle"
                    @click="save(13)"
                    >确认</el-button
                  >
                  <i
                    class="el-icon-arrow-right"
                    style="
                      margin: 0 0 0 50px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(39)"
                  ></i>
                </el-row>
              </el-row>
              <el-row style="height: 2px; background-color: #f4f4f4"></el-row>
              <el-row class="bottomNum">
                <el-col> 重量:{{ $store.state.DSBC.P1111 }} </el-col>
                <el-col style="margin: 12px 0;padding:0 0 0 40px;"> 重量码 </el-col>
                <el-col class="weightBottom" style="padding:0 0 0 40px;">
                  <p  v-if="this.$store.state.DSBC.P2301 >= 1">
                    <span>1#</span>
                    <span>{{ this.$store.state.DSBC.P1231 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 2">
                    <span>2#</span>
                    <span>{{ this.$store.state.DSBC.P1232 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 3">
                    <span>3#</span>
                    <span>{{ this.$store.state.DSBC.P1233 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 4">
                    <span>4#</span>
                    <span>{{ this.$store.state.DSBC.P1234 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2335 >= 5">
                    <span>5#</span>
                    <span>{{ this.$store.state.DSBC.P1235 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 6">
                    <span>6#</span>
                    <span>{{ this.$store.state.DSBC.P1236 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 7">
                    <span>7#</span>
                    <span>{{ this.$store.state.DSBC.P1237 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 8">
                    <span>8#</span>
                    <span>{{ this.$store.state.DSBC.P1238 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 9">
                    <span>9#</span>
                    <span>{{ this.$store.state.DSBC.P1239 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 10">
                    <span>10#</span>
                    <span>{{ this.$store.state.DSBC.P1240 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 11">
                    <span>11#</span>
                    <span>{{ this.$store.state.DSBC.P1241 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 12">
                    <span>12#</span>
                    <span>{{ this.$store.state.DSBC.P1242 }}</span>
                  </p>
                </el-col>
                <el-col  class="weightBottom" style="padding:0 0 0 40px;margin:12px 0;">
                  <p>
                    <span>总计</span>
                    <span>{{ this.$store.state.DSBC.P1230 }}</span>
                  </p>
                </el-col>
              </el-row> </el-tab-pane>
            <el-tab-pane label="压角" name="activeTiaoC">
              <el-row style="padding:26px 0 0 0">
                <el-row style="text-align:center" class="inputCent">
                    <el-input
                      placeholder="请输入内容"
                      v-model="$store.state.DSBC.P1002"
                      @blur="save('inputP1002')"
                      @input="open('inputP1002')"
                      ref="inputP1002"
                      style="width: 484px;text-align:center;font-size:48px; font-family: Bahnschrift;"
                    ></el-input>
                    <p
                      class="weigh_col weigh_p"
                      style="display: inline-block; margin: 0 0 0 30px"
                    >
                      <span
                        class="state"
                        style="background-color: #409eff"
                        v-if="$store.state.DSBC.P1101 == true"
                      >
                        <span></span>
                      </span>
                      <span class="state" v-else>
                        <span></span>
                      </span>
                      <label for="">稳定</label><br>
                    </p>
                </el-row>
                <el-row style="margin:55px 0 55px 0;text-align:center">
                  <i
                    class="el-icon-arrow-left"
                    style="
                      margin: 0 50px 0 0px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(36)"
                  ></i>
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                    "
                    @click="save(232)"
                    >进入</el-button
                  >
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                      margin: 0 65px;
                    "
                    @click="openbox"
                    >修改</el-button
                  >
                  <el-button
                    type="primary"
                    style="width: 96px; vertical-align: middle"
                    @click="save(13)"
                    >确认</el-button
                  >
                  <i
                    class="el-icon-arrow-right"
                    style="
                      margin: 0 0 0 50px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(39)"
                  ></i>
                </el-row>
              </el-row>
              <el-row style="height: 2px; background-color: #f4f4f4"></el-row>
              <el-row class="bottomNum">
                <el-col> 重量:{{ $store.state.DSBC.P1111 }} </el-col>
                <el-col style="margin: 12px 0;padding:0 0 0 40px;"> 重量码 </el-col>
                <el-col class="weightBottom" style="padding:0 0 0 40px;">
                  <p  v-if="this.$store.state.DSBC.P2301 >= 1">
                    <span>1#</span>
                    <span>{{ this.$store.state.DSBC.P1231 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 2">
                    <span>2#</span>
                    <span>{{ this.$store.state.DSBC.P1232 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 3">
                    <span>3#</span>
                    <span>{{ this.$store.state.DSBC.P1233 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 4">
                    <span>4#</span>
                    <span>{{ this.$store.state.DSBC.P1234 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2335 >= 5">
                    <span>5#</span>
                    <span>{{ this.$store.state.DSBC.P1235 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 6">
                    <span>6#</span>
                    <span>{{ this.$store.state.DSBC.P1236 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 7">
                    <span>7#</span>
                    <span>{{ this.$store.state.DSBC.P1237 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 8">
                    <span>8#</span>
                    <span>{{ this.$store.state.DSBC.P1238 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 9">
                    <span>9#</span>
                    <span>{{ this.$store.state.DSBC.P1239 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 10">
                    <span>10#</span>
                    <span>{{ this.$store.state.DSBC.P1240 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 11">
                    <span>11#</span>
                    <span>{{ this.$store.state.DSBC.P1241 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 12">
                    <span>12#</span>
                    <span>{{ this.$store.state.DSBC.P1242 }}</span>
                  </p>
                </el-col>
                <el-col  class="weightBottom" style="padding:0 0 0 40px;margin:12px 0;">
                  <p>
                    <span>总计</span>
                    <span>{{ this.$store.state.DSBC.P1230 }}</span>
                  </p>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="压轴" name="activeTiaoD">
               <el-row style="padding:26px 0 0 0">
                <el-row style="text-align:center" class="inputCent">
                    <el-input
                      placeholder="请输入内容"
                      v-model="$store.state.DSBC.P1002"
                      @blur="save('inputP1002')"
                      @input="open('inputP1002')"
                      ref="inputP1002"
                      style="width: 484px;text-align:center;font-size:48px; font-family: Bahnschrift;"
                    ></el-input>
                    <p
                      class="weigh_col weigh_p"
                      style="display: inline-block; margin: 0 0 0 30px"
                    >
                      <span
                        class="state"
                        style="background-color: #409eff"
                        v-if="$store.state.DSBC.P1101 == true"
                      >
                        <span></span>
                      </span>
                      <span class="state" v-else>
                        <span></span>
                      </span>
                      <label for="">稳定</label><br>
                    </p>
                </el-row>
                <el-row style="margin:55px 0 55px 0;text-align:center">
                  <i
                    class="el-icon-arrow-left"
                    style="
                      margin: 0 50px 0 0px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(36)"
                  ></i>
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                    "
                    @click="save(234)"
                    >进入</el-button
                  >
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                      margin: 0 65px;
                    "
                    @click="openbox"
                    >修改</el-button
                  >
                  <el-button
                    type="primary"
                    style="width: 96px; vertical-align: middle"
                    @click="save(13)"
                    >确认</el-button
                  >
                  <i
                    class="el-icon-arrow-right"
                    style="
                      margin: 0 0 0 50px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(39)"
                  ></i>
                </el-row>
              </el-row>
              <el-row style="height: 2px; background-color: #f4f4f4"></el-row>
              <el-row class="bottomNum">
                <el-col> 重量:{{ $store.state.DSBC.P1111 }} </el-col>
                <el-col style="margin: 12px 0;padding:0 0 0 40px;"> 重量码 </el-col>
                <el-col class="weightBottom" style="padding:0 0 0 40px;">
                  <p  v-if="this.$store.state.DSBC.P2301 >= 1">
                    <span>1#</span>
                    <span>{{ this.$store.state.DSBC.P1231 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 2">
                    <span>2#</span>
                    <span>{{ this.$store.state.DSBC.P1232 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 3">
                    <span>3#</span>
                    <span>{{ this.$store.state.DSBC.P1233 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 4">
                    <span>4#</span>
                    <span>{{ this.$store.state.DSBC.P1234 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2335 >= 5">
                    <span>5#</span>
                    <span>{{ this.$store.state.DSBC.P1235 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 6">
                    <span>6#</span>
                    <span>{{ this.$store.state.DSBC.P1236 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 7">
                    <span>7#</span>
                    <span>{{ this.$store.state.DSBC.P1237 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 8">
                    <span>8#</span>
                    <span>{{ this.$store.state.DSBC.P1238 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 9">
                    <span>9#</span>
                    <span>{{ this.$store.state.DSBC.P1239 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 10">
                    <span>10#</span>
                    <span>{{ this.$store.state.DSBC.P1240 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 11">
                    <span>11#</span>
                    <span>{{ this.$store.state.DSBC.P1241 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 12">
                    <span>12#</span>
                    <span>{{ this.$store.state.DSBC.P1242 }}</span>
                  </p>
                </el-col>
                <el-col  class="weightBottom" style="padding:0 0 0 40px;margin:12px 0;">
                  <p>
                    <span>总计</span>
                    <span>{{ this.$store.state.DSBC.P1230 }}</span>
                  </p>
                </el-col>
              </el-row> </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <!-- 标定 -->
      <el-col :span="18" class="box-right" v-else-if="boxdialogVisible == 5">
        <div class="topbox"></div>
        <div class="bottombox">
          <el-tabs
            type="border-card"
            v-model="activeBiao"
            @tab-click="BiaoClick"
          >
            <el-tab-pane label="参数" name="activeBiaoA">
              <el-row>
                <el-col :span="8">
                  <label for="">分度值</label><br>
                  <el-select
                    v-model="$store.state.DSBC.P2401"
                    placeholder=""
                    @change="save('inputP2401')"
                  >
                    <el-option
                      v-for="item in optionsP2401"
                      :key="item.name"
                      :label="item.value"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <!-- <el-input  placeholder="请输入内容"  v-model="$store.state.DSBC.P2401" @blur="save('inputP2401')" @input="open('inputP2401')" ref="inputP2401"> </el-input> -->
                </el-col>
                <el-col :span="8">
                  <label for="">满量程</label><br>
                  <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P2402"
                    @blur="save('inputP2402')"
                    @input="open('inputP2402')"
                    ref="inputP2402"
                  >
                  </el-input>
                </el-col>
                <el-col :span="8">
                  <label for="">切换点</label><br>
                  <el-input
                    placeholder="请输入内容"
                    v-model="$store.state.DSBC.P2403"
                    @blur="save('inputP2403')"
                    @input="open('inputP2403')"
                    ref="inputP2403"
                  >
                  </el-input>
                </el-col>
                <el-col :span="8" class="marbox">
                  <label for="">小数点</label><br>
                  <el-select
                    v-model="$store.state.DSBC.P2404"
                    placeholder=""
                    @change="save('inputP2404')"
                  >
                    <el-option
                      v-for="item in optionsP2404"
                      :key="item.name"
                      :label="item.value"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <!-- <el-input placeholder="请输入内容" v-model="$store.state.DSBC.P2404" @blur="save('inputP2404')" @input="open('inputP2404')" ref="inputP2404"></el-input> -->
                </el-col>
                <el-col :span="8" class="marbox">
                  <label for="">单位</label><br>
                  <el-select
                    v-model="$store.state.DSBC.P2405"
                    placeholder=""
                    @change="save('inputP2405')"
                  >
                    <el-option
                      v-for="item in optionsP2405"
                      :key="item.name"
                      :label="item.value"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <!-- <el-input  placeholder="请输入内容" v-model="$store.state.DSBC.P2405" @blur="save('inputP2405')" @input="open('inputP2405')" ref="inputP2405"> </el-input> -->
                </el-col>
                <el-col :span="8" class="marbox">
                  <label for="">开机置零</label><br>
                  <el-select
                    v-model="$store.state.DSBC.P2406"
                    placeholder=""
                    @change="save('inputP2406')"
                  >
                    <el-option
                      v-for="item in optionsP2406"
                      :key="item.name"
                      :label="item.value"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <!-- <el-input placeholder="请输入内容" v-model="$store.state.DSBC.P2406" @blur="save('inputP2406')" @input="open('inputP2406')" ref="inputP2406"> </el-input> -->
                </el-col>
                <el-col :span="8">
                  <label for="">手动置零</label><br>
                  <el-select
                    v-model="$store.state.DSBC.P2407"
                    placeholder=""
                    @change="save('inputP2407')"
                  >
                    <el-option
                      v-for="item in optionsP2407"
                      :key="item.name"
                      :label="item.value"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <!-- <el-input placeholder="请输入内容" v-model="$store.state.DSBC.P2407" @blur="save('inputP2407')" @input="open('inputP2407')" ref="inputP2407"> </el-input> -->
                </el-col>
                <el-col :span="8">
                  <label for="">零点跟踪</label><br>
                  <el-select
                    v-model="$store.state.DSBC.P2408"
                    placeholder=""
                    @change="save('inputP2408')"
                  >
                    <el-option
                      v-for="item in optionsP2408"
                      :key="item.name"
                      :label="item.value"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <!-- <el-input placeholder="请输入内容"  v-model="$store.state.DSBC.P2408" @blur="save('inputP2408')" @input="open('inputP2408')" ref="inputP2408"> </el-input> -->
                </el-col>
                <el-col :span="8">
                  <label for="">回零设置</label><br>
                  <el-select
                    v-model="$store.state.DSBC.P2409"
                    placeholder=""
                    @change="save('inputP2409')"
                  >
                    <el-option
                      v-for="item in optionsP2409"
                      :key="item.name"
                      :label="item.value"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <!-- <el-input placeholder="请输入内容" v-model="$store.state.DSBC.P2409" @blur="save('inputP2409')" @input="open('inputP2409')" ref="inputP2409"> </el-input> -->
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="两点" name="activeBiaoB">
               <el-row style="padding:26px 0 0 0">
                <el-row style="text-align:center" class="inputCent">
                    <el-input
                      placeholder="请输入内容"
                      v-model="$store.state.DSBC.P1002"
                      @blur="save('inputP1002')"
                      @input="open('inputP1002')"
                      ref="inputP1002"
                      style="width: 484px;text-align:center;font-size:48px; font-family: Bahnschrift;"
                    ></el-input>
                    <p
                      class="weigh_col weigh_p"
                      style="display: inline-block; margin: 0 0 0 30px"
                    >
                      <span
                        class="state"
                        style="background-color: #409eff"
                        v-if="$store.state.DSBC.P1101 == true"
                      >
                        <span></span>
                      </span>
                      <span class="state" v-else>
                        <span></span>
                      </span>
                      <label for="">稳定</label><br>
                    </p>
                </el-row>
                <el-row style="margin:55px 0 55px 0;text-align:center">
                  <i
                    class="el-icon-arrow-left"
                    style="
                      margin: 0 50px 0 0px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(36)"
                  ></i>
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                    "
                    @click="save(311)"
                    >进入</el-button
                  >
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                      margin: 0 65px;
                    "
                    @click="openbox"
                    >修改</el-button
                  >
                  <el-button
                    type="primary"
                    style="width: 96px; vertical-align: middle"
                    @click="save(13)"
                    >确认</el-button
                  >
                  <i
                    class="el-icon-arrow-right"
                    style="
                      margin: 0 0 0 50px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(39)"
                  ></i>
                </el-row>
              </el-row>
              <el-row style="height: 2px; background-color: #f4f4f4"></el-row>
              <el-row class="bottomNum">
                <el-col> 重量:{{ $store.state.DSBC.P1111 }} </el-col>
                <el-col style="margin: 12px 0;padding:0 0 0 40px;"> 重量码 </el-col>
                <el-col class="weightBottom" style="padding:0 0 0 40px;">
                  <p  v-if="this.$store.state.DSBC.P2301 >= 1">
                    <span>1#</span>
                    <span>{{ this.$store.state.DSBC.P1231 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 2">
                    <span>2#</span>
                    <span>{{ this.$store.state.DSBC.P1232 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 3">
                    <span>3#</span>
                    <span>{{ this.$store.state.DSBC.P1233 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 4">
                    <span>4#</span>
                    <span>{{ this.$store.state.DSBC.P1234 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2335 >= 5">
                    <span>5#</span>
                    <span>{{ this.$store.state.DSBC.P1235 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 6">
                    <span>6#</span>
                    <span>{{ this.$store.state.DSBC.P1236 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 7">
                    <span>7#</span>
                    <span>{{ this.$store.state.DSBC.P1237 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 8">
                    <span>8#</span>
                    <span>{{ this.$store.state.DSBC.P1238 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 9">
                    <span>9#</span>
                    <span>{{ this.$store.state.DSBC.P1239 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 10">
                    <span>10#</span>
                    <span>{{ this.$store.state.DSBC.P1240 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 11">
                    <span>11#</span>
                    <span>{{ this.$store.state.DSBC.P1241 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 12">
                    <span>12#</span>
                    <span>{{ this.$store.state.DSBC.P1242 }}</span>
                  </p>
                </el-col>
                <el-col  class="weightBottom" style="padding:0 0 0 40px;margin:12px 0;">
                  <p>
                    <span>总计</span>
                    <span>{{ this.$store.state.DSBC.P1230 }}</span>
                  </p>
                </el-col>
              </el-row>
               </el-tab-pane>
            <el-tab-pane label="三点" name="activeBiaoC">
               <el-row style="padding:26px 0 0 0">
                <el-row style="text-align:center" class="inputCent">
                    <el-input
                      placeholder="请输入内容"
                      v-model="$store.state.DSBC.P1002"
                      @blur="save('inputP1002')"
                      @input="open('inputP1002')"
                      ref="inputP1002"
                      style="width: 484px;text-align:center;font-size:48px; font-family: Bahnschrift;"
                    ></el-input>
                    <p
                      class="weigh_col weigh_p"
                      style="display: inline-block; margin: 0 0 0 30px"
                    >
                      <span
                        class="state"
                        style="background-color: #409eff"
                        v-if="$store.state.DSBC.P1101 == true"
                      >
                        <span></span>
                      </span>
                      <span class="state" v-else>
                        <span></span>
                      </span>
                      <label for="">稳定</label><br>
                    </p>
                </el-row>
                <el-row style="margin:55px 0 55px 0;text-align:center">
                  <i
                    class="el-icon-arrow-left"
                    style="
                      margin: 0 50px 0 0px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(36)"
                  ></i>
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                    "
                    @click="save(314)"
                    >进入</el-button
                  >
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                      margin: 0 65px;
                    "
                    @click="openbox"
                    >修改</el-button
                  >
                  <el-button
                    type="primary"
                    style="width: 96px; vertical-align: middle"
                    @click="save(13)"
                    >确认</el-button
                  >
                  <i
                    class="el-icon-arrow-right"
                    style="
                      margin: 0 0 0 50px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(39)"
                  ></i>
                </el-row>
              </el-row>
              <el-row style="height: 2px; background-color: #f4f4f4"></el-row>
              <el-row class="bottomNum">
                <el-col> 重量:{{ $store.state.DSBC.P1111 }} </el-col>
                <el-col style="margin: 12px 0;padding:0 0 0 40px;"> 重量码 </el-col>
                <el-col class="weightBottom" style="padding:0 0 0 40px;">
                  <p  v-if="this.$store.state.DSBC.P2301 >= 1">
                    <span>1#</span>
                    <span>{{ this.$store.state.DSBC.P1231 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 2">
                    <span>2#</span>
                    <span>{{ this.$store.state.DSBC.P1232 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 3">
                    <span>3#</span>
                    <span>{{ this.$store.state.DSBC.P1233 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 4">
                    <span>4#</span>
                    <span>{{ this.$store.state.DSBC.P1234 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2335 >= 5">
                    <span>5#</span>
                    <span>{{ this.$store.state.DSBC.P1235 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 6">
                    <span>6#</span>
                    <span>{{ this.$store.state.DSBC.P1236 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 7">
                    <span>7#</span>
                    <span>{{ this.$store.state.DSBC.P1237 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 8">
                    <span>8#</span>
                    <span>{{ this.$store.state.DSBC.P1238 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 9">
                    <span>9#</span>
                    <span>{{ this.$store.state.DSBC.P1239 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 10">
                    <span>10#</span>
                    <span>{{ this.$store.state.DSBC.P1240 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 11">
                    <span>11#</span>
                    <span>{{ this.$store.state.DSBC.P1241 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 12">
                    <span>12#</span>
                    <span>{{ this.$store.state.DSBC.P1242 }}</span>
                  </p>
                </el-col>
                <el-col  class="weightBottom" style="padding:0 0 0 40px;margin:12px 0;">
                  <p>
                    <span>总计</span>
                    <span>{{ this.$store.state.DSBC.P1230 }}</span>
                  </p>
                </el-col>
              </el-row>
               </el-tab-pane>
            <!-- <el-tab-pane label="零点" name="activeBiaoD">
              
            </el-tab-pane> -->
            <el-tab-pane label="补偿" name="activeBiaoE">
               <el-row style="padding:26px 0 0 0">
                <el-row style="text-align:center" class="inputCent">
                    <el-input
                      placeholder="请输入内容"
                      v-model="$store.state.DSBC.P1002"
                      @blur="save('inputP1002')"
                      @input="open('inputP1002')"
                      ref="inputP1002"
                      style="width: 484px;text-align:center;font-size:48px; font-family: Bahnschrift;"
                    ></el-input>
                    <p
                      class="weigh_col weigh_p"
                      style="display: inline-block; margin: 0 0 0 30px"
                    >
                      <span
                        class="state"
                        style="background-color: #409eff"
                        v-if="$store.state.DSBC.P1101 == true"
                      >
                        <span></span>
                      </span>
                      <span class="state" v-else>
                        <span></span>
                      </span>
                      <label for="">稳定</label><br>
                    </p>
                </el-row>
                <el-row style="margin:55px 0 55px 0;text-align:center">
                  <i
                    class="el-icon-arrow-left"
                    style="
                      margin: 0 50px 0 0px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(36)"
                  ></i>
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                    "
                    @click="save(313)"
                    >进入</el-button
                  >
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                      margin: 0 65px;
                    "
                    @click="openbox"
                    >修改</el-button
                  >
                  <el-button
                    type="primary"
                    style="width: 96px; vertical-align: middle"
                    @click="save(13)"
                    >确认</el-button
                  >
                  <i
                    class="el-icon-arrow-right"
                    style="
                      margin: 0 0 0 50px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(39)"
                  ></i>
                </el-row>
              </el-row>
              <el-row style="height: 2px; background-color: #f4f4f4"></el-row>
              <el-row class="bottomNum">
                <el-col> 重量:{{ $store.state.DSBC.P1111 }} </el-col>
                <el-col style="margin: 12px 0;padding:0 0 0 40px;"> 重量码 </el-col>
                <el-col class="weightBottom" style="padding:0 0 0 40px;">
                  <p  v-if="this.$store.state.DSBC.P2301 >= 1">
                    <span>1#</span>
                    <span>{{ this.$store.state.DSBC.P1231 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 2">
                    <span>2#</span>
                    <span>{{ this.$store.state.DSBC.P1232 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 3">
                    <span>3#</span>
                    <span>{{ this.$store.state.DSBC.P1233 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 4">
                    <span>4#</span>
                    <span>{{ this.$store.state.DSBC.P1234 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2335 >= 5">
                    <span>5#</span>
                    <span>{{ this.$store.state.DSBC.P1235 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 6">
                    <span>6#</span>
                    <span>{{ this.$store.state.DSBC.P1236 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 7">
                    <span>7#</span>
                    <span>{{ this.$store.state.DSBC.P1237 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 8">
                    <span>8#</span>
                    <span>{{ this.$store.state.DSBC.P1238 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 9">
                    <span>9#</span>
                    <span>{{ this.$store.state.DSBC.P1239 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 10">
                    <span>10#</span>
                    <span>{{ this.$store.state.DSBC.P1240 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 11">
                    <span>11#</span>
                    <span>{{ this.$store.state.DSBC.P1241 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 12">
                    <span>12#</span>
                    <span>{{ this.$store.state.DSBC.P1242 }}</span>
                  </p>
                </el-col>
                <el-col  class="weightBottom" style="padding:0 0 0 40px;margin:12px 0;">
                  <p>
                    <span>总计</span>
                    <span>{{ this.$store.state.DSBC.P1230 }}</span>
                  </p>
                </el-col>
              </el-row>
               </el-tab-pane>
            <!-- <el-tab-pane label="信息" name="activeBiaoF"> </el-tab-pane> -->
            <el-tab-pane label="默认" name="activeBiaoG">
               <el-row style="padding:26px 0 0 0">
                <el-row style="text-align:center" class="inputCent">
                    <el-input
                      placeholder="请输入内容"
                      v-model="$store.state.DSBC.P1002"
                      @blur="save('inputP1002')"
                      @input="open('inputP1002')"
                      ref="inputP1002"
                      style="width: 484px;text-align:center;font-size:48px; font-family: Bahnschrift;"
                    ></el-input>
                    <p
                      class="weigh_col weigh_p"
                      style="display: inline-block; margin: 0 0 0 30px"
                    >
                      <span
                        class="state"
                        style="background-color: #409eff"
                        v-if="$store.state.DSBC.P1101 == true"
                      >
                        <span></span>
                      </span>
                      <span class="state" v-else>
                        <span></span>
                      </span>
                      <label for="">稳定</label><br>
                    </p>
                </el-row>
                <el-row style="margin:55px 0 55px 0;text-align:center">
                  <i
                    class="el-icon-arrow-left"
                    style="
                      margin: 0 50px 0 0px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(36)"
                  ></i>
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                    "
                    @click="save(325)"
                    >进入</el-button
                  >
                  <el-button
                    style="
                      width: 96px;
                      background-color: #dddddd;
                      vertical-align: middle;
                      margin: 0 65px;
                    "
                    @click="openbox"
                    >修改</el-button
                  >
                  <el-button
                    type="primary"
                    style="width: 96px; vertical-align: middle"
                    @click="save(13)"
                    >确认</el-button
                  >
                  <i
                    class="el-icon-arrow-right"
                    style="
                      margin: 0 0 0 50px;
                      font-size: 48px;
                      vertical-align: middle;
                      color: #dddddd;
                    "
                    @click="save(39)"
                  ></i>
                </el-row>
              </el-row>
              <el-row style="height: 2px; background-color: #f4f4f4"></el-row>
              <el-row class="bottomNum">
                <el-col> 重量:{{ $store.state.DSBC.P1111 }} </el-col>
                <el-col style="margin: 12px 0;padding:0 0 0 40px;"> 重量码 </el-col>
                <el-col class="weightBottom" style="padding:0 0 0 40px;">
                  <p  v-if="this.$store.state.DSBC.P2301 >= 1">
                    <span>1#</span>
                    <span>{{ this.$store.state.DSBC.P1231 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 2">
                    <span>2#</span>
                    <span>{{ this.$store.state.DSBC.P1232 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 3">
                    <span>3#</span>
                    <span>{{ this.$store.state.DSBC.P1233 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 4">
                    <span>4#</span>
                    <span>{{ this.$store.state.DSBC.P1234 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2335 >= 5">
                    <span>5#</span>
                    <span>{{ this.$store.state.DSBC.P1235 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 6">
                    <span>6#</span>
                    <span>{{ this.$store.state.DSBC.P1236 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 7">
                    <span>7#</span>
                    <span>{{ this.$store.state.DSBC.P1237 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 8">
                    <span>8#</span>
                    <span>{{ this.$store.state.DSBC.P1238 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 9">
                    <span>9#</span>
                    <span>{{ this.$store.state.DSBC.P1239 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 10">
                    <span>10#</span>
                    <span>{{ this.$store.state.DSBC.P1240 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 11">
                    <span>11#</span>
                    <span>{{ this.$store.state.DSBC.P1241 }}</span>
                  </p>
                  <p  v-if="this.$store.state.DSBC.P2301 >= 12">
                    <span>12#</span>
                    <span>{{ this.$store.state.DSBC.P1242 }}</span>
                  </p>
                </el-col>
                <el-col  class="weightBottom" style="padding:0 0 0 40px;margin:12px 0;">
                  <p>
                    <span>总计</span>
                    <span>{{ this.$store.state.DSBC.P1230 }}</span>
                  </p>
                </el-col>
              </el-row>
               </el-tab-pane>
            <!-- <el-tab-pane label="内码" name="activeBiaoH"> </el-tab-pane>
            <el-tab-pane label="检定" name="activeBiaoI"> </el-tab-pane> -->
          </el-tabs>
        </div>
      </el-col>
      <!-- 功能 -->
      <!-- <el-col :span="19" class="box-right" v-else-if="boxdialogVisible == 6">
        <div class="topbox"></div>
        <div class="bottombox">
          <el-tabs
            type="border-card"
            v-model="activeGong"
            @tab-click="BiaoClick"
          >
            <el-tab-pane label="时钟" name="activeGongD"> </el-tab-pane>
            <el-tab-pane label="滤波" name="activeGongE"> </el-tab-pane>
            <el-tab-pane label="试用期" name="activeGongA"> </el-tab-pane>
            <el-tab-pane label="出厂" name="activeGongB"> </el-tab-pane>
            <el-tab-pane label="还原" name="activeGongC"> </el-tab-pane>
            <el-tab-pane label="密码" name="activeGongF"> </el-tab-pane>
          </el-tabs>
        </div>
      </el-col> -->
      <!-- <el-col :span="19" class="box-right" v-else-if="boxdialogVisible == 7">
        <div class="topbox"></div>
        <div class="bottombox">
          <el-tabs type="border-card" v-model="activelV" @tab-click="BiaoClick">
            <el-tab-pane label="参数" name="activelVA"> </el-tab-pane>
          </el-tabs>
        </div>
      </el-col> -->
      <!-- <el-col :span="19" class="box-right" v-else-if="boxdialogVisible == 8">
        <div class="topbox"></div>
        <div class="bottombox">
          <el-tabs
            type="border-card"
            v-model="activeTime"
            @tab-click="BiaoClick"
          >
            <el-tab-pane label="时间" name="activeTimeA"> </el-tab-pane>
          </el-tabs>
        </div>
      </el-col> -->
      <!-- 密码 -->
      <!-- <el-col :span="19" class="box-right" v-else-if="boxdialogVisible == 9">
        <div class="topbox"></div>
        <div class="bottombox">
          <el-tabs
            type="border-card"
            v-model="activePass"
            @tab-click="BiaoClick"
          >
            <el-tab-pane label="管理员" name="activePassA"> </el-tab-pane>
            <el-tab-pane label="调称" name="activePassB"> </el-tab-pane>
            <el-tab-pane label="标定" name="activePassC"> </el-tab-pane>
          </el-tabs>
        </div>
      </el-col> -->
      <!-- 经典 -->
      <el-col :span="18" class="box-right" v-else-if="boxdialogVisible == 10">
        <div class="topbox"></div>
        <div
          class="bottombox textbottombox"
          style="box-sizing: border-box; "
        >
          <el-row>
            <el-col style="text-align:center;" class="inputCent">
            <el-input
              placeholder="请输入内容"
              style="width: 484px;font-size:48px;font-family: Bahnschrift;"
              v-model="$store.state.DSBC.P1002"
              @blur="save('inputP1001')"
              @input="open('inputP1001')"
              ref="inputP1001"
            ></el-input>
          </el-col>
          <el-col class="weigh_text" style="text-align:center;">
            <p class="weigh_col weigh_p">
              <span
                class="state"
                style="background-color: #409eff"
                v-if="$store.state.DSBC.P1105 == true"
              >
                <span></span>
              </span>
              <span class="state" v-else>
                <span></span>
              </span>
              <label for="">故障</label><br>
            </p>
            <p class="weigh_col weigh_p">
              <span
                class="state"
                style="background-color: #409eff"
                v-if="$store.state.DSBC.P1103 == true"
              >
                <span></span>
              </span>
              <span class="state" v-else>
                <span></span>
              </span>
              <label for="">毛重</label><br>
            </p>
            <p class="weigh_col weigh_p">
              <span
                class="state"
                style="background-color: #409eff"
                v-if="$store.state.DSBC.P1104 == true"
              >
                <span></span>
              </span>
              <span class="state" v-else>
                <span></span>
              </span>
              <label for="">净重</label><br>
            </p>
            <p class="weigh_col weigh_p">
              <span
                class="state"
                style="background-color: #409eff"
                v-if="$store.state.DSBC.P1101 == true"
              >
                <span></span>
              </span>
              <span class="state" v-else>
                <span></span>
              </span>
              <label for="">稳定</label><br>
            </p>
            <p class="weigh_col weigh_p">
              <span
                class="state"
                style="background-color: #409eff"
              
                v-if="$store.state.DSBC.P1102 == true"
              >
                <span></span>
              </span>
              <span class="state" v-else>
                <span></span>
              </span>
              <label for="">零位</label><br>
            </p>
            
          </el-col>
          </el-row>
          <!-- <el-col>
            <el-button type="primary" @click="save('inputP1002')">查 询</el-button>
            <el-button type="primary" @click="save(118)" >置 零</el-button>
             </el-col> -->
             <div v-if="$store.state.DSBC.P2004.indexOf('61C') == -1">
                <el-row class="buttons" style="text-align:center;">
              <el-button type="primary" @click="save(124)" style="background-color:rgb(0,117,191)">查询</el-button>
              <el-button type="primary" @click="save(49)" style="background-color:rgb(0,117,191)">
                <p>车号</p>
                <p>1</p>
              </el-button>
              <el-button type="primary" @click="save(50)" style="background-color:rgb(0,117,191)">
                <p>货号</p>
                <p>2</p>
              </el-button>
              <el-button type="primary" @click="save(51)" style="background-color:rgb(0,117,191)">
                <p>存皮</p>
                <p>3</p>
              </el-button>
              <el-button type="primary" @click="save(8)" style="background-color:rgb(0,117,191)">返回</el-button>
              <el-button type="primary" @click="save(119)" style="background-color:rgb(0,117,191)">检查</el-button>
                </el-row>
                <el-row class="buttons" style="text-align:center;">
                  <el-button type="primary" @click="save(122)" style="background-color:rgb(0,117,191)">设置</el-button>
                  <el-button type="primary" @click="save(52)" style="background-color:rgb(0,117,191)">
                    <p>补打</p>
                    <p>4</p>
                  </el-button>
                  <el-button type="primary" @click="save(53)" style="background-color:rgb(0,117,191)">
                    <p>时钟</p>
                    <p>5</p>
                  </el-button>
                  <el-button type="primary" @click="save(54)" style="background-color:rgb(0,117,191)">
                    <p>设皮</p>
                    <p>6</p>
                  </el-button>
                  <el-button type="primary" @click="save(13)" style="background-color:rgb(0,117,191)">输入</el-button>
                  <el-button type="primary" @click="save(12)" style="background-color:rgb(0,117,191)">清除</el-button>
                </el-row>
                <el-row class="buttons" style="text-align:center;">
                  <el-button type="primary" @click="save(123)" style="background-color:rgb(0,117,191)">标定</el-button>
                  <el-button type="primary" @click="save(55)" style="background-color:rgb(0,117,191)">
                    <p>报表</p>
                    <p>7</p>
                  </el-button>
                  <el-button type="primary" @click="save(56)" style="background-color:rgb(0,117,191)">
                    <p>累打</p>
                    <p>8</p>
                  </el-button>
                  <el-button type="primary" @click="save(57)" style="background-color:rgb(0,117,191)">
                    <p>打印</p>
                    <p>9</p>
                  </el-button>
                  <el-button type="primary" @click="save(36)" style="background-color:rgb(0,117,191)">称重</el-button>
                  <el-button type="primary" @click="save(117)" style="background-color:rgb(0,117,191)">去皮</el-button>
                </el-row>
                <el-row class="buttons" style="text-align:center;">
                  <el-button type="primary" @click="save(121)" style="background-color:rgb(0,117,191)" v-if="($store.state.DSBC.P2004).indexOf('61B') != -1">地址</el-button>
                  <el-button type="primary" @click="save(121)" style="background-color:rgb(0,117,191)" v-else>调秤</el-button>
                  <el-button type="primary" @click="save(37)" style="background-color:rgb(0,117,191)" v-if="(this.$store.state.DSBC.P2004).indexOf('61B') != -1">
                    <p> <i class="el-icon-caret-left"></i></p>
                    <p>调角</p>
                  </el-button>
                  <el-button type="primary"  @click="save(37)" icon="el-icon-caret-left" style="background-color:rgb(0,117,191)"  v-else></el-button>
                  <el-button type="primary" @click="save(48)" style="background-color:rgb(0,117,191)">
                    <p>走纸</p>
                    <p>0</p>
                  </el-button>
                  <el-button type="primary" @click="save(39)" style="background-color:rgb(0,117,191)" v-if="($store.state.DSBC.P2004).indexOf('61B') != -1">
                    <p> <i class="el-icon-caret-right"></i></p>
                    <p>测试</p>
                  </el-button>
                  <el-button type="primary" @click="save(39)" icon="el-icon-caret-right" style="background-color:rgb(0,117,191)"  v-else></el-button>
                  <el-button type="primary" @click="save(120)" style="background-color:rgb(0,117,191)">Fn</el-button>
                  <el-button type="primary" @click="save(118)" style="background-color:rgb(0,117,191)">置零</el-button>
                </el-row>
             </div>
             <div v-else>
                <el-row class="buttons" style="text-align:center;">
                  <el-button type="primary" @click="save(128)" style="background-color:rgb(0,117,191)">地址</el-button>
                  <el-button type="primary" @click="save(123)" style="background-color:rgb(0,117,191)">标定</el-button>
                  <el-button type="primary" @click="save(49)" style="background-color:rgb(0,117,191)">
                    <p>车号</p>
                    <p>1</p>
                  </el-button>
                  <el-button type="primary" @click="save(50)" style="background-color:rgb(0,117,191)">
                    <p>货号</p>
                    <p>2</p>
                  </el-button>
                  <el-button type="primary" @click="save(51)" style="background-color:rgb(0,117,191)">
                    <p>车号</p>
                    <p>3</p>
                  </el-button>
                  <el-button type="primary" @click="save(119)" style="background-color:rgb(0,117,191)">检查</el-button>
                </el-row>
                <el-row class="buttons" style="text-align:center;">
                  <el-button type="primary" @click="save(129)" style="background-color:rgb(0,117,191)">调角</el-button>
                  <el-button type="primary" @click="save(122)" style="background-color:rgb(0,117,191)">设置</el-button>
                  <el-button type="primary" @click="save(52)" style="background-color:rgb(0,117,191)">
                    <p>补打</p>
                    <p>4</p>
                    
                  </el-button>
                  <el-button type="primary" @click="save(53)" style="background-color:rgb(0,117,191)">
                    <p>时钟</p>
                    <p>5</p>
                    
                  </el-button>
                  <el-button type="primary" @click="save(54)" style="background-color:rgb(0,117,191)">
                    <p>设皮</p>
                    <p>6</p>
                  </el-button>
                  <el-button type="primary" @click="save(13)" style="background-color:rgb(0,117,191)">输入</el-button>
                </el-row>
                <el-row class="buttons" style="text-align:center;">
                  <el-button type="primary" @click="save(130)" style="background-color:rgb(0,117,191)">自校</el-button>
                  <el-button type="primary" @click="save(125)" style="background-color:rgb(0,117,191)">毛/净</el-button>
                  <el-button type="primary" @click="save(55)" style="background-color:rgb(0,117,191)">
                    <p>报表</p>
                    <p>7</p>
                  </el-button>
                  <el-button type="primary" @click="save(56)" style="background-color:rgb(0,117,191)">
                    <p>累打</p>
                    <p>8</p>
                  </el-button>
                  <el-button type="primary" @click="save(57)" style="background-color:rgb(0,117,191)">
                    <p>清除</p>
                    <p>9</p>
                  </el-button>
                  <el-button type="primary" @click="save(36)" style="background-color:rgb(0,117,191)">称重</el-button>
                </el-row>
                <el-row class="buttons" style="text-align:center;">
                  <el-button type="primary" @click="save(131)" style="background-color:rgb(0,117,191)">测试</el-button>
                  <el-button type="primary" @click="save(127)" style="background-color:rgb(0,117,191)" >走纸</el-button>
                  <el-button type="primary" @click="save(42)" style="background-color:rgb(0,117,191)" >打印</el-button>
                  <el-button type="primary" @click="save(48)" style="background-color:rgb(0,117,191)">
                    <p>F1</p>
                    <p>0</p>
                  </el-button>
                  <el-button type="primary" @click="save(117)" style="background-color:rgb(0,117,191)">去皮</el-button>
                  <el-button type="primary" @click="save(118)" style="background-color:rgb(0,117,191)">置零</el-button>
                </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <div
      style="
        width: 100%;
        height: 40px;
        position: absolute;
        bottom: 0;
        background-color: #f5f5f5;
        line-height: 40px;
        padding: 0 40px;
      "
    >
      <el-col>
        <p style="padding: 0 20px; display: inline-block">
          故障：{{ this.$store.state.DSBC.P1004 }}
        </p>
        <p style="padding: 0 20px; display: inline-block">
          错误：{{ this.$store.state.DSBC.P1005 }}
        </p>
        <p style="padding: 0 20px 0 0; display: inline-block">
          帮助：{{ this.$store.state.DSBC.P1003 }}
        </p>
      </el-col>
    </div>
    <div
      style="width: 80px; height: 80px; position: absolute; bottom: 0;right:0;"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    ></div>
    <el-dialog
      title="数值"
      :visible.sync="DialogVisibleDa"
      width="20%"
      center
      @close="closedDa"
    >
      <div>
        <p style="margin-top: 12px">
          <el-input placeholder="请输入内容" v-model="textInput">
          </el-input>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save('inputP10080')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate,formatDate2 } from "@/common/common.js";
export default {
  name: "Debugging",
  props: {
    msg: String,
  },
  data() {
    return {
      // P2301:7,
      JingPage: this.$store.state.DSBC.P2004,
      // JingPage:localStorage.getItem('Modelvalue'),
      defaultactive: "1",
      loading: false,
      boxdialogVisible: 1,
      activeMessage: "activeMessageA", //信息
      activeWeigh: "activeWeighA", //重量码
      activeDevice: "activeDeviceD", //地址
      activeTiao: "activeTiaoA", //调角
      activeBiao: "activeBiaoA", //标定
      activeGong: "activeGongD", //功能
      activelV: "activelV", //滤波
      activeTime: "activeTimeA", //时钟
      activePass: "activePassA", //密码
      options: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      input: "",
      textInput:0,
      inputP1111: "",
      inputP1112: "",
      inputP1113: "",
      inputP1114: "",
      inputP1115: "",
      inputP1116: "",
      inputP1002: "",
      inputP2301: "",
      inputP1201: "",
      inputP1202: "",
      inputP1203: "",
      inputP1204: "",
      inputP1205: "",
      inputP1206: "",
      inputP1207: "",
      inputP1208: "",
      inputP1209: "",
      inputP1115: "",
      inputP1008: "",
      inputP1007: "",
      inputP2303: "",
      inputP2304: "",
      inputP2305: "",
      inputP2306: "",
      inputP2307: "",
      inputP2308: "",
      inputP2309: "",
      inputP2310: "",
      inputP2401: "",
      inputP2402: "",
      inputP2403: "",
      inputP2404: "",
      inputP2405: "",
      inputP2406: "",
      inputP2407: "",
      inputP2408: "",
      inputP2409: "",
      inputP2501:"",
      inputP2502:"",
      inputP1021:"",
      inputP1022:"",
      inputP2021:"",
      inputP2022:"",
      inputP1023:"",
      inputP1024:"",
      optionsP2401: [
        {
          name: 0,
          value: 1,
        },
        {
          name: 1,
          value: 2,
        },
        {
          name: 2,
          value: 5,
        },
        {
          name: 3,
          value: 10,
        },
        {
          name: 4,
          value: 20,
        },
        {
          name: 5,
          value: 50,
        },
        {
          name: 6,
          value: 100,
        },
      ],
      optionsP2404: [
        {
          name: 0,
          value: 0,
        },
        {
          name: 1,
          value: 1,
        },
        {
          name: 2,
          value: 2,
        },
        {
          name: 3,
          value: 3,
        },
      ],
      optionsP2405: [
        {
          name: 0,
          value: "t",
        },
        {
          name: 1,
          value: "kg",
        },
        {
          name: 2,
          value: "g",
        },
        {
          name: 3,
          value: "Lb",
        },
      ],
      optionsP2406: [
        {
          name: 0,
          value: "0%",
        },
        {
          name: 1,
          value: "1%",
        },
        {
          name: 2,
          value: "2%",
        },
        {
          name: 3,
          value: "4%",
        },
        {
          name: 4,
          value: "10%",
        },
        {
          name: 5,
          value: "20%",
        },
        {
          name: 6,
          value: "10%",
        },
      ],
      optionsP2407: [
        {
          name: 0,
          value: "0%",
        },
        {
          name: 1,
          value: "1%",
        },
        {
          name: 2,
          value: "2%",
        },
        {
          name: 3,
          value: "4%",
        },
        {
          name: 4,
          value: "10%",
        },
        {
          name: 5,
          value: "20%",
        },
        {
          name: 6,
          value: "10%",
        },
      ],
      optionsP2408: [
        {
          name: 0,
          value: "0e",
        },
        {
          name: 1,
          value: "0.5e",
        },
        {
          name: 2,
          value: "1e",
        },
        {
          name: 3,
          value: "2e",
        },
        {
          name: 4,
          value: "3e",
        },
        {
          name: 5,
          value: "4e",
        },
        {
          name:6,
          value: "5e",
        },
      ],
      optionsP2409: [
        {
          name: 0,
          value: "0e",
        },
        {
          name: 1,
          value: "1e",
        },
        {
          name: 2,
          value: "2e",
        },
        {
          name: 3,
          value: "3e",
        },
        {
          name: 4,
          value: "4e",
        },
        {
          name: 5,
          value: "5e",
        },
      ],
      DialogVisibleDa: false,
    };
  },
  created() {
    
    if (localStorage.getItem("defaultactive") != undefined) {
      this.defaultactive = JSON.parse(localStorage.getItem("defaultactive"));
      this.boxdialogVisible = JSON.parse(localStorage.getItem("defaultactive"));
      if (JSON.parse(localStorage.getItem("defaultactive")) == 1) {
        if (localStorage.getItem("tabName") != undefined) {
          this.activeMessage = JSON.parse(localStorage.getItem("tabName"));
        }
      } else if (JSON.parse(localStorage.getItem("defaultactive")) == 2) {
        if (localStorage.getItem("tabName") != undefined) {
          this.activeWeigh = JSON.parse(localStorage.getItem("tabName"));
        }
      } else if (JSON.parse(localStorage.getItem("defaultactive")) == 3) {
        if (localStorage.getItem("tabName") != undefined) {
          this.activeDevice = JSON.parse(localStorage.getItem("tabName"));
        }
      } else if (JSON.parse(localStorage.getItem("defaultactive")) == 4) {
        if (localStorage.getItem("tabName") != undefined) {
          this.activeTiao = JSON.parse(localStorage.getItem("tabName"));
        }
      } else if (JSON.parse(localStorage.getItem("defaultactive")) == 5) {
        if (localStorage.getItem("tabName") != undefined) {
          this.activeBiao = JSON.parse(localStorage.getItem("tabName"));
        }
      } else if (JSON.parse(localStorage.getItem("defaultactive")) == 6) {
        if (localStorage.getItem("tabName") != undefined) {
          this.activeGong = JSON.parse(localStorage.getItem("tabName"));
        }
      } else if (JSON.parse(localStorage.getItem("defaultactive")) == 7) {
        if (localStorage.getItem("tabName") != undefined) {
          this.activelV = JSON.parse(localStorage.getItem("tabName"));
        }
      } else if (JSON.parse(localStorage.getItem("defaultactive")) == 8) {
        if (localStorage.getItem("tabName") != undefined) {
          this.activeTime = JSON.parse(localStorage.getItem("tabName"));
        }
      } else if (JSON.parse(localStorage.getItem("defaultactive")) == 9) {
        if (localStorage.getItem("tabName") != undefined) {
          this.activePass = JSON.parse(localStorage.getItem("tabName"));
        }
      }
    }
  },
  mounted() {
    window.addEventListener("setItem", () => {
        console.log('监听',localStorage.getItem('currentModelRow'))
        this.JingPage = localStorage.getItem('currentModelRow')
    });
  },
  methods: {
    warnNewing(value) {
      var NewList = this.$store.state.newList
      var dateTime = new Date();
      var newS = "时间：" + formatDate2(dateTime) + ' 请求信息' + value
      NewList.unshift(newS)
      this.$store.commit("newList",NewList);
    },
    openbox() {
      this.DialogVisibleDa = true;
      this.textInput = 0
    },
    closedDa() {
      this.DialogVisibleDa = false;
    },
    open(val) {
      if (this.$store.state.timer) {
        clearInterval(this.$store.state.timer);
        this.$store.state.timer = null;
      }
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
        console.log("1111111");
      }
      var that = this;
      console.log(val);
      that.timer = setTimeout(function () {
        if (val == "inputP2301") {
          that.$refs.inputP2301.blur();
        } else if (val == "inputP1201") {
          that.$refs.inputP1201.blur();
        } else if (val == "inputP1002") {
          that.$refs.inputP1002.blur();
        } else if (val == "inputP1202") {
          that.$refs.inputP1202.blur();
        } else if (val == "inputP1203") {
          that.$refs.inputP1203.blur();
        } else if (val == "inputP1204") {
          that.$refs.inputP1204.blur();
        } else if (val == "inputP1205") {
          that.$refs.inputP1205.blur();
        } else if (val == "inputP1206") {
          that.$refs.inputP1206.blur();
        } else if (val == "inputP1207") {
          that.$refs.inputP1207.blur();
        } else if (val == "inputP1208") {
          that.$refs.inputP1208.blur();
        } else if (val == "inputP1209") {
          that.$refs.inputP1209.blur();
        } else if (val == "inputP1115") {
          that.$refs.inputP1115.blur();
        } else if (val == "inputP1007") {
          that.$refs.inputP1007.blur();
        } else if (val == "inputP1008") {
          that.$refs.inputP1008.blur();
        } else if (val == "inputP2303") {
          that.$refs.inputP2303.blur();
        } else if (val == "inputP2304") {
          that.$refs.inputP2304.blur();
        } else if (val == "inputP2305") {
          that.$refs.inputP2305.blur();
        } else if (val == "inputP2306") {
          that.$refs.inputP2306.blur();
        } else if (val == "inputP2307") {
          that.$refs.inputP2307.blur();
        } else if (val == "inputP2308") {
          that.$refs.inputP2308.blur();
        } else if (val == "inputP2309") {
          that.$refs.inputP2309.blur();
        } else if (val == "inputP2310") {
          that.$refs.inputP2310.blur();
        } else if (val == "inputP2401") {
          that.$refs.inputP2401.blur();
        } else if (val == "inputP2402") {
          that.$refs.inputP2402.blur();
        } else if (val == "inputP2403") {
          that.$refs.inputP2403.blur();
        } else if (val == "inputP2404") {
          that.$refs.inputP2404.blur();
        } else if (val == "inputP2405") {
          that.$refs.inputP2405.blur();
        } else if (val == "inputP2406") {
          that.$refs.inputP2406.blur();
        } else if (val == "inputP2407") {
          that.$refs.inputP2407.blur();
        } else if (val == "inputP2408") {
          that.$refs.inputP2408.blur();
        } else if (val == "inputP2409") {
          that.$refs.inputP2409.blur();
        }else if (val == "inputP2501") {
          that.$refs.inputP2501.blur();
        }else if (val == "inputP2502") {
          that.$refs.inputP2502.blur();
        }else if (val == "inputP1021") {
          that.$refs.inputP1021.blur();
        }else if (val == "inputP1022") {
          that.$refs.inputP1022.blur();
        }else if (val == "inputP1023") {
          that.$refs.inputP1023.blur();
        }else if (val == "inputP1024") {
          that.$refs.inputP1024.blur();
        }else if (val == "inputP2021") {
          that.$refs.inputP2021.blur();
        }else if (val == "inputP2022") {
          that.$refs.inputP2022.blur();
        }

       
      }, 10000);
    },
    // 保存参数
    save(val) {
      console.log(val);
      var dynParmRules = [];
      if (val == "inputP1111") {
        dynParmRules = [
          {
            field: "P1111",
            value: this.$store.state.DSBC.P1111,
            type: "string",
          },
        ];
      } else if (val == "inputP1112") {
        dynParmRules = [
          {
            field: "P1112",
            value: this.$store.state.DSBC.P1112,
            type: "string",
          },
        ];
      } else if (val == "inputP1113") {
        dynParmRules = [
          {
            field: "P1113",
            value: this.$store.state.DSBC.P1113,
            type: "string",
          },
        ];
      } else if (val == "inputP1114") {
        dynParmRules = [
          {
            field: "P1114",
            value: this.$store.state.DSBC.P1114,
            type: "string",
          },
        ];
      } else if (val == "inputP1115") {
        dynParmRules = [
          {
            field: "P1115",
            value: this.$store.state.DSBC.P1115,
            type: "int",
          },
        ];
      } else if (val == "inputP1116") {
        dynParmRules = [
          {
            field: "P1116",
            value: this.$store.state.DSBC.P1116,
            type: "string",
          },
        ];
      } else if (val == "inputP2301") {
        dynParmRules = [
          {
            field: "P2301",
            value: this.$store.state.DSBC.P2301,
            type: "int",
          },
        ];
      } else if (val == "inputP1002") {
        dynParmRules = [
          {
            field: "P1002",
            value: this.$store.state.DSBC.P1002,
            type: "string",
          },
        ];
      } else if (val == "inputP1201") {
        dynParmRules = [
          {
            field: "P1201",
            value: this.$store.state.DSBC.P1201,
            type: "string",
          },
        ];
      } else if (val == "inputP1209") {
        dynParmRules = [
          {
            field: "P1209",
            value: this.$store.state.DSBC.P1209,
            type: "int",
          },
        ];
      } else if (val == "inputP1202") {
        dynParmRules = [
          {
            field: "P1202",
            value: true,
            type: "int",
          },
        ];
      } else if (val == "inputP1203") {
        dynParmRules = [
          {
            field: "P1203",
            value: this.$store.state.DSBC.P1203,
            type: "int",
          },
        ];
      } else if (val == "inputP1204") {
        dynParmRules = [
          {
            field: "P1204",
            value: this.$store.state.DSBC.P1204,
            type: "int",
          },
        ];
      } else if (val == "inputP1205") {
        dynParmRules = [
          {
            field: "P1205",
            value: this.$store.state.DSBC.P1205,
            type: "int",
          },
        ];
      } else if (val == "inputP1206") {
        dynParmRules = [
          {
            field: "P1206",
            value: this.$store.state.DSBC.P1206,
            type: "int",
          },
        ];
      } else if (val == "inputP1207") {
        dynParmRules = [
          {
            field: "P1207",
            value: this.$store.state.DSBC.P1207,
            type: "int",
          },
        ];
      } else if (val == "inputP1208") {
        dynParmRules = [
          {
            field: "P1208",
            value: this.$store.state.DSBC.P1208,
            type: "int",
          },
        ];
      } else if (val == "inputP1115") {
        dynParmRules = [
          {
            field: "P1115",
            value: this.$store.state.DSBC.P1115,
            type: "int",
          },
        ];
      } else if (val == "inputP1007") {
        dynParmRules = [
          {
            field: "P1007",
            value: this.$store.state.DSBC.P1007,
            type: "string",
          },
        ];
      } else if (val == "inputP1008") {
        dynParmRules = [
          {
            field: "P1008",
            value: this.$store.state.DSBC.P1008,
            type: "int",
          },
        ];
      } else if (val == "inputP10080") {
        dynParmRules = [
          {
            field: "P1008",
            value: this.textInput,
            type: "string",
          },
        ];
      } else if (val == "inputP2303") {
        dynParmRules = [
          {
            field: "P2303",
            value: this.$store.state.DSBC.P2303,
            type: "string",
          },
        ];
      } else if (val == "inputP2304") {
        dynParmRules = [
          {
            field: "P2304",
            value: this.$store.state.DSBC.P2304,
            type: "string",
          },
        ];
      } else if (val == "inputP2305") {
        dynParmRules = [
          {
            field: "P2305",
            value: this.$store.state.DSBC.P2305,
            type: "string",
          },
        ];
      } else if (val == "inputP2306") {
        dynParmRules = [
          {
            field: "P2306",
            value: this.$store.state.DSBC.P2306,
            type: "string",
          },
        ];
      } else if (val == "inputP2307") {
        dynParmRules = [
          {
            field: "P2307",
            value: this.$store.state.DSBC.P2307,
            type: "string",
          },
        ];
      } else if (val == "inputP2308") {
        dynParmRules = [
          {
            field: "P2308",
            value: this.$store.state.DSBC.P2308,
            type: "string",
          },
        ];
      } else if (val == "inputP2309") {
        dynParmRules = [
          {
            field: "P2309",
            value: this.$store.state.DSBC.P2309,
            type: "string",
          },
        ];
      } else if (val == "inputP2310") {
        dynParmRules = [
          {
            field: "P2310",
            value: this.$store.state.DSBC.P2310,
            type: "string",
          },
        ];
      } else if (val == "inputP2401") {
        dynParmRules = [
          {
            field: "P2401",
            value: this.$store.state.DSBC.P2401,
            type: "int",
          },
        ];
      } else if (val == "inputP2402") {
        dynParmRules = [
          {
            field: "P2402",
            value: this.$store.state.DSBC.P2402,
            type: "string",
          },
        ];
      } else if (val == "inputP2403") {
        dynParmRules = [
          {
            field: "P2403",
            value: this.$store.state.DSBC.P2403,
            type: "string",
          },
        ];
      } else if (val == "inputP2404") {
        dynParmRules = [
          {
            field: "P2404",
            value: this.$store.state.DSBC.P2404,
            type: "int",
          },
        ];
      } else if (val == "inputP2405") {
        dynParmRules = [
          {
            field: "P2405",
            value: this.$store.state.DSBC.P2405,
            type: "int",
          },
        ];
      } else if (val == "inputP2406") {
        dynParmRules = [
          {
            field: "P2406",
            value: this.$store.state.DSBC.P2406,
            type: "int",
          },
        ];
      } else if (val == "inputP2407") {
        dynParmRules = [
          {
            field: "P2407",
            value: this.$store.state.DSBC.P2407,
            type: "int",
          },
        ];
      } else if (val == "inputP2408") {
        dynParmRules = [
          {
            field: "P2408",
            value: this.$store.state.DSBC.P2408,
            type: "int",
          },
        ];
      } else if (val == "inputP2409") {
        dynParmRules = [
          {
            field: "P2409",
            value: this.$store.state.DSBC.P2409,
            type: "int",
          },
        ];
        
      } else if (val == "inputP2501") {
        dynParmRules = [
          {
            field: "P2501",
            value: this.$store.state.DSBC.P2501,
            type: "int",
          },
        ];
        
      } else if (val == "inputP2502") {
        dynParmRules = [
          {
            field: "P2502",
            value: this.$store.state.DSBC.P2502,
            type: "int",
          },
        ];
        
      } else if (val == "inputP1021") {
        dynParmRules = [
          {
            field: "P1022",
            value: this.$store.state.DSBC.P1021,
            type: "string",
          },
        ];
        
      } else if (val == "inputP1022") {
        dynParmRules = [
          {
            field: "P1022",
            value: this.$store.state.DSBC.P1022,
            type: "string",
          },
        ];
        
      } else if (
        val == 211 ||
        val == 212 ||
        val == 213 ||
        val == 231 ||
        val == 232 ||
        val == 234 ||
        val == 311 ||
        val == 313 ||
        val == 314 ||
        val == 325
      ) {
        dynParmRules = [
          {
            field: "P1006",
            value: val,
            type: "int",
          },
        ];
      } else if (
        val == 180 ||
        val == 233 ||
        val == 500 ||
        val == 501 ||
        val == 502 ||
        val == 503 ||
        val == 261 ||
        val == 321 ||
        val == 214 ||
        val == 36 ||
        val == 39 ||
        val == 13 ||
        val == 118 ||
        val == 120 ||
        val == 37 ||
        val == 121 ||
        val == 117 ||
        val == 123 ||
        val == 12 ||
        val == 122 ||
        val == 119 ||
        val == 8 ||
        val == 124 ||
        val == 48 ||
        val == 49 ||
        val == 50 ||
        val == 51 ||
        val == 52 ||
        val == 53 ||
        val == 54 ||
        val == 55 ||
        val == 56 ||
        val == 57 ||
        val == 42 ||
        val == 127 ||
        val == 131 ||
        val == 125 ||
        val == 130 ||
        val == 129 ||
        val == 128
      ) {
        dynParmRules = [
          {
            field: "P1007",
            value: val,
            type: "int",
          },
        ];
      }

      this.loading = true;
      this.$myaxios(
        "post",
        "/api/main/common/ExecuteSqlTable",
        {
          table: this.$store.state.DSBC.ID ? this.$store.state.DSBC.ID : JSON.parse(localStorage.getItem("currentRow")).ID,
          moduleName: "DownloadPara",
          dynParmRules: dynParmRules,
        }
        // "formdata"
      ).then((res) => {
        if (res.data.Code == 0) {
          if(JSON.parse(localStorage.getItem("loginData")).UserName  == 'admin' || JSON.parse(localStorage.getItem("loginData")).UserName  == 'dpt' || JSON.parse(localStorage.getItem("loginData")).UserName  == 'highsor' || JSON.parse(localStorage.getItem("loginData")).UserName == 'haishuo') {
            this.$message({
            message: "保存成功！",
            type: "success",
            duration: 1500,
          });
          }
          
          this.closedDa();

          if (
            val == 180 ||
            val == 211 ||
            val == 212 ||
            val == 213 ||
            val == 214 ||
            val == 231 ||
            val == 232 ||
            val == 233 ||
            val == 234 ||
            val == 311 ||
            val == 313 ||
            val == 314 ||
            val == 500 ||
            val == 501 ||
            val == 502 ||
            val == 503 ||
            val == 261 ||
            val == 321 ||
            val == 322 ||
            val == 323 ||
            val == 324 ||
            val == 325 ||
            val == 36 ||
            val == 39 ||
            val == 13 ||
            val == 118 ||
            val == 120 ||
            val == 37 ||
            val == 121 ||
            val == 117 ||
            val == 123 ||
            val == 12 ||
            val == 122 ||
            val == 119 ||
            val == 8 ||
            val == 124 ||
            val == 48 ||
            val == 49 ||
            val == 50 ||
            val == 51 ||
            val == 52 ||
            val == 53 ||
            val == 54 ||
            val == 55 ||
            val == 56 ||
            val == 57
          ) {
            
          } 
        } else {
           if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
        }
        this.loading = false;
         this.warnNewing(res.data.Message)
      }).catch(() => {
        this.loading = false;
      });
    },
    
    // 菜单点击
    handleSelect(key, keyPath) {
      this.boxdialogVisible = key;
      localStorage.setItem(
        "defaultactive",
        JSON.stringify(this.boxdialogVisible)
      );
      localStorage.removeItem("tabName");
    },

    BiaoClick(tab, event) {
      localStorage.setItem("tabName", JSON.stringify(tab.name));
      console.log(tab.name, event);
    },
     getBack() {
       this.$message({
        message: '该账户已在其他地方登录，请重新登录',
        type: "warning",
        duration: 1500,
      });
      this.$router.push({ path: "/" });
      localStorage.clear();
    }
  },
  computed: {},
  watch: {
    activeDevice() {
      if (this.activeDevice == "activeDeviceA") {
        this.save(211);
      } else if (this.activeDevice == "activeDeviceB") {
        this.save(212);
      } else if (this.activeDevice == "activeDeviceC") {
        this.save(213);
      } else if (this.activeDevice == "activeDeviceD") {
        // this.save(214);
      }
    },
    activeTiao() {
      if (this.activeTiao == "activeTiaoB") {
        console.log("activeTiaoB");
        this.save(231);
      } else if (this.activeTiao == "activeTiaoC") {
        this.save(232);
      } else if (this.activeTiao == "activeTiaoD") {
        this.save(234);
      } else if (this.activeTiao == "activeTiaoA") {
        // this.save(233);
      }
    },
    activeBiao() {
      if (this.activeBiao == "activeBiaoB") {
        this.save(311);
      } else if (this.activeBiao == "activeBiaoC") {
        this.save(314);
      } else if (this.activeBiao == "activeBiaoE") {
        this.save(313);
      } else if (this.activeBiao == "activeBiaoF") {
        // this.save(324);
      } else if (this.activeBiao == "activeBiaoG") {
        this.save(325);
      } else if (this.activeBiao == "activeBiaoH") {
        // this.save(323);
      } else if (this.activeBiao == "activeBiaoJ") {
        // this.save(322);
      }
    },
    activeGong() {
      if (this.activeGong == "activeGongA") {
        this.save(501);
      } else if (this.activeGong == "activeGongB") {
        // this.save(502);
      } else if (this.activeGong == "activeGongC") {
        // this.save(503);
      } else if (this.activeGong == "activeGongD") {
        this.save(180);
      } else if (this.activeGong == "activeGongF") {
        this.save(500);
      }
    },
    // activeTime () {
    //   if (this.activeTime == "activeTime") {
    //       this.save(180);
    //     }
    // },
    activePass() {
      if (this.activePass == "activePassA") {
        this.save(500);
      } else if (this.activePass == "activePassB") {
        this.save(261);
      } else if (this.activePass == "activePassC") {
        this.save(321);
      }
    },
    boxdialogVisible() {
      if (this.boxdialogVisible == 1) {
      } else if (this.boxdialogVisible == 2) {
      } else if (this.boxdialogVisible == 3) {
        if (this.activeDevice == "activeDeviceA") {
          this.save(211);
        } else if (this.activeDevice == "activeDeviceB") {
          this.save(212);
        } else if (this.activeDevice == "activeDeviceC") {
          this.save(213);
        } else if (this.activeDevice == "activeDeviceD") {
          // this.save(214);
        }
      } else if (this.boxdialogVisible == 4) {
        if (this.activeTiao == "activeTiaoB") {
          console.log("activeTiaoB");
          this.save(231);
        } else if (this.activeTiao == "activeTiaoC") {
          this.save(232);
        } else if (this.activeTiao == "activeTiaoD") {
          this.save(234);
        } else if (this.activeTiao == "activeTiaoA") {
          // this.save(233);
        }
      } else if (this.boxdialogVisible == 5) {
        if (this.activeBiao == "activeBiaoB") {
          this.save(311);
        } else if (this.activeBiao == "activeBiaoC") {
          this.save(314);
        } else if (this.activeBiao == "activeBiaoE") {
          this.save(313);
        } else if (this.activeBiao == "activeBiaoF") {
          // this.save(324);
        } else if (this.activeBiao == "activeBiaoG") {
          this.save(325);
        } else if (this.activeBiao == "activeBiaoH") {
          // this.save(323);
        } else if (this.activeBiao == "activeBiaoJ") {
          // this.save(322);
        }
      } else if (this.boxdialogVisible == 6) {
        if (this.activeGong == "activeGongA") {
          this.save(501);
        } else if (this.activeBiao == "activeGongB") {
          // this.save(502);
        } else if (this.activeBiao == "activeGongC") {
          // this.save(503);
        } else if (this.activeBiao == "activeGongD") {
          this.save(180);
        } else if (this.activeBiao == "activeGongE") {
          this.save(180);
        } else if (this.activeGong == "activeGongF") {
          this.save(500);
        }
      } else if (this.boxdialogVisible == 7) {
      } else if (this.boxdialogVisible == 8) {
        // if (this.activeTime == "activeTimeA") {
        //           this.save(180);
        //         }
      } else if (this.boxdialogVisible == 9) {
        if (this.activePass == "activePassA") {
          this.save(500);
        } else if (this.activePass == "activePassB") {
          this.save(261);
        } else if (this.activePass == "activePassC") {
          this.save(321);
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrap {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f4f4f4;
  font-size: 20px;
  overflow-y: scroll;
  .box_left {
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 0 48px 60px !important;
    border-right: 1px solid #dddddd;
  }
  .box-right {
    height: 100%;
    overflow-y: scroll;
    .topbox {
      height: 59px;
      border-bottom: 1px solid #dddddd;
    }
    .bottombox {
      box-sizing: border-box;
      padding: 40px;
    }
    label,
    span {
      vertical-align: middle;
    }
  }
  .weigh_box {
    height: 100%;
    background-color: #fff;
    .weigh_text {
      .weigh_col {
        padding: 24px 0;
        vertical-align: bottom;
        label {
          display: inline-block;
          vertical-align: middle;
        }
        .state {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 60%;
          vertical-align: middle;
          margin-right: 12px;
          text-align: center;
          line-height: 20px;
          border: 2px solid #dddddd;
          span {
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 3px;
            background-color: #dddddd;
          }
        }
      }
      .weigh_p {
        display: inline-block;
        margin-right: 0;
      }
      .weigh_p:last-of-type {
        margin-right: 0;
      }
    }
    label {
      display: inline-block;
      height: 4rem;
      line-height: 4rem;
      vertical-align: middle;
      // width: 12rem;
      margin-right: 12px;
    }
    .el-input {
      width: 200px;
    }
    .padbox {
      box-sizing: border-box;
      padding: 1.5rem;
    }
    .marbox {
      margin: 10px 0;
    }
  }
  ::v-deep .el-input__inner {

color: #000000;
  }
  .inputCent {
    ::v-deep .el-input__inner {
    text-align: center;
    height: 55px;
  }
  }
  .zhidu span {
    vertical-align: top;
    display: inline-block;
    width: 220px;
    height: 40px;
    padding: 0 15px;
    line-height: 40px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 20px;
    color: #000000;
    background-color: #f5f5f5;
    // margin-top: 10px;
  }
  .el-tabs--border-card {
    box-shadow: none;
  }
  .buttons .el-button {
    margin: 14px 14px 0 0;
    width: 72px;
    height: 62px;
    vertical-align: middle;
  }
  .weigh_p {
    display: inline-block;
    margin-right: 126px;
  }
  .weigh_p:last-of-type {
    margin-right: 0;
  }
  .bottomNum {
    padding: 20px 0;
    color: #888888;
    font-size: 20px;

    .weightBottom {
      p {
        display: inline-block;
        margin-right: 28px;
        span:first-of-type {
          margin-right: 12px;
        }
      }
    }
  }
  .state {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 60%;
    vertical-align: middle;
    margin-right: 12px;
    text-align: center;
    line-height: 17px;
    border: 2px solid #dddddd;
    span {
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 3px;
      background-color: #dddddd;
    }
  }
  .el-select {
    width: 200px;
   
  }
  
  
  .el-input,
  ::v-deep .el-select .el-input__inner {
    font-size: 18px;
    color: #000000;
  }
  .el-menu-item {
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    border-bottom: 1px solid #dddddd;
  }
  ::v-deep .el-tabs__item {
    width: 136px;
    text-align: center;
    font-size: 20px;
    height: 48px;
    line-height: 48px;
  }
  ::v-deep.el-tabs--border-card > .el-tabs__content {
    padding: 40px 45px;
  }
  ::v-deep .el-loading-spinner {
    margin-top: -35px;
  }
  ::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #ffffff;
  }
}
.disabledBg ::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #f5f5f5;
  }
</style>
