export function dyParameter (_this, arr) {
    let ParmRules = []
    let list = arr
    let obj
    for (let i of list) {
      if(i == "password") {
         obj = {
          field: i,
          value: _this[i],
          type: 'userkey',
        }
        
      }else {
        
         obj = {
          field: i,
          value: _this[i],
          type: 'string',
        }
        
      }
      ParmRules.push(obj)
      
    }
    return ParmRules
  }
  export function formatDate(date) {
    console.log(date);
    console.log(date);
    var y = date.getFullYear();
    console.log(y);
    var m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
  }

  
  export function formatDate2(date) {
    console.log(date);
    console.log(date);
    var y = date.getFullYear();
    console.log(y);
    var m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours()
    h = h < 10 ? ('0' + h) : h;
    var M = date.getMinutes()
    M = M < 10 ? ('0' + M) : M;
    var s = date.getSeconds()
    s = s < 10 ? ('0' + s) : s;
    return y + '-' + m + '-' + d + ' ' + h + ':' + M + ':' + s;
  }

  export function ArrayFilter(tempArr) {
      let newArr = [];
      for (let i = 0; i < tempArr.length; i++) {
          if (newArr.indexOf(tempArr[i].Model) == -1) {
              newArr.push(tempArr[i].Model);
          } else {
              tempArr.splice(i, 1);
          };
      };
      return tempArr;
  };