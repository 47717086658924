<template>
  <div class="home wraps">
    <div class="boxleft">
      <el-row class="home_Title home_logo">
        <img :src="UserImg" alt="" style="width: 100%; height: 100%" />
      </el-row>
      <el-row class="home_menu">
        <el-menu
          :default-active="defaultactive"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          @select="handleSelect"
        >
          <template v-for="(item, index) in menulist">
            <el-menu-item
              :key="index"
              :index="item.index"
              style="padding: 0 40px"
              @click="gopath(item)"
            >
              <img :src="item.src" alt="" />
              <span
                slot="title"
                style="vertical-align: middle; margin-left: 28px"
                >{{ item.res_name }}</span
              >
            </el-menu-item>
          </template>
        </el-menu>
      </el-row>
    </div>
    <div class="boxright">
      <el-row class="home_Title" style="padding: 0 40px 0 40px">
        <el-col :span="8"> 宁波海硕传感器有限公司 </el-col>
        <el-col :span="16" class="home_topright">
          <img src="@/assets/home01.png" alt="" @click="goDevice" />
          <img src="@/assets/set02.png" alt="" />
          <img src="@/assets/outlogin.png" alt="" @click="signout" />
          <!-- <img src="@/assets/iconHead.png" alt="" /> -->
          <span
            style="background-color: red"
            v-if="loginData.Field == 'CreateUserId'"
          ></span>
          <span
            style="background-color: #409eff"
            v-if="loginData.Field == 'AdminUserId'"
          ></span>
          <span
            style="background-color: green"
            v-if="loginData.Field == 'UserId'"
          ></span>
          <el-dropdown>
            <label for="">{{ loginData.Role }}:{{ loginData.FullName }}</label>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="signout">
                退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-popover
          placement="bottom"
          trigger="click"
          style="float: right; margin-right: 20px; width: 100px"
        >
          <el-button
            class="table_waianniu"
            type="primary"
            style="margin-left: 20px"
            @click="updatePassword()"
          >
            修改密码</el-button
          >
          <el-button
            class="table_waianniu"
            type="primary"
            style="margin-left: 20px"
            @click="logout()"
          >
            退出登录</el-button
          >
        </el-popover>
      </el-row>
      <el-row class="home_Children">
        <router-view />
      </el-row>
    </div>
     
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      menulist: [
        {
          res_name: "个人资料",
          index: "1",
          resid: "/Material",
          src: require("@/assets/icon01.png"),
        },
        {
          res_name: "客户管理",
          index: "2",
          resid: "/Company",
          src: require("@/assets/icon02.png"),
        },
        {
          res_name: "售后管理",
          index: "3",
          resid: "/Service",
          src: require("@/assets/icon03.png"),
        },
        {
          res_name: "用户管理",
          index: "4",
          resid: "/User",
          src: require("@/assets/icon04.png"),
        },
        {
          res_name: "产品管理",
          index: "6",
          resid: "/Product",
          src: require("@/assets/icon05.png"),
        },
        {
          res_name: "设备管理",
          index: "5",
          resid: "/Manage",
          src: require("@/assets/icon06.png"),
        },
        {
          res_name: "设备注册",
          index: "8",
          resid: "/Register",
          src: require("@/assets/icon07.png"),
        },
        {
          res_name: "关于",
          index: "7",
          resid: "/About",
          src: require("@/assets/icon08.png"),
        },
      ],
      leftMenu: [],
      defaultactive: "1",
      loginData: "",
      UserImg: "",
      IP:''
    };
  },

  created() {
    this.loginData = JSON.parse(localStorage.getItem("loginData"));
    this.leftMenu = JSON.parse(localStorage.getItem("loginData")).topmenus;
    if (!JSON.parse(localStorage.getItem("loginData")).Url) {
      this.UserImg = require("@/assets/logo.png");
    } else {
      this.UserImg = "/api/main" + this.loginData.Url;
    }
    var data = [];
    for (var i in this.menulist) {
      for (var j in this.leftMenu) {
        if (this.menulist[i].res_name == this.leftMenu[j].res_name) {
          data.push(this.menulist[i]);
        }
      }
    }

    this.menulist = data;
    if (localStorage.getItem("defaultactiveHome") != undefined) {
      this.defaultactive = JSON.parse(
        localStorage.getItem("defaultactiveHome")
      );
    } else {
      this.defaultactive = data[0].index;
    }

    console.log(
      this.menulist[0].res_name,
      this.leftMenu[0].res_name,
      this.defaultactive,
      "666666666"
    );
  },
  mounted() {
    this.loginData = JSON.parse(localStorage.getItem("loginData"));
  },
  methods: {
    
    gopath(val) {
      this.$router.push({ path: val.resid });
    },
    goDevice() {
      this.$router.push({ path: "/Weigh" });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(key, keyPath) {
      this.defaultactive = key;
      localStorage.setItem(
        "defaultactiveHome",
        JSON.stringify(this.defaultactive)
      );
    },
    signout() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
  },
  computed: {},
 
};
</script>
<style lang="scss" scoped>
.wraps {
  .boxleft {
    box-sizing: border-box;
    display: inline-block;
    width: 308px;
    background-color: red;
    height: 100%;
    vertical-align: top;
    border-right: 1px solid #dddddd;
  }
  .boxright {
    display: inline-block;
    width: calc(100% - 308px);
    background-color: yellow;
    height: 100%;
    vertical-align: top;
  }
  .home_Title {
    font-size: 24px;
    height: 120px;
    line-height: 120px;
    background-color: #ffffff;
    vertical-align: top;
    color: #333333;
    border-bottom: 1px solid #dddddd;
    .home_topright {
      height: 100%;
      box-sizing: border-box;
      padding-right: 2rem;
      text-align: right;
      span {
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 60%;

        vertical-align: top;
        margin: 45px 40px;
      }
      img,
      label {
        vertical-align: top;
        font-size: 24px;
      }
    }
    .el-dropdown {
      vertical-align: top;
      height: 120px;
    }
  }

  .home_Children,
  .home_menu {
    width: 100%;
    height: calc(100% - 120px);
    background-color: #ffffff;
  }
  .home_Children {
    padding: 40px;
    background-color: #f5f5f5;
  }
  .home_menu {
    overflow-y: scroll;
    img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
    }
  }
  .home_logo {
    // background-image: url(../assets/logo.png);
    // background-size: 100% 100%;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  img {
    width: 120px;
    height: 120px;
    vertical-align: top;
  }
  .el-menu-item {
    font-size: 20px;
    height: 56px;
    line-height: 56px;
    color: #666666;
  }
  .el-menu-item.is-active {
    color: #409eff;
  }
  
}

</style>