<template>
  <div class="home wraps">
    <div class="boxleft">
      <el-row class="home_Title home_logo">
        <img :src="UserImg" alt="" style="width: 100%; height: 100%" />
      </el-row>
      <el-row class="home_menu">
        <template v-for="(item, index) in menulist">
          <el-col
            :key="index"
            v-if="item.res_name == '客户'"
            style="margin-bottom: 16px"
          >
            <p style="margin-bottom: 16px">客户：</p>
            <el-select
              v-model="CompanyValue"
              placeholder="请选择"
              @change="getUser(1)"
            >
              <el-option
                v-for="item in CompanySet"
                :key="item.OwnGuid"
                :label="item.Company"
                :value="item.OwnGuid"
              >
              </el-option>
            </el-select>
          </el-col>
          <!-- <el-col :key="index" v-if="item.res_name == '售后'">
            售后：
            <el-select
              v-model="ServiceValue"
              placeholder="请选择"
              @change="getUser"
            >
              <el-option
                v-for="item in ServiceSet"
                :key="item.OwnGuid"
                :label="item.FullName"
                :value="item.OwnGuid"
              >
              </el-option>
            </el-select>
          </el-col> -->
          <el-col
            :key="index"
            v-if="item.res_name == '用户'"
            style="margin-bottom: 16px"
          >
            <p style="margin-bottom: 16px">用户：</p>
            <el-select
              v-model="UserValue"
              placeholder="请选择"
              @change="getsu(1)"
            >
              <el-option
                v-for="item in UserSet"
                :key="item.OwnGuid"
                :label="item.FullName"
                :value="item.OwnGuid"
              >
              </el-option>
            </el-select>
          </el-col>
        </template>

        <el-col style="margin-bottom: 16px">
          <p style="margin-bottom: 16px">型号：</p>
          <el-select
            v-model="Modelvalue"
            filterable
            :popper-append-to-body="false"
 popper-class="eloption"
            @change="getDevice(1)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in ModelList"
              :key="item.Model"
              :label="item.Model"
              :value="item.Model"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col style="margin-bottom: 16px">
          <p style="margin-bottom: 16px">设备：{{currentRow.FullName}}</p>
          <el-input
            placeholder="请输入内容"
            v-model="DeviceValue"
            class="input-with-select"
            @input="changeInput"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getDevice"
            ></el-button>
          </el-input>
          <el-table
            :data="DeviceList"
            style="width: 100%; margin-top: 16px,background-color:#ffffff;"
            highlight-current-row
            ref="multipleTable"
            @current-change="handleCurrentChange"
            :height="300"
          >
            <el-table-column prop="ID" label="状态" width="50">
              <template slot-scope="scope">
                <p v-if="scope.row.Ponline == true">
                  <!-- 在线  -->
                  <img
                    src="@/assets/upicon.png"
                    alt=""
                    style="width: 35px; height: 35px"
                    v-if="scope.row.P1004 == '正常'"
                  />
                  <img
                    src="@/assets/upiconfault.png"
                    alt=""
                    style="width: 35px; height: 35px"
                    v-else
                  />
                </p>
                <p v-else-if="scope.row.Ponline == false ">
                  <!-- 下线 -->
                  <img
                    src="@/assets/downicon.png"
                    alt=""
                    style="width: 35px; height: 35px"
                    v-if="scope.row.P1004 == '正常'"
                  />
                  <img
                    src="@/assets/downiconfault.png"
                    alt=""
                    style="width: 35px; height: 35px"
                    v-else
                  />
                </p>
                <p v-else>故障</p>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="ID" label="ID"> </el-table-column> -->
            <el-table-column prop="FullName" label="名称"> </el-table-column>
          </el-table>
        </el-col>
        <el-col class="tablepage">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrents"
            :current-page="currentPage"
            background
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagesize"
            layout="total, prev, pager, next"
            :total="Count"
          >
          </el-pagination>
        </el-col>
      </el-row>
    </div>
    <div class="boxright">
      <el-row class="home_Title" style="padding: 0 40px 0 40px">
        <el-col :span="8"> 宁波海硕传感器有限公司 </el-col>
        <el-col :span="16" class="home_topright">
          <img src="@/assets/home02.png" alt="" />
          <img src="@/assets/set01.png" alt="" @click="goHome" />
          <img src="@/assets/outlogin.png" alt="" @click="signout" />
          <!-- <img src="@/assets/iconHead.png" alt="" /> -->
          <span
            style="background-color: red"
            v-if="loginData.Field == 'CreateUserId'"
          ></span>
          <span
            style="background-color: #409eff"
            v-if="loginData.Field == 'AdminUserId'"
          ></span>
          <span
            style="background-color: green"
            v-if="loginData.Field == 'UserId'"
          ></span>
          <el-dropdown>
            <label for="">{{ loginData.Role }}:{{ loginData.FullName }}</label>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="signout">
                退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
      <el-row class="home_Children">
        <el-col class="page_button">
          <span @click="getPath(spanList[0])" :class="[(isActive == 1)?'changs':'change']">称重</span>
          <span @click="getPath(spanList[1])" :class="[(isActive == 2)?'changs':'change']">查询</span>
          <span @click="getPath(spanList[2])" :class="[(isActive == 3)?'changs':'change']">设置</span>
          <span @click="getPath(spanList[3])" :class="[(isActive == 4)?'changs':'change']" v-if="loginData.Field != 'UserId'">调试</span>
          <span @click="getPath(spanList[4])" :class="[(isActive == 5)?'changs':'change']" v-if="loginData.Field == 'CreateUserId'">管理</span>
          <!-- <template v-for="item in spanList" >
            <span :class="[(isActive == 3)?'change':'changs']"  @click="getPath(item)">{{item.name}}</span>
          </template> -->
          
        </el-col>

        <el-col class="page_Children">
          <router-view ref="Childmain" />
        </el-col>
      </el-row>
    </div>
    <el-dialog
        title="列表"
        :visible.sync="dialogVisible"
        width="70%"
        >
        <div style="height:500px;overflow-y: scroll;font-size:20px">
          <p v-for="item in this.$store.state.newList">
          {{item}}
        </p>
        </div>
        
    </el-dialog>
   
    <div
      style="width: 80px; height: 80px; position: fixed; bottom: 0;right:0;"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    ></div>

     <el-button type="primary" style="position: fixed; right:40px;bottom: 0;z-index:2000;" @click="dialogVisible = true" v-if="username  == 'admin' || username  == 'dpt' || username  == 'highsor' || username == 'haishuo'">推送列表</el-button>
  </div>
  
</template>

<script>
import { formatDate,formatDate2,ArrayFilter } from "@/common/common.js";
import $ from "jquery";
export default {
  name: "Device",
  components: {},
  data() {
    return {
      loading: false,
      tabPosition: "left",
      loginData: JSON.parse(localStorage.getItem("loginData")),
      menulist: [],
      active: "2",

      CompanySet: [],
      CompanyValue: "",
      ServiceSet: [],
      ServiceValue: "",
      UserSet: [],
      UserValue: "",
      ModelList: [],
      Modelvalue: "",
      DeviceList: [],
      currentPage: 1,
      pagesize: 20,
      Count: 0,
      DeviceValue: "",
      currentRow: "",
      UserImg: "",
      websock: null,
      spanList:[
        {
          name:'称重',
          path:'Weigh',
          index:1,
        },
        {
          name:'查询',
          path:'/Query',
          index:2,
        },
        {
          name:'设置',
          path:'/Setup',
          index:3,
        },
        {
          name:'调试',
          path:'/Debugging',
          index:4,
        },
        {
          name:'管理',
          path:'/Manage',
          index:5,
        },
      ],
      isActive:1,
      dialogVisible:false,
      newList:[],
      webScoketNum:1,
      IP:'',
      username:JSON.parse(localStorage.getItem('loginData')).UserName,
    };
  },
  created() {
    // this.initWebSocket()
    this.menulist = JSON.parse(localStorage.getItem("loginData")).leftmenus;
    // localStorage.setItem("currentModelRow", '');
    // this.getXT()
  },
  mounted() {
    
    if(this.$route.path == '/Weigh') {
      
      this.isActive = 1
    }else if(this.$route.path == '/Query') {
      this.isActive = 2
    }else if(this.$route.path == '/Setup') {
      this.isActive = 3
    }else if(this.$route.path == '/Debugging') {
      this.isActive = 4
    }else {
      this.isActive = 5
    }
    var data = JSON.parse(localStorage.getItem("loginData")).leftmenus;
    // 
    if (!JSON.parse(localStorage.getItem("loginData")).Url) {
      this.UserImg = require("@/assets/logo.png");
    } else {
      this.UserImg = "/api/main" + this.loginData.Url;
    }
    
      if (data.length > 0) {
        for (var i in data) {
          if (data[i].res_name == "客户") {
            this.getCompany(1);
            return false;
          } else if (data[i].res_name == "用户") {
            this.CompanyValue = JSON.parse(
              localStorage.getItem("loginData")
            ).OwnGuid;
            this.getUser(1);
          }
        }
      } else {
        this.getsu(1);
      }
  },
  methods: {
    
     
    getPath(val) {
      this.isActive = val.index
      this.$router.push({ path: val.path });
      if(val.index == 5) {
       localStorage.setItem(
        "defaultactiveHome",
        JSON.stringify("5")
      );
      }
    },
    // 选择设备
    handleCurrentChange(val) {
      this.currentRow = val;
      localStorage.setItem("currentRow", JSON.stringify(val));
      localStorage.setItem("currentModelRow", val.Model);
      this.resetSetItem('yearId',val.Model)
      if (val.Ponline != false) {
        this.getSBC();
        
      } else {
        this.$store.commit("DSBC", {});
      }
    },
    // 获取公司
    getCompany(val) {
      this.loading = true;
      this.$myaxios("post", "/api/main/common/GeneralDynamicList", {
        SearchRules: [
          {
            op: "like",
            field: "su.CreateUserId",
            value: JSON.parse(localStorage.getItem("loginData")).OwnGuid,
            type: "string",
          },
        ],
        moduleName: "System",
        table: "Table:System_GetAllUser",
        orderby: "",
      }).then((res) => {
        var data = JSON.parse(res.data.Data);
        var object = {
          OwnGuid: "aaaaaa",
          Company: "全部",
        };
        data.unshift(object);
        if (res.data.Code == 0) {
          this.CompanySet = data;
          if (val == 1) {
            if(localStorage.getItem("CompanyValue")) {
              this.CompanyValue = localStorage.getItem("CompanyValue")
            }else {
              if (data.length > 1) {
              this.CompanyValue = data[1].OwnGuid;
              } else {
                this.CompanyValue = data[0].OwnGuid;
              }
            }
            this.getUser(1);
          }
        } else {
           if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
              message: res.data.Message,
              type: "warning",
              duration: 1500,
            });
        }
        this.loading = false;
        this.warnNewing(res.data.Message)
      }).catch(() => {this.loading = false; });
    },

    // 获取用户
    getUser(val) {
      localStorage.setItem("CompanyValue", this.CompanyValue);
    
      this.UserSet = [];
      var setvalue = "";
      if (
        JSON.parse(localStorage.getItem("loginData")).Field == "CreateUserId"
      ) {
        setvalue = (this.CompanyValue == 'aaaaaa' ? '' : this.CompanyValue) ;
      } else {
        setvalue = JSON.parse(localStorage.getItem("loginData")).OwnGuid;
      }
      this.loading = true;
      this.$myaxios("post", "/api/main/common/GeneralDynamicList", {
        SearchRules: [
          {
            op: "like",
            field: "su.CreateUserId",
            value: setvalue,
            type: "string",
          },
        ],
        moduleName: "System",
        table: "Table:System_GetAllUser",
        orderby: "",
      }).then((res) => {
        var data = JSON.parse(res.data.Data);
        var array = [];
        data.forEach((item) => {
          if (item.field == "UserId") {
            array.push(item);
          }
        });
        this.tableData = array;
        var object = {
          OwnGuid: "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaa",
          FullName: "全部",
        };
        array.unshift(object);
        if (res.data.Code == 0) {
          this.UserSet = array;
          if (val == 1) {
            if(localStorage.getItem("UserValue")) {
              this.UserValue = localStorage.getItem("UserValue")
            }else {
              if (data.length > 1) {
              this.UserValue = array[1].OwnGuid;
            } else {
              this.UserValue = array[0].OwnGuid;
            }
            }
            this.getsu(1);
          }
        } else {
           if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
        }
        this.loading = false;
        this.warnNewing(res.data.Message)
      }).catch(() => {this.loading = false; });
    },
    // 型号
    getsu(val) {
      localStorage.setItem("UserValue", this.UserValue);
   
      this.loading = true;
      this.$myaxios("post", "/api/main/common/GeneralDynamicList", {
        moduleName: "System",
        table: "Table:System_GetAllRoles",
        orderby: "GroupNo",
        dynParmRules: [
          {
            op: "like",
            field: "@Kind",
            value: "设备类型",
            type: "string",
          },
        ],
      }).then((res) => {
        var data = JSON.parse(res.data.Data);
        var object = {
          Model: "全部",
        };
        data.unshift(object);
        if (res.data.Code == 0) {
          this.ModelList = ArrayFilter(data);
          if (val == 1) {
            if(localStorage.getItem("Modelvalue")) {
              this.Modelvalue = localStorage.getItem("Modelvalue")
            }else {
              this.Modelvalue = this.ModelList[0].Model;
            }
           
            this.getDevice(1);
          }
        } else {
           if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
        }
        this.loading = false;
        this.warnNewing(res.data.Message)
      }).catch(() => {this.loading = false; });
    },
    changeInput() {
      localStorage.setItem("DeviceValue", this.DeviceValue);
      if (this.DeviceValue == "") {
        this.getDevice();
      }
    },
    // 设备
    getDevice(val) {
      this.currentRow = {}
       localStorage.setItem("Modelvalue", this.Modelvalue);
      
      this.$store.commit("DSBC", {});
      this.loading = true;
      this.$myaxios("post", "/api/main/common/GeneralDynamicPageList", {
        moduleName: "System",
        table: "Table:System_GetAllDevices",
        orderby: "Ponline desc,ID",
        pageNumber: this.currentPage,
        rowsPerPage: this.pagesize,
        SearchRules: [
          {
            op: "like",
            field: "UserId",
            value:
              this.UserValue == ""
                ? JSON.parse(localStorage.getItem("loginData")).OwnGuid
                : this.UserValue == "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaa"
                ? ""
                : this.UserValue,
            type: "string",
          },
          {
            op: "like",
            field: "AdminUserId",
            value: this.CompanyValue == 'aaaaaa' ? '' : this.CompanyValue,
            type: "string",
          },
          {
            op: "equal",
            field: "Model",
            value: this.Modelvalue == "全部" ? "" : this.Modelvalue,
            type: "string",
          },
          {
            op: "like",
            field: "ID",
            value: this.DeviceValue,
            type: "string",
          },
        ],
      }).then((res) => {
        var data = JSON.parse(res.data.Data);
        if (res.data.Code == 0) {
          this.DeviceList = data;
          this.Count = res.data.Count;
          if (val == 1 && data.length > 0) {
            var lodata = JSON.parse(localStorage.getItem("currentRow"))
            console.log(lodata,'\\\\\\\\\\\\\\\\\\\\\\\\\\')
            for(var i=0;i< data.length;i++) {
               if (data[i].Ponline == true) {
                 if( lodata != '' &&  lodata != undefined &&  lodata != null) {
                    if(lodata.ID == data[i].ID) {
                      this.currentRow = lodata
                      this.$refs.multipleTable.setCurrentRow(data[i]);
                      localStorage.setItem("currentRow", JSON.stringify(data[i]));
                      localStorage.setItem("currentModelRow", data[i].Model);
                      break;
                    }
                  }else {
                    this.$refs.multipleTable.setCurrentRow(data[i]);
                    localStorage.setItem("currentRow", JSON.stringify(data[i]));
                     localStorage.setItem("currentModelRow", data[i].Model);
                    break;
                  }
                   
                }
                 this.$refs.Childmain.save(10080)
            }
             
           console.log(this.currentRow,'====================')
            console.log(this.$store.state.DSBC);
            this.$nextTick(() => {
              this.getDeviceValue();
            });
          }
        } else {
           if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
        }
        this.loading = false;
        this.warnNewing(res.data.Message)
      }).catch(() => {this.loading = false; });
    },

    getDeviceValue() {
      if (this.currentRow) {
        if (this.currentRow.Ponline != false) {
        localStorage.setItem("ID", this.DeviceValue);
        }
      }
    },
    // 系统常量
    getXT() {
      this.loading = true;
      this.$myaxios("post", "/api/main/common/GeneralDynamicList", {
        moduleName: "System",
        table: "Table:System_GetStaticValues",
        orderby: "",
      }).then((res) => {
        var data = JSON.parse(res.data.Data);
        if (res.data.Code == 0) {
          console.log(data,'99999999................')
          var BaudRates = [];
          var ComWays = [];
          var Divisions = [];
          var Printers = [];
          var Units = [];
          var ZeroSets = [];
          var ZeroTrace = [];
          for(var a = 0;a<JSON.parse(data[0].BaudRates).length ; a++) {
            BaudRates.push({
              name:JSON.stringify(a),
              value:JSON.parse(data[0].BaudRates)[a]
            })
          }
           console.log(BaudRates,'a.............................',)
          for(var b = 0;b < JSON.parse(data[0].ComWays).length ; b++) {
            ComWays.push({
              name:JSON.stringify(b),
              value:JSON.parse(data[0].ComWays)[b]
            })
          }
           console.log(ComWays,'b.............................')
          for(var c = 0;c< JSON.parse(data[0].Divisions).length ; c++) {
            Divisions.push({
              name:JSON.stringify(c),
              value:JSON.parse(data[0].Divisions)[c]
            })
          }
          console.log(ComWays,'c.............................')
          for(var d = 0;d < JSON.parse(data[0].Printers).length ; d++) {
            Printers.push({
              name:JSON.stringify(d),
              value:JSON.parse(data[0].Printers)[d]
            })
          }
          console.log(ComWays,'d.............................')
          for(var e = 0;e < JSON.parse(data[0].Units).length ; e++) {
            Units.push({
              name:JSON.stringify(e),
              value:JSON.parse(data[0].Units)[e]
            })
          }
          console.log(ComWays,'e.............................')
          for(var f = 0;f < JSON.parse(data[0].ZeroSets).length ; f++) {
            ZeroSets.push({
              name:JSON.stringify(f),
              value:JSON.parse(data[0].ZeroSets)[f]
            })
          }
          console.log(ComWays,'f.............................')
          for(var g = 0;g < JSON.parse(data[0].ZeroTrace).length ; g++) {
            ZeroTrace.push({
              name:JSON.stringify(g),
              value:JSON.parse(data[0].ZeroTrace)[g]
            })
          }
          console.log(ComWays,'g.............................')
          this.$store.commit("BackPapper", data[0].BackPapper);
          this.$store.commit("PrtUnion", data[0].PrtUnion);
          this.$store.commit("ToZeroSet", data[0].ToZeroSet);
          this.$store.commit("GoPapper", data[0].GoPapper);
          this.$store.commit("PointNum", data[0].PointNum);
          this.$store.commit("BaudRates", BaudRates);
          this.$store.commit("ComWays", ComWays);
          this.$store.commit("Divisions",Divisions);
          this.$store.commit("Printers", Printers);
          this.$store.commit("Units", Units);
          this.$store.commit("ZeroSets", ZeroSets);
          this.$store.commit("ZeroTrace", ZeroTrace);
         
          // this.ModelList = data;
        } else {
           if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
        }
        this.loading = false;
        this.warnNewing(res.data.Message)
      }).catch(() => {this.loading = false; });
    },
    getSBC() {
      this.loading = true;
      this.$myaxios("post", "/api/main/common/GeneralDynamicList", {
        moduleName: "System",
        table: "Table:System_GetDeviceProfile",
        orderby: "",
        dynParmRules: [
          {
            field: "@ID",
            value: this.currentRow.ID,
            type: "string",
          },
        ],
      }).then((res) => {
        var data = JSON.parse(res.data.Data);
        var dateTime = new Date();
        if (res.data.Code == 0) {
          if (data[0].P1304 == "") {
            data[0].P1304 = formatDate(dateTime);
          }
          this.$store.commit("DSBC", data[0]);
         
          console.log(this.$store.state.DSBC, "8888888888888");
        } else {
           if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
          
        }
        this.loading = false;
        this.warnNewing(res.data.Message)
      }).catch(() => {this.loading = false; });
    },
    warnNewing(value) {
      var NewList = this.$store.state.newList
      var dateTime = new Date();
      var newS = "时间：" + formatDate2(dateTime) + ' 请求信息' + value
     NewList.unshift(newS)
      this.$store.commit("newList",NewList);
    },
    getBack() {
       this.$message({
        message: '该账户已在其他地方登录，请重新登录',
        type: "warning",
        duration: 1500,
      });
      this.$router.push({ path: "/" });
      localStorage.clear();
    },
    // 选择每页多少条
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesize = val;
      this.getDevice();
    },
    // 选择第几页
    handleCurrents(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getDevice();
    },
    goHome() {
      this.$router.push({ path: "/Material" });
      localStorage.setItem(
        "defaultactiveHome",
        JSON.stringify("1")
      );
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    signout() {
      this.$router.push({ path: "/" });
      localStorage.clear();
    },
  },
  computed: {},
  beforeRouteLeave(to, form, next) {
    console.log('定时器已销毁')
    next();
  },
  beforeDestroy() {
    this.$store.commit("DSBC", {});
    localStorage.setItem("CompanyValue", this.CompanyValue);
    localStorage.setItem("UserValue", this.UserValue);
    localStorage.setItem("Modelvalue", this.Modelvalue);
    localStorage.setItem("DeviceValue", this.DeviceValue);
    console.log('定时器已销毁')
  },
  watch: {
    $route(to, form) {
      console.log(form.path, "form");
      console.log(to.path, "to");
    },
  },
};
</script>
<style lang="scss" scoped>
.wraps {
  font-size: 20px;
  .boxleft {
    box-sizing: border-box;
    display: inline-block;
    width: 308px;
    height: 100%;
    vertical-align: top;
    border-right: 1px solid #dddddd;
  }
  .boxright {
    display: inline-block;
    width: calc(100% - 308px);
    height: 100%;
    vertical-align: top;
  }

  .home_Title {
    height: 120px;
    line-height: 120px;
    background-color: #ffffff;
    vertical-align: top;
    color: #333333;
    border-bottom: 1px solid #dddddd;
    .home_topright {
      box-sizing: border-box;
      padding-right: 20px;
      text-align: right;
      span {
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 60%;

        vertical-align: top;
        margin: 45px 40px;
      }
      img,
      label {
        vertical-align: top;
        font-size: 24px;
      }
    }
  }

  .home_Children,
  .home_menu {
    width: 100%;
    height: calc(100% - 120px);
  }

  .home_menu {
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 28px 42px 0;
    background-color: #fff;
    // position: relative;
    ::v-deep .el-table td.el-table__cell {
      border-bottom: none !important;
      padding: 0;
    }
    ::v-deep .el-table thead {
      display: none;
    }
    .tablepage {
      position: absolute;
      bottom: 5px;
    }
  }
  .home_logo {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .page_button {
    text-align: center;
    height: 115px;
    padding: 20px 0;

    span {
      display: inline-block;
      width: 150px;
      height: 70px;
      line-height: 70px;
      display: inline-block;
      border: 1px solid #333333;
      margin: 0 125px 0 0;
      border-radius: 40px;
      background-color: #fff;
    }
    .change {
            background-color: #fff;
            color:  #000000;
    }
        
    .changs {
            border-color: #b3d8ff;
            background-color: #ecf5ff;
    }
  }
  
  .page_Children {
    height: calc(100% - 115px);
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 40px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  ::v-deep .el-select {
    width: 100%;
  }
  img {
    width: 120px;
    height: 120px;
    vertical-align: top;
  }
  .el-dropdown {
    vertical-align: top;
    height: 120px;
  }
  .el-input,
  ::v-deep .el-select .el-input__inner {
    font-size: 18px;
    color: #333333;
  }
  ::v-deep .el-loading-spinner {
    margin-top: -35px;
  }
   ::v-deep .el-table--border::after, .el-table--group::after, .el-table::before {
    background-color: #fff;
    
   }
   ::v-deep .eloption .el-select-dropdown__wrap {
    height: 460px;
    max-height: 460px;
    padding: 0 0 15px 0;
    overflow-y: scroll !important;
  }
}
</style>