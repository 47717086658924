<template>
  <div class="service wrap">
    <el-row class="userButton">
      <el-col :span="6">售后管理</el-col>
      <el-col :span="18" style="text-align: right;">
        <el-button type="primary" @click="addUser">新增</el-button>
        <el-button type="danger" @click="deleteUser">删除</el-button>
      </el-col>
    </el-row>
    <el-row class="userList">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        height="100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="序号" type="index" width="70"></el-table-column>
        <el-table-column prop="UserName" label="用户ID"></el-table-column>
        <el-table-column prop="FullName" label="用户名"> </el-table-column>
        <!-- <el-table-column prop="Password" label="密码"> </el-table-column> -->
        <el-table-column prop="Phone" label="手机号"> </el-table-column>
        <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button size="mini" type="goon" @click="openDetail(scope.$index, scope.row)">详情</el-button>
              <el-button size="mini" type="primary" @click="modifyUser(scope.$index, scope.row)">修改</el-button>
            </template>
          </el-table-column>
        <!-- <el-table-column prop="Company" label="公司"> </el-table-column>
        <el-table-column prop="Addr" label="地址"> </el-table-column> -->
      </el-table>
    </el-row>
    <el-row class="pageSizes">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        background
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination>
    </el-row>
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      @close="closed"
    >
      <el-form
        :model="form"
        status-icon
        :rules="rules"
        label-width="0"
        :ref="'form'"
        class="dialogbox"
      >
        <el-col>
          <el-form-item prop="UserName">
            <el-input
              placeholder="请输入账号"
              :disabled="disabled"
              v-model="form.UserName"
            >
              <template slot="prepend"
                >账号<span class="impred">*</span></template
              >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="FullName">
            <el-input
              placeholder="请输入用户名"
              :disabled="disabled"
              v-model="form.FullName"
            >
              <template slot="prepend"
                >用户名<span class="impred">*</span></template
              >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col >
          <el-form-item prop="Password">
            <el-input placeholder="请输入密码"  :disabled="disabled"  v-model="form.Password">
              <template slot="prepend"
                >密码<span class="impred">*</span></template
              >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="Phone">
            <el-input placeholder="请输入手机号"  :disabled="disabled"  v-model="form.Phone">
              <template slot="prepend"
                >手机号<span class="impred">*</span></template
              >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="Company">
            <el-input placeholder="请输入公司"  :disabled="disabled"  v-model="form.Company">
              <template slot="prepend"
                >公司<span class="impred">*</span></template
              >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="Addr">
            <el-input placeholder="请输入地址"  :disabled="disabled"  v-model="form.Addr">
              <template slot="prepend"
                >地址<span class="impred">*</span></template
              >
            </el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closed">取 消</el-button>
        <el-button type="primary" @click="save" v-if="boxnum !=0">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { dyParameter,formatDate2 } from "@/common/common.js";
export default {
  name: "Service",
  props: {
    msg: String,
  },
  data() {
    return {
      tableData: [],
      selectionList: [],
      currentPage: 1,
      pagesize: 10,
      title: "新增",
      centerDialogVisible: false,
      form: {
        OwnGuid: "",
        FullName: "",
        UserName: "",
        Password: "",
        Phone: "",
        Company: "",
        Addr: "",
        AdminUserId:JSON.parse(localStorage.getItem('loginData')).OwnGuid
      },
      rules: {
        UserName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        Password: [
          {
            required: true,
            message: "请选择密码",
            trigger: ["blur", "change"],
          },
        ],
      },
      disabled:false,
      boxnum:1,
      
    };
  },
  created() {},
  mounted() {
    this.getUser();
  },
  methods: {
    // 获取用户列表
    getUser() {
      this.$myaxios("post", "/api/main/common/GeneralDynamicPageList", {
        SearchRules: [
          {
            op: "like",
              field: "CreateUserId",
              value: JSON.parse(localStorage.getItem('loginData')).OwnGuid,
              type: 'string',
            }
        ],
        moduleName: "System",
        table: "Table:System_GetAllUser",
        orderby: "UserName",
        pageNumber: this.currentPage,
        rowsPerPage: this.pagesize,
      }).then((res) => {
        var data = JSON.parse(res.data.Data);
        if (res.data.Code == 0) {
          this.tableData = data;
        } else {
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
        }
      });
    },

    // 多选
    handleSelectionChange(val) {
      this.selectionList = val;
    },
    // 新增
    addUser() {
      this.title = "新增";
      this.boxnum = 1
        this.disabled = false
      this.centerDialogVisible = true;
    },
    // 修改
    modifyUser(index, row) {
        this.title = "编辑";
        this.boxnum = 1
        this.form.UserName = row.UserName;
        this.form.Password = row.Password;
        this.form.Phone = row.Phone;
        this.form.Company = row.Company;
        this.form.Addr = row.Addr;
        this.form.OwnGuid = row.OwnGuid;
        this.form.FullName = row.FullName;
        this.disabled = false
        this.centerDialogVisible = true;
      
    },
    // 详情
    openDetail(index, row) {
        this.title = "详情";
        this.form.UserName = row.UserName;
        this.form.Password = row.Password;
        this.form.Phone = row.Phone;
        this.form.Company = row.Company;
        this.form.Addr = row.Addr;
        this.form.OwnGuid = row.OwnGuid;
        this.form.FullName = row.FullName;
        this.disabled = true
        this.boxnum = 0
        this.centerDialogVisible = true;
      
    },
    // 新增、修改保存
    save() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let list = [
            "UserName",
            "FullName",
            "Password",
            "Phone",
            "Company",
            "Addr",
            "OwnGuid",
            "AdminUserId"
          ];

          let dynParmRules = dyParameter(this.form, list);
          this.$myaxios(
            "post",
            "/api/main/common/ExecuteSqlTable",
            {
              table: "Table:System_SetUser",
              moduleName: "System",
              dynParmRules: dynParmRules,
            }
            // "formdata"
          ).then((res) => {
            if (res.data.Code == 0) {
              this.$message({
                message: "保存成功！",
                type: "success",
                duration: 1500,
              });
              this.getUser();
              this.closed();
            } else {
              this.$message({
                message: res.data.Message,
                type: "warning",
                duration: 1500,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 删除
    deleteUser() {
      console.log(this.selectionList, "8888888888888");
      if (this.selectionList.length <= 0) {
        this.$message({
          message: "请选择要删除的数据！",
          type: "warning",
          duration: 1500,
        });
      } else {
        var IDLIST = "";
        for (var i = 0; i < this.selectionList.length; i++) {
          IDLIST += this.selectionList[i].OwnGuid + ",";
        }
        console.log(IDLIST.slice(0, IDLIST.length - 1), "999999999999", IDLIST);
        this.$myaxios(
          "post",
          "/api/main/common/ExecuteSqlTable",
          {
            table: "Table:System_DelUsers",
            moduleName: "System",
            dynParmRules: [
              {
                field: 'idlist',
                value: IDLIST.slice(0, IDLIST.length - 1),
                type: 'string'
              },
            ]
          },
        ).then((res) => {
          if (res.data.Code == 0) {
            this.getUser()
            this.$message({
              message: "删除成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "删除失败",
              type: "warning",
            });
          }
        }).catch(() => {
            this.$message({
              type: "warning",
              message: this.$glg("删除失败"),
            });
          });
      }
    },
    closed() {
      this.centerDialogVisible = false;
      this.form.UserName = "";
      this.form.Password = "";
      this.form.Phone = "";
      this.form.Company = "";
      this.form.Addr = "";
      this.form.OwnGuid = "";
      this.form.FullName = "";
    },
    // 选择每页多少条
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesize = val;
      this.getUser();
    },
    // 选择第几页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getUser();
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrap {
  // padding: 40px;
  position: relative;
  width: 100%;
  .userList {
    height: calc(100% - 110px);
  }
  .pageSizes {
    height: 35px;
    text-align: center;
     z-index: 1200;
  }
  ::v-deep .el-loading-spinner {
    margin-top: -35px;
  }
  .el-input,::v-deep .el-select .el-input__inner {
    font-size: 18px;
    color: #333333;
  }
}
</style>
