<template>
  <div class="index">
    这里是首页
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    msg: String
  },
  data() {
    return {
     
    };
  },
  methods: {
   
  },
  computed: {},
  created() {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
