<template>
  <div class="query wrap">
    <el-row class="weigh_box">
      <el-col :span="6" class="box_left">
        <el-menu
          :default-active="defaultactive"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
          <el-menu-item index="1">
            <span slot="title">信息</span>
            <img  src="@/assets/iconRightMi.png"  alt="" v-if="boxdialogVisible == 1" style="float:right;margin:23px 0"  />
            <img  src="@/assets/iconRightAn.png"  alt="" v-else  style="float:right;margin:23px 0" />
            <!-- <i class="el-icon-right" style="float:right;"></i> -->
          </el-menu-item>
          <!-- <el-menu-item index="2">
            <span slot="title">记录</span>
          </el-menu-item> -->
        </el-menu>
      </el-col>
      <el-col :span="18" class="box-right" v-if="boxdialogVisible == 1">
        <div class="topbox"></div>
        <div class="bottombox">
          <el-tabs type="border-card" v-model="active" @tab-click="handleClick">
            <el-tab-pane label="基本" name="one">
              <el-row class="weigh_text">
                <el-col :span="8" class="weigh_col">
                  <label for="">系列</label>
                  <span>{{ this.$store.state.DSBC.P2003 }}</span>
                  <!-- <el-input
                    v-model="this.$store.state.DSBC.P2003" disabled
                  >
                  </el-input> -->
                </el-col>
                <el-col :span="8" class="weigh_col">
                  <label for="">型号</label>
                  <span>{{ this.$store.state.DSBC.P2004 }}</span>
                  <!-- <el-input
                    v-model="this.$store.state.DSBC.P2004" disabled
                  >
                  </el-input> -->
                </el-col>
                <el-col :span="8" class="weigh_col">
                  <label for="">ID</label>
                  <span>{{ this.$store.state.DSBC.P2005 }}</span>
                  <!-- <el-input
                    v-model="this.$store.state.DSBC.P2005" disabled
                  >
                  </el-input> -->
                </el-col>
                <el-col :span="8" class="weigh_col marbox">
                  <label for="">编码</label>
                  <span>{{ this.$store.state.DSBC.P2008 }}</span>
                  <!-- <el-input
                    v-model="this.$store.state.DSBC.P2008" disabled
                  >
                  </el-input> -->
                </el-col>
                <el-col :span="8" class="weigh_col marbox">
                  <label for="">生产日期</label>
                  <span>{{ this.$store.state.DSBC.P2009 }}</span>
                  <!-- <el-input
                    v-model="this.$store.state.DSBC.P2009" disabled
                  >
                  </el-input> -->
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="软件" name="two">
              <el-row class="weigh_text">
                <el-col :span="8" class="weigh_col">
                  <label for="">数值</label>
                  <span>{{ this.$store.state.DSBC.P2006 }}</span>
                  <!-- <el-input
                    v-model="this.$store.state.DSBC.P2006" disabled
                  >
                  </el-input> -->
                </el-col>
                <el-col :span="8" class="weigh_col ">
                  <label for="">日期</label>
                  <span>{{ this.$store.state.DSBC.P2007 }}</span>
                  <!-- <el-input
                    v-model="this.$store.state.DSBC.P2007" disabled
                  >
                  </el-input> -->
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="电池" name="three">
              <el-row class="weigh_text">
                <el-col :span="8" class="weigh_col">
                  <label for="">电压</label>
                  <span>{{ this.$store.state.DSBC.P1031 }}</span>
                  <!-- <el-input
                    v-model="this.$store.state.DSBC.P2003" disabled
                  >
                  </el-input> -->
                </el-col>
                <el-col :span="8" class="weigh_col">
                  <label for="">电量</label>
                  <span>{{ this.$store.state.DSBC.P1032 }}</span>
                  <!-- <el-input
                    v-model="this.$store.state.DSBC.P2004" disabled
                  >
                  </el-input> -->
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <!-- <el-col :span="18" class="box-right" v-else-if="boxdialogVisible == 2">
        <div class="topbox"></div>
        <div class="bottombox">
          <el-tabs
            type="border-card"
            v-model="activeJL"
            @tab-click="handleClick"
          >
            <el-tab-pane label="称重" name="activeJLA"> </el-tab-pane>
            <el-tab-pane label="皮重" name="activeJLB"> </el-tab-pane>
            <el-tab-pane label="超载" name="activeJLC"> </el-tab-pane>
          </el-tabs>
        </div>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
import { formatDate,formatDate2 } from "@/common/common.js";
export default {
  name: "Query",
  props: {
    msg: String,
  },
  data() {
    return {
      form: {},
      active: "one",
      defaultactive: "1",
      activeJL: "activeJLA",
      boxdialogVisible: 1,
    };
  },
  created() {},
  mounted() {
    if (localStorage.getItem("defaultactiveQuery") != undefined) {
      this.defaultactive =  JSON.parse(localStorage.getItem("defaultactiveQuery"))
      this.boxdialogVisible = JSON.parse(localStorage.getItem("defaultactiveQuery"))
      if (JSON.parse(localStorage.getItem("defaultactiveQuery")) == 1) {
         if (localStorage.getItem("tabNameQuery") != undefined) {
          this.active = JSON.parse(localStorage.getItem("tabNameQuery"));
        }
      }else if(JSON.parse(localStorage.getItem("defaultactiveQuery")) == 2) {
         if (localStorage.getItem("tabNameQuery") != undefined) {
          this.activeJL = JSON.parse(localStorage.getItem("tabNameQuery"));
        }
      }
    }
   
  },
  methods: {
    warnNewing(value) {
      var NewList = this.$store.state.newList
      var dateTime = new Date();
      var newS = "时间：" + formatDate2(dateTime) + ' 请求信息' + value
      NewList.unshift(newS)
      this.$store.commit("newList",NewList);
    },
    // 菜单点击
    handleSelect(key, keyPath) {
      this.boxdialogVisible = key;
      localStorage.setItem(
        "defaultactiveQuery",
        JSON.stringify(this.boxdialogVisible)
      );
      localStorage.removeItem("tabNameQuery");
    },
    handleClick(tab, event) {
      localStorage.setItem("tabNameQuery", JSON.stringify(tab.name));
    },
    // 保存参数
    save(val) {
      console.log(val);
      var dynParmRules = [];
       if (val == 110 || val == 120 || val == 123 || val == 124) {
        dynParmRules = [
          {
            field: "P1007",
            value: val,
            type: "int",
          },
        ];
      }

      this.$myaxios(
        "post",
        "/api/main/common/ExecuteSqlTable",
        {
          table: this.$store.state.DSBC.ID ? this.$store.state.DSBC.ID : JSON.parse(localStorage.getItem("currentRow")).ID,
          moduleName: "DownloadPara",
          dynParmRules: dynParmRules,
        }
        // "formdata"
      ).then((res) => {
        if (res.data.Code == 0) {
          if(JSON.parse(localStorage.getItem("loginData")).UserName  == 'admin' || JSON.parse(localStorage.getItem("loginData")).UserName  == 'dpt' || JSON.parse(localStorage.getItem("loginData")).UserName  == 'highsor' || JSON.parse(localStorage.getItem("loginData")).UserName == 'haishuo') {
            this.$message({
            message: "保存成功！",
            type: "success",
            duration: 1500,
          });
          }
        } else {
          if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
            offset: 800,
          });
        }
         this.warnNewing(res.data.Message)
      });
    },
    getBack() {
       this.$message({
        message: '该账户已在其他地方登录，请重新登录',
        type: "warning",
        duration: 1500,
      });
      this.$router.push({ path: "/" });
      localStorage.clear();
    }
  },
  computed: {},
  watch:{
    active(newName, oldName) {
      if (this.active == "three") {
        // this.save(110);
      } else if (this.active == "two") {
      } 
    },
    activeJL(newName, oldName) {
      if (this.activeJL == "activeJLA") {
        this.save(123);
      } else if (this.activeJL == "activeJLB") {
        this.save(124);
      } else if (this.activeJL == "activeJLC") {
        this.save(120);
      }
    },
    boxdialogVisible() {
      if (this.boxdialogVisible == 1) {
        if (this.active == "three") {
                // this.save(110);
              } else if (this.active == "two") {
              } 
      } else if (this.boxdialogVisible == 2) {
        if (this.activeJL == "activeJLA") {
          this.save(123);
        } else if (this.activeJL == "activeJLB") {
          this.save(124);
        } else if (this.activeJL == "activeJLC") {
          this.save(120);
        }
      }
    },
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrap {
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  box-sizing: border-box;
  background-color: #f4f4f4;
  font-size: 20px;
  overflow-y: scroll;
  .weigh_box {
    height: 100%;
    background-color: #fff;

    .box_left {
      height: 100%;
      overflow-y: scroll;
      padding: 0 48px !important;
      border-right: 1px solid #dddddd;
    }
    .box-right {
      height: 100%;
      overflow-y: scroll;
      .topbox {
        height: 59px;
        border-bottom: 1px solid #dddddd;
      }
      .bottombox {
        box-sizing: border-box;
        padding: 40px;
      }
    }
    .weigh_title {
      border-bottom: 1px solid #dddddd;
      padding: 1.5rem 2rem;
    }

    .weigh_text {
      padding: 1rem 2rem;
      vertical-align: bottom;
      p {
        margin: 0 0 10px 0;
      }
      label {
        display: inline-block;
        width: 120px;
        height: 40px;
        line-height: 40px;
        vertical-align: top;
      }
      span {
        vertical-align: top;
        display: inline-block;
        width: 220px;
        height: 40px;
        padding: 0 15px;
        line-height: 40px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        box-sizing: border-box;
        // margin-top: 10px;
        background-color: #f5f5f5;
      }
    }
    .marbox {
      margin: 10px 0;
    }
  }

  .el-select,
  .el-input {
    width: 200px;
  }
  .el-tabs--border-card {
    box-shadow: none;
  }
  .el-menu-item {
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    border-bottom: 1px solid #dddddd;
  }
  ::v-deep .el-input .is-disabled .el-input__inner {
    color: #333333 !important;
  }
  ::v-deep .el-tabs__item {
    width: 136px;
    text-align: center;
    font-size: 20px;
    height: 48px;
    line-height: 48px;
  }
}
</style>
