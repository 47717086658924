import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./common/common.css"
import "./common/rem.js"
Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import AFTableColumn from 'af-table-column'
Vue.use(AFTableColumn)
//引入axios
import axios from 'axios'
Vue.prototype.$axios = axios
// 引用封装axios
import { myaxios }  from './common/MyAxios.js'

Vue.prototype.$myaxios = myaxios

Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'yearId') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent')
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val)
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null)
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal)
  }
}


// 1. 加载本地 config.json
let loadLocal_A = () => {
  return new Promise((resolve, reject) => {
     axios.get('config.json').then((res) => {
          console.log('config', res)
          // 基础地址
          Vue.prototype.websockUrl = res.data.websockUrl
          resolve()
      })
  })
}
// 3. 本地文件加载完成，开始构建
let build_Vue = async () => {
  await (loadLocal_A())
  window.Sorting_Pad_Vue = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

build_Vue()
// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  document.title = '宁波海硕传感器制造有限公司'
  // to 将要访问的路径  from 从哪个路径跳转过来  next 一个函数，表示放行
  if (to.path === '/') return next()
  // 获取token
  const tokenStr = localStorage.getItem('Token')
  // console.log(JSON.parse(tokenStr))
  if (!tokenStr) return next('/')
  
  next()
})
