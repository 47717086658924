<template>
  <div class="material wrap">
    <el-row class="userButton">
      <el-col :span="6">个人资料</el-col>
    </el-row>
    <el-row class="material_Text">
      <el-col :span="8">
        <label for="">用户名</label><br>
        <el-input v-model="form.FullName" placeholder="请输入用户名"></el-input>
      </el-col>
      <el-col :span="8">
        <label for="">手机号</label><br>
        <el-input v-model="form.Phone" placeholder="请输入手机号"></el-input>
      </el-col>
      <el-col :span="8">
        <label for="">公司名称</label><br>
        <el-input
          v-model="form.Company"
          placeholder="请输入公司名称"
        ></el-input>
      </el-col>
      <el-col :span="8" style="margin-top:20px;">
        <label for="">地址</label><br>
        <el-input v-model="form.Addr" placeholder="请输入地址"></el-input>
      </el-col>
      <!-- <el-col :span="8">
        <label for="">联系方式</label>
        <el-input v-model="form.Phone" placeholder="请输入账号"></el-input>
      </el-col> -->
      <!-- <el-col :span="8">
        <label for="">密码</label>
        <el-input v-model="form.Password" placeholder="请输入账号"></el-input>
      </el-col> -->
      <el-col :span="16" style="margin-top:69px;text-align:right;">
        <el-button type="primary" @click="getuser(1)" style="margin-right:178px;width:96px;">确认</el-button>
      </el-col>
    </el-row>
    <el-row class="weigh_box">
      <el-col class="weigh_title">密码修改</el-col>
      <el-col class="weigh_text">
        <el-row>
          <el-col :span="8">
            <label for="">原密码</label><br>
            <el-input v-model="passOld" placeholder="请输入账号"></el-input>
          </el-col>
          <el-col :span="8" >
            <label for="">新密码</label><br>
            <el-input v-model="passNew1" placeholder="请输入账号"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" style="margin-top:20px;">
            <label for="">确认密码</label><br>
            <el-input v-model="passNew2" placeholder="请输入账号"></el-input>
          </el-col>
          <el-col :span="16" style="margin-top:69px;text-align:right;">
            <el-button type="primary" @click="getuser(2)" style="margin-right:178px;width:96px">确认</el-button>
            <!-- <el-button type="primary" @click="getUpload">上传</el-button> -->
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- <el-row class="material_Possword">
      <el-row class="material_title"> 密码修改 </el-row>
      <el-row class="material_box">
        <el-row>
          <el-col :span="8">
            <label for="">原密码</label>
            <el-input v-model="input" placeholder="请输入账号"></el-input>
          </el-col>
          <el-col :span="8">
            <label for="">新密码</label>
            <el-input v-model="input" placeholder="请输入账号"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <label for="">确认密码</label>
            <el-input v-model="input" placeholder="请输入账号"></el-input>
          </el-col>
          <el-col :span="8">
            <el-button type="primary">确认</el-button>
          </el-col>
        </el-row>
      </el-row>
    </el-row> -->
    <div style="width:80px;height:80px; position: absolute; bottom: 0;right:0;" v-loading="loading"  element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.8)"></div>
  </div>
</template>
<script>
import { dyParameter,formatDate2 } from "@/common/common.js";
export default {
  name: "Material",
  props: {
    msg: String,
  },
  data() {
    return {
      loading:false,
      centerDialogVisible: false,
      headToken: {
        token: JSON.parse(localStorage.getItem("Token")),
      },
      input: "",
      logindata: [],
      form: {
        UserName: "",
        Company: "",
        Phone: "",
        Addr: "",
        Password: "",
        OwnGuid: "",
        FullName: "",
        AdminUserId: JSON.parse(localStorage.getItem("loginData")).OwnGuid,
        File_Guid: "",
      },
      passOld: "",
      passNew1: "",
      passNew2: "",
    };
  },
  created() {},
  mounted() {
    this.getMaterial();
  },
  methods: {
    getUpload() {
      this.centerDialogVisible = true;
    },

    // 获取用户列表
    getMaterial() {
      this.loading = true
      this.$myaxios("post", "/api/main/common/GeneralDynamicPageList", {
        SearchRules: [
          {
            op: "like",
            field: "UserName",
            value: JSON.parse(localStorage.getItem("loginData")).UserName,
            type: "string",
          },
          {
            op: "like",
            field: "field",
            value: JSON.parse(localStorage.getItem("loginData")).Field,
            type: "string",
          },
          {
            op: "like",
            field: "OwnGuid",
            value: JSON.parse(localStorage.getItem("loginData")).OwnGuid,
            type: "string",
          },
        ],
        moduleName: "System",
        table: "Table:System_GetAllUser",
        orderby: "UserName",
        pageNumber: 1,
        rowsPerPage: 10,
      }).then((res) => {
        var data = JSON.parse(res.data.Data);
        console.log(data, "11111111111");
        if (res.data.Code == 0) {
          this.form.OwnGuid = data[0].OwnGuid;
          this.form.UserName = data[0].UserName;
          this.form.Company = data[0].Company;
          this.form.Phone = data[0].Phone;
          this.form.Addr = data[0].Addr;
          this.form.Password = data[0].Password;
          this.form.FullName = data[0].FullName;
          this.form.File_Guid = data[0].File_Guid;
        } else {
           if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
        }
        this.loading = false
         this.warnNewing(res.data.Message)
      }).catch(() => {this.loading = false; });
    },
getBack() {
       this.$message({
        message: '该账户已在其他地方登录，请重新登录',
        type: "warning",
        duration: 1500,
      });
      this.$router.push({ path: "/" });
      localStorage.clear();
    },
    getuser(val) {
      if (val == 2) {
        if (this.passOld != this.form.Password) {
          this.$message({
            message: "原密码错误！",
            type: "warning",
            duration: 1500,
          });
        } else {
          if (this.passNew1 != this.passNew2) {
            this.$message({
              message: "两次输入密码不相同！",
              type: "warning",
              duration: 1500,
            });
          } else {
            this.form.Password = this.passNew1;
            this.save();
          }
        }
      } else {
        this.save();
      }
    },

    save() {
      let list = [
        "UserName",
        "FullName",
        "Password",
        "Phone",
        "Company",
        "Addr",
        "OwnGuid",
        "File_Guid",
        "AdminUserId",
      ];
this.loading = true
      let dynParmRules = dyParameter(this.form, list);
      this.$myaxios("post", "/api/main/common/ExecuteSqlTable", {
        table: "Table:System_SetUser",
        moduleName: "System",
        dynParmRules: dynParmRules,
      }).then((res) => {
        if (res.data.Code == 0) {
          this.$message({
            message: "保存成功！",
            type: "success",
            duration: 1500,
          });

          this.passOld = "";
          this.passNew1 = "";
          this.passNew2 = "";
        } else {
           if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
        }
        this.loading = false
         this.warnNewing(res.data.Message)
      }).catch(() => {this.loading = false; });
    },
     warnNewing(value) {
      var NewList = this.$store.state.newList
      var dateTime = new Date();
      var newS = "时间：" + formatDate2(dateTime) + ' 请求信息' + value
     NewList.unshift(newS)
      this.$store.commit("newList",NewList);
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrap {
  // padding: 40px;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  .material_Text {
    height: 262px;
    box-sizing: border-box;
    padding: 40px 46px 0 ;
    background-color: #fff;
    font-size: 2.4rem;
  }

  .material_Possword {
    margin-top: 15px;
    background-color: #fff;
    .material_title {
      height: 60px;
      line-height: 60px;
      box-sizing: border-box;
      padding: 0 1rem;
      border-bottom: 1px solid#F4F4F4;
    }
    .material_box {
      box-sizing: border-box;
      padding: 1.5rem 1rem;
    }
  }
  .el-col-8 {
    box-sizing: border-box;
    .el-input {
      width: 280px;
      margin: 18px 0 0 0;
    }
    .el-button--primary {
      margin: 45px 0 0 0;
    }
  }
  .weigh_box {
    height: 334px;
    margin-top: 24px;
    background-color: #fff;
    .weigh_title {
      height: 64px;
      font-size: 20px;
      line-height: 64px;
      border-bottom: 8px solid #f5f5f5;
      padding: 0 46px 0;
    }

    .weigh_text {
      padding: 30px  46px 0;
      vertical-align: bottom;
      label {
        display: inline-block;
        width: 12rem;
      }
    }
  }
  .el-input,::v-deep .el-select .el-input__inner {
    font-size: 18px;
    color: #333333;
  }
  ::v-deep .el-loading-spinner {
    margin-top: -35px;
  }
}
</style>