<template>
  <div class="product wrap">
    
     <el-row class="productButton userButton">
      <el-col :span="6" style="text-align:left;">产品管理</el-col>
      <el-col :span="18" style="text-align: right;">
        <el-button type="primary" @click="addUser">新增</el-button>
      <!-- <el-button @click="modifyUser">编辑</el-button> -->
      <el-button type="danger" @click="deleteUser"
        >删除</el-button
      >
      </el-col>
    </el-row>
    <el-row class="productList">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        height="100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="序号" type="index" width="70"></el-table-column>
        <el-table-column prop="GroupNo" label="系列"></el-table-column>
        <el-table-column prop="Model" label="型号"> </el-table-column>
        <el-table-column prop="Role" label="名称"> </el-table-column>
        <el-table-column prop="UpdateDate" label="发布时间" :formatter="timeFormat"> </el-table-column>
        <el-table-column prop="Remark" label="特性"> </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="goon"
              @click="openDetail(scope.$index, scope.row)"
              >详情</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="modifyUser(scope.$index, scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pageSizes">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        background
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="Count"
      >
      </el-pagination>
    </el-row>
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
     <el-form :model="form" status-icon :rules="rules" label-width="0" :ref="'form'" class="dialogbox">
        <el-col>
          <el-form-item prop="Level">
              <el-input placeholder="请输入序号" v-model="form.Level" :disabled="disabled">
                <template slot="prepend">序号<span class="impred">*</span></template>
              </el-input>
            </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="GroupNo">
              <el-input placeholder="请输入系列" v-model="form.GroupNo" :disabled="disabled">
                <template slot="prepend">系列<span class="impred">*</span></template>
              </el-input>
            </el-form-item>
        </el-col>
        <el-col >
          <el-form-item prop="Model">
              <el-input placeholder="请输入型号" v-model="form.Model" :disabled="disabled">
                <template slot="prepend">型号<span class="impred">*</span></template>
              </el-input>
            </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="Role">
              <el-input placeholder="请输入名称" v-model="form.Role" :disabled="disabled">
                <template slot="prepend">名称<span class="impred">*</span></template>
              </el-input>
            </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="Remark">
              <el-input placeholder="请输入特性" v-model="form.Remark" :disabled="disabled">
                <template slot="prepend">特性<span class="impred">*</span></template>
              </el-input>
            </el-form-item>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closed">取 消</el-button>
        <el-button type="primary" @click="save"  v-if="boxnum != 0"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div style="width:80px;height:80px; position: absolute; bottom: 0;right:0;" v-loading="loading"  element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.8)"></div>
  </div>
</template>

<script>
import { dyParameter,formatDate2 } from "@/common/common.js";
export default {
  name: "Product",
  props: {
    msg: String,
  },
  data() {
    return {
      loading:false,
      tableData: [
        
      ],
      Count:0,
      selectionList: [],
      currentPage: 1,
      pagesize:10,
      title:"新增",
      centerDialogVisible:false,
      form:{
        OwnGuid:'',
        Level:'',//序号
        GroupNo:'',//系列
        Model:'',//型号
        Role:'',//名称
        Remark:'',//特性
        Kind:'设备类型',//设备类型
      },
      rules: {
          Level: [
            { required: true, message: '请输入序号', trigger: 'blur' },
          ],
          GroupNo: [
            { required: true, message: '请输入系列', trigger: ['blur', 'change'], },
          ],
          Model: [
            { required: true, message: '请输入型号', trigger: ['blur', 'change'], },
          ],
          Role: [
            { required: true, message: '请输入名称', trigger: ['blur', 'change'], },
          ],
        },
        disabled: false,
      boxnum: 1,
    };
  },
  created() {},
  mounted() {
    this.getProductList()
  },
  methods: {
    // 获取仪器列表
    getProductList() {
      this.loading = true
      this.$myaxios("post", "/api/main/common/GeneralDynamicPageList", {
        moduleName: "System",
        table: "Table:System_GetAllRoles",
        orderby: "GroupNo",
        pageNumber: this.currentPage,
        rowsPerPage: this.pagesize,
        dynParmRules: [
            {
              op: "like",
              field: "@Kind",
              value: "设备类型",
              type: 'string',
            }
          ],
      }).then((res) => {
        var data = JSON.parse(res.data.Data);
        console.log(data, "11111111111");
        if (res.data.Code == 0) {
          this.tableData = data;
          this.Count = res.data.Count
        } else {
           if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
        }
        this.loading = false
        this.warnNewing(res.data.Message)
      }).catch(() => {this.loading = false; });
    },
    // 多选
    handleSelectionChange(val) {
      this.selectionList = val;
    },
    // 新增
    addUser() {
      this.title = "新增"
      this.boxnum = 1;
      this.disabled = false;
      this.centerDialogVisible = true
    },
    // 修改
    modifyUser(index, row) {
       this.title = "编辑"
       
         this.form.Level = row.Level
         this.form.GroupNo = row.GroupNo
         this.form.Model = row.Model
         this.form.Role =row.Role
         this.form.Remark = row.Remark
         this.form.OwnGuid = row.OwnGuid
         
       this.boxnum = 1;
      this.disabled = false;
      this.centerDialogVisible = true
    },
    // 详情
    openDetail(index, row) {
      this.title = "详情";
      this.form.Level = row.Level
         this.form.GroupNo = row.GroupNo
         this.form.Model = row.Model
         this.form.Role =row.Role
         this.form.Remark = row.Remark
         this.form.OwnGuid = row.OwnGuid
         this.centerDialogVisible = true
      this.disabled = true;
      this.boxnum = 0;
      this.centerDialogVisible = true;
      console.log(row, "11111111111111");
    },
    // 新增、修改保存
    save () {
      this.$refs['form'].validate((valid) => {
          if (valid) {
            let list = ["Level","GroupNo", "Model", "Role", "Remark","Kind","OwnGuid"];
            this.loading = true
            let dynParmRules = dyParameter(this.form, list);
            this.$myaxios(
              "post",
              "/api/main/common/ExecuteSqlTable",
              {
                table: "Table:System_SetRole",
                moduleName: "System",
                dynParmRules: dynParmRules,
              },
              // "formdata"
            ).then((res) => { 
              if(res.data.Code == 0) {
                this.$message({
                  message: "保存成功！",
                  type: "success",
                  duration: 1500,
                });
                this.getProductList()
                this.closed()
              }else {
                 if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
                this.$message({
                  message: res.data.Message,
                  type: "warning",
                  duration: 1500,
                });
              }
              this.loading = false
              this.warnNewing(res.data.Message)
            }).catch(() => {this.loading = false; })
          } else {
            return false;
          }
      })
    },
    // 删除
    deleteUser() {
      console.log(this.selectionList, "8888888888888");
      if (this.selectionList.length <= 0) {
        this.$message({
          message: "请选择要删除的数据！",
          type: "warning",
          duration: 1500,
        });
      } else {
        var IDLIST = "";
        for (var i = 0; i < this.selectionList.length; i++) {
          IDLIST += this.selectionList[i].OwnGuid + ",";
        }
        this.loading = true
        console.log(IDLIST.slice(0, IDLIST.length - 1), "999999999999", IDLIST);
        this.$myaxios(
          "post",
          "/api/main/common/ExecuteSqlTable",
          {
            table: "Table:System_DelDevModel",
            moduleName: "System",
            dynParmRules: [
              {
                field: 'idlist',
                value: IDLIST.slice(0, IDLIST.length - 1),
                type: 'string'
              },
            ]
          },
        ).then((res) => {
          if (res.data.Code == 0) {
            this.getProductList()
            this.$message({
              message: "删除成功",
              type: "success",
            });
          } else {
             if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
            this.$message({
              message: "删除失败",
              type: "warning",
            });
          }
          this.loading = false
          this.warnNewing(res.data.Message)
        }).catch(() => {
          this.loading = false
            this.$message({
              type: "warning",
              message: this.$glg("删除失败"),
            });
        });
      }
    },
    getBack() {
       this.$message({
        message: '该账户已在其他地方登录，请重新登录',
        type: "warning",
        duration: 1500,
      });
      this.$router.push({ path: "/" });
      localStorage.clear();
    },
    closed () {
        this.centerDialogVisible = false
        this.form.Level = ''
        this.form.GroupNo = ''
        this.form.Model = ''
        this.form.Role = ''
        this.form.Remark = ''
        this.form.OwnGuid = ''
    },
   
    // 选择每页多少条
    handleSizeChange(val) {
      this.pagesize = val
      this.getProductList()
    },
     // 选择第几页
    handleCurrentChange(val) {
       this.currentPage = val
      this.getProductList()
    },
    // 时间格式
    timeFormat(row,column) {
      return (row.UpdateDate).substring(0,10)
    },
    warnNewing(value) {
      var NewList = this.$store.state.newList
      var dateTime = new Date();
      var newS = "时间：" + formatDate2(dateTime) + ' 请求信息' + value
      NewList.unshift(newS)
      this.$store.commit("newList",NewList);
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrap {
  // padding: 40px;
  position: relative;
  width: 100%;
  .productButton {
    // height: 50px;
    text-align: right;
  }
  .productList {
    height: calc(100% - 110px);
  }
  .pageSizes {
    height: 35px;
    text-align: center;
    z-index: 1200;
  }
  .el-input,::v-deep .el-select .el-input__inner {
    font-size: 18px;
    color: #333333;
  }
  ::v-deep .el-loading-spinner {
    margin-top: -35px;
  }
}
</style>
