<template>
  <div class="setup wrap">
    <el-row class="weigh_box">
      <el-col :span="6" class="box_left">
        <el-menu
          :default-active="defaultactive"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
          <el-menu-item index="1">
            <span slot="title">打印</span>
            <img  src="@/assets/iconRightMi.png"  alt="" v-if="boxdialogVisible == 1" style="float:right;margin:23px 0"  />
            <img  src="@/assets/iconRightAn.png"  alt="" v-else  style="float:right;margin:23px 0" />
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title">PC通讯</span>
            <img  src="@/assets/iconRightMi.png"  alt="" v-if="boxdialogVisible == 2" style="float:right;margin:23px 0"  />
            <img  src="@/assets/iconRightAn.png"  alt="" v-else  style="float:right;margin:23px 0" />
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="18" class="box-right" v-if="boxdialogVisible == 1">
        <div class="topbox"></div>
        <div class="bottombox">
          <el-tabs type="border-card" v-model="activeName"  @tab-click="handleClick" >
            <el-tab-pane label="参数" name="first">
              <el-row class="weigh_text">
                <el-col :span="8">
                  <label for="">打印机</label><br>
                  <el-select
                      v-model="$store.state.DSBC.P2201"
                      placeholder=""
                      @change="save('inputP2201')"
                    >
                      <el-option
                        v-for="item in optionsP2201"
                        :key="item.name"
                        :label="item.value"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  <!-- <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2201" @blur="save('inputP2201')" @input="open('inputP2201')" ref="inputP2201"> </el-input> -->
                </el-col>
                <el-col  :span="8">
                  <label for="">格式</label><br>
                  <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2202" @blur="save('inputP2202')" @input="open('inputP2202')" ref="inputP2202"> </el-input>
                </el-col>
                <el-col  :span="8">
                  <label for="">联数</label><br>
                  <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2203" @blur="save('inputP2203')" @input="open('inputP2203')" ref="inputP2203"> </el-input>
                </el-col>
                <el-col  :span="8"  class="marbox">
                  <label for="">走纸</label><br>
                  <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2204" @blur="save('inputP2204')" @input="open('inputP2204')" ref="inputP2204"> </el-input>
                </el-col>
                <el-col  :span="8"  class="marbox">
                  <label for="">退纸</label><br>
                  <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2205" @blur="save('inputP2205')" @input="open('inputP2205')" ref="inputP2205"> </el-input>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="选项" name="second">
              <el-row class="weigh_text">
                <el-col :span="8">
                  <label for="">货号</label><br>
                  <el-select v-model="$store.state.DSBC.P2211" placeholder="请选择"  @change="save('inputP2211')">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">
                  <label for="">车号</label><br>
                  <el-select v-model="$store.state.DSBC.P2212" placeholder="请选择"  @change="save('inputP2212')">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">
                  <label for="">单位</label><br>
                  <el-select v-model="$store.state.DSBC.P2213" placeholder="请选择"  @change="save('inputP2213')">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8" class="marbox">
                  <label for="">序号复位</label><br>
                  <el-select v-model="$store.state.DSBC.P2214" placeholder="请选择"  @change="save('inputP2214')">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8"  class="marbox">
                  <label for="">一次过磅</label><br>
                  <el-select v-model="$store.state.DSBC.P2215" placeholder="请选择"  @change="save('inputP2215')">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8"  class="marbox">
                  <label for="">放大</label><br>
                  <el-select v-model="$store.state.DSBC.P2216" placeholder="请选择"  @change="save('inputP2216')">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">
                  <label for="">换页</label><br>
                  <el-select v-model="$store.state.DSBC.P2217" placeholder="请选择"  @change="save('inputP2217')">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">
                  <label for="">自动</label><br>
                  <el-select v-model="$store.state.DSBC.P2218" placeholder="请选择"  @change="save('inputP2218')">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="自定义" name="third">
              <el-row class="weigh_text">
                <el-col :span="8">
                  <label for="">表头长度</label><br>
                  <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2221"  @blur="save('inputP2221')" @input="open('inputP2221')" ref="inputP2221"> </el-input>
                </el-col>
                <el-col :span="8">
                  <label for="">表格长度</label><br>
                  <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2222"  @blur="save('inputP2222')" @input="open('inputP2222')" ref="inputP2222"> </el-input>
                </el-col>
                <el-col :span="8">
                  <label for="">表格宽度</label><br>
                  <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2223"  @blur="save('inputP2223')" @input="open('inputP2223')" ref="inputP2223"> </el-input>
                </el-col>
                <el-col :span="8"  class="marbox">
                  <label for="">表尾长度</label><br>
                  <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2224"  @blur="save('inputP2224')" @input="open('inputP2224')" ref="inputP2224"> </el-input>
                </el-col>
                <el-col :span="8"  class="marbox">
                  <label for="">表格个数</label><br>
                  <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2225"  @blur="save('inputP2225')" @input="open('inputP2225')" ref="inputP2225"> </el-input>
                </el-col>
                <el-col :span="8"  class="marbox">
                  <label for="">左边距</label><br>
                  <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2226"  @blur="save('inputP2226')" @input="open('inputP2226')" ref="inputP2226"> </el-input>
                </el-col>
                <el-col :span="8">
                  <label for="">空行位置</label><br>
                  <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2227"  @blur="save('inputP2227')" @input="open('inputP2227')" ref="inputP2227"> </el-input>
                </el-col>
                <el-col :span="8">
                  <label for="">补偿</label><br>
                  <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2228"  @blur="save('inputP2228')" @input="open('inputP2228')" ref="inputP2228"> </el-input>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <el-col :span="18" class="box-right" v-else-if="boxdialogVisible == 2">
        <div class="topbox"></div>
        <div class="bottombox">
          <el-tabs type="border-card" v-model="active"  @tab-click="handleClick">
            <el-tab-pane label="串口" name="one">
              <el-row class="weigh_text">
                <el-col :span="8">
                  <label for="">波特率</label><br>
                  <el-select
                      v-model="$store.state.DSBC.P2101"
                      placeholder="" 
                      @change="save('inputP2101')"
                    >
                      <el-option
                        v-for="item in optionsP2101"
                        :key="item.name"
                        :label="item.value"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  <!-- <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2101" @blur="save('inputP2102')" @input="open('inputP2102')" ref="inputP2102"> </el-input> -->
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="协议" name="two">
              <el-row class="weigh_text">
                <el-col :span="8">
                  <label for="">方式</label><br>
                  <el-select
                      v-model="$store.state.DSBC.P2102"
                      placeholder="" 
                      @change="save('inputP2102')"
                    >
                      <el-option
                        v-for="item in optionsP2102"
                        :key="item.name"
                        :label="item.value"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  <!-- <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2102" @blur="save('inputP2101')"  @input="open('inputP2101')" ref="inputP2101"> </el-input> -->
                </el-col>
                <el-col :span="8">
                  <label for="">地址</label><br>
                  <el-input style="width: 200px" placeholder="请输入内容" v-model="$store.state.DSBC.P2103" @blur="save('inputP2103')"  @input="open('inputP2103')" ref="inputP2103"> </el-input>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- <el-col style="text-align:right;padding-right:20px;margin-bottom:15px;">
          <el-button type="primary" @click="save">确定</el-button>
        </el-col> -->
      </el-col>
    </el-row>
    <div style="width: 80px; height: 80px; position: absolute; bottom: 0;right:0;" v-loading="loading"  element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.8)"></div>
  </div>
</template>

<script>
import { formatDate,formatDate2 } from "@/common/common.js";
import $ from "jquery";
export default {
  name: "Setup",
  props: {
    msg: String,
  },
  data() {
    return {
      defaultactive: "1",
      loading:false,
      DSBCdata: {},
      boxdialogVisible: 1,
      activeName:'first',
      active:'one',
       options: [{
          value: true,
          label: '是'
        }, {
          value: false,
          label: '否'
        }],
        timer:'',
        optionsP2201: [
        {
          name: 0,
          value: '1121',
        },
        {
          name: 1,
          value: '1131',
        },
        {
          name: 2,
          value: '58',
        },
        {
          name: 3,
          value: 'YIN',
        },
        {
          name: 4,
          value: 'DS',
        },
        {
          name: 5,
          value: '150',
        },
        {
          name: 6,
          value: 'ES',
        },
      ],
      optionsP2102:[
        {
          name: 0,
          value: 'TOOL',
        },
        {
          name: 1,
          value: 'A9',
        },
        {
          name: 2,
          value: 'A9C',
        },
        {
          name: 3,
          value: 'D2A',
        },
        {
          name: 4,
          value: 'D2AN',
        },
        {
          name: 5,
          value: 'TL',
        },
        {
          name: 6,
          value: '8803',
        },
        {
          name: 7,
          value: 'HT',
        },
        {
          name: 8,
          value: 'HS',
        },
        {
          name: 9,
          value: 'HSC',
        },
        {
          name: 10,
          value: 'HSD',
        },
        {
          name: 11,
          value: 'HST',
        },
        {
          name: 12,
          value: 'HS1',
        },
        {
          name: 13,
          value: 'HSF',
        },
      ],
      optionsP2101:[
        {
          name: 0,
          value: '600',
        },
        {
          name: 1,
          value: '1200',
        },
        {
          name: 2,
          value: '2400',
        },
        {
          name: 3,
          value: '4800',
        },
        {
          name: 4,
          value: '9600',
        },
        {
          name: 5,
          value: '19200',
        },
        {
          name: 6,
          value: '38400',
        },
        {
          name: 7,
          value: '57600',
        },
        {
          name: 8,
          value: '115200',
        },
      ]
    };
  },
  created() {
    if (localStorage.getItem("defaultactiveSet") != undefined) {
      this.defaultactive = JSON.parse(localStorage.getItem("defaultactiveSet"));
      this.boxdialogVisible = JSON.parse(localStorage.getItem("defaultactiveSet"));
      if (JSON.parse(localStorage.getItem("defaultactiveSet")) == 1) {
        if (localStorage.getItem("tabNameSet") != undefined) {
          this.activeName = JSON.parse(localStorage.getItem("tabNameSet"));
        }
      } else if (JSON.parse(localStorage.getItem("defaultactiveSet")) == 2) {
        if (localStorage.getItem("tabNameSet") != undefined) {
          this.active = JSON.parse(localStorage.getItem("tabNameSet"));
        }
      }
    }
  },
  mounted() {
    this.DSBCdata = this.$store.state.DSBC;
  },
  methods: {
    warnNewing(value) {
      var NewList = this.$store.state.newList
      var dateTime = new Date();
      var newS = "时间：" + formatDate2(dateTime) + ' 请求信息' + value
      NewList.unshift(newS)
      this.$store.commit("newList",NewList);
    },
    open(val) {
      if(this.$store.state.timer) {
        clearInterval(this.$store.state.timer);
        this.$store.state.timer = null;
      }
      if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null 
        }
      var that = this
      that.timer = setTimeout(function() {
        if(val == 'inputP2201') {
          that.$refs.inputP2201.blur()
          
        }else if(val == 'inputP2202') {
          that.$refs.inputP2202.blur()

        }else if(val == 'inputP2203'){
          if(that.$store.state.DSBC.P2203 >= 0 && that.$store.state.DSBC.P2203 < 4) {
            that.$refs.inputP2203.blur()
          } else {
            that.$message({
                  message: '请输入0-3范围内的数值',
                  type: "warning",
                  duration: 1500,
                });
          }

        }else if(val == 'inputP2204'){
         
          if(that.$store.state.DSBC.P2204 >= 0 && that.$store.state.DSBC.P2204 < 100) {
             that.$refs.inputP2204.blur()
          } else {
            that.$message({
                  message: '请输入0-99范围内的数值',
                  type: "warning",
                  duration: 1500,
                });
          }

        }else if(val == 'inputP2205'){
          
          if(that.$store.state.DSBC.P2205 >= 0 && that.$store.state.DSBC.P2205 < 100) {
             that.$refs.inputP2205.blur()
          } else {
            that.$message({
                  message: '请输入0-99范围内的数值',
                  type: "warning",
                  duration: 1500,
                });
          }

        }else if(val == 'inputP2221'){
          that.$refs.inputP2221.blur()


        }else if(val == 'inputP2222'){
          that.$refs.inputP2222.blur()

        }else if(val == 'inputP2223'){
          that.$refs.inputP2223.blur()

        }else if(val == 'inputP2224'){
          that.$refs.inputP2224.blur()

        }else if(val == 'inputP2225'){
          that.$refs.inputP2225.blur()
      
        }else if(val == 'inputP2226'){
          that.$refs.inputP2226.blur()

        }else if(val == 'inputP2227'){
          that.$refs.inputP2227.blur()

        }else if(val == 'inputP2228'){
          that.$refs.inputP2228.blur()

        }else if(val == 'inputP2101'){
          that.$refs.inputP2101.blur()

        }else if(val == 'inputP2102'){
          that.$refs.inputP2102.blur()

        }else if(val == 'inputP2103'){
          if(that.$store.state.DSBC.P2103 >= 0 && that.$store.state.DSBC.P2103 < 100) {
            that.$refs.inputP2103.blur()
          } else {
            that.$message({
                  message: '请输入0-99范围内的数值',
                  type: "warning",
                  duration: 1500,
                });
          }
          

        }
      },3000)
    },
    handleSelect(key, keyPath) {
      this.boxdialogVisible = key
       localStorage.setItem(
        "defaultactiveSet",
        JSON.stringify(this.boxdialogVisible)
      );
      localStorage.removeItem("tabNameSet")
    },
    handleClick(tab, event) {
         localStorage.setItem("tabNameSet", JSON.stringify(tab.name));
    },
    save (val) {
      var dynParmRules=[]
      if(val == 'inputP2201') {
         dynParmRules = [
              {
                    field: "P2201",
                    value: this.$store.state.DSBC.P2201,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2202') {
         dynParmRules = [
                  {
                    field: "P2202",
                    value: this.$store.state.DSBC.P2202,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2203') {
        if(this.$store.state.DSBC.P2203 >= 0 && this.$store.state.DSBC.P2203 < 4) {
            dynParmRules = [
                  {
                    field: "P2203",
                    value: this.$store.state.DSBC.P2203,
                    type: "int",
                  },
            ]
          } else {
            this.$message({
                  message: '请输入0-3范围内的数值',
                  type: "warning",
                  duration: 1500,
                });
                return;
          }
         
      }else if (val == 'inputP2204') {
        if(this.$store.state.DSBC.P2204 >= 0 && this.$store.state.DSBC.P2204 < 100) {
            dynParmRules = [
                  {
                    field: "P2204",
                    value: this.$store.state.DSBC.P2204,
                    type: "int",
                  },
            ]
          } else {
            this.$message({
                  message: '请输入0-99范围内的数值',
                  type: "warning",
                  duration: 1500,
                });
                return;
          }
         
      }else if (val == 'inputP2205') {
         
            if(this.$store.state.DSBC.P2205 >= 0 && this.$store.state.DSBC.P2205 < 100) {
            dynParmRules = [
                  {
                    field: "P2205",
                    value: this.$store.state.DSBC.P2205,
                    type: "int",
                  },
            ]
          } else {
            this.$message({
                  message: '请输入0-99范围内的数值',
                  type: "warning",
                  duration: 1500,
                });
                return;
          }
      }else if (val == 'inputP2211') {
         dynParmRules = [
                  {
                    field: "P2211",
                    value: this.$store.state.DSBC.P2211,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2212') {
        dynParmRules = [
                  {
                    field: "P2212",
                    value: this.$store.state.DSBC.P2212,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2213') {
        dynParmRules = [
                  {
                    field: "P2213",
                    value: this.$store.state.DSBC.P2213,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2214') {
        dynParmRules = [
                  {
                    field: "P2214",
                    value: this.$store.state.DSBC.P2214,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2215') {
        dynParmRules = [
                  {
                    field: "P2215",
                    value: this.$store.state.DSBC.P2215,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2216') {
        dynParmRules = [
                  {
                    field: "P2216",
                    value: this.$store.state.DSBC.P2216,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2217') {
        dynParmRules = [
                  {
                    field: "P2217",
                    value: this.$store.state.DSBC.P2217,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2218') {
        dynParmRules = [
                  {
                    field: "P2218",
                    value: this.$store.state.DSBC.P2218,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2221') {
          dynParmRules = [
                  {
                    field: "P2221",
                    value: this.$store.state.DSBC.P2221,
                    type: "string",
                  },
            ]
      }else if (val == 'inputP2222') {
         dynParmRules = [
                  {
                    field: "P2222",
                    value: this.$store.state.DSBC.P2222,
                    type: "string",
                  },
            ]
      }else if (val == 'inputP2223') {
         dynParmRules = [
                  {
                    field: "P2223",
                    value: this.$store.state.DSBC.P2223,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2224') {
         dynParmRules = [
                  {
                    field: "P2224",
                    value: this.$store.state.DSBC.P2224,
                    type: "string",
                  },
            ]
      }else if (val == 'inputP2225') {
         dynParmRules = [
                  {
                    field: "P2225",
                    value: this.$store.state.DSBC.P2225,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2226') {
         dynParmRules = [
                  {
                    field: "P2226",
                    value: this.$store.state.DSBC.P2226,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2227') {
         dynParmRules = [
                  {
                    field: "P2227",
                    value: this.$store.state.DSBC.P2227,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2228') {
         dynParmRules = [
                  {
                    field: "P2228",
                    value: this.$store.state.DSBC.P2228,
                    type: "string",
                  },
            ]
      }else if (val == 'inputP2101') {
        dynParmRules = [
                  {
                    field: "P2101",
                    value: this.$store.state.DSBC.P2101,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2102') {
         dynParmRules = [
                  {
                    field: "P2102",
                    value: this.$store.state.DSBC.P2102,
                    type: "int",
                  },
            ]
      }else if (val == 'inputP2103') {
        if(this.$store.state.DSBC.P2103 >= 0 && this.$store.state.DSBC.P2103 < 100) {
            dynParmRules = [
                  {
                    field: "P2103",
                    value: this.$store.state.DSBC.P2103,
                    type: "int",
                  },
            ]
          } else {
            this.$message({
                  message: '请输入0-99范围内的数值',
                  type: "warning",
                  duration: 1500,
                });
                return;
          }
         
      }
        
        this.loading = true
            this.$myaxios(
              "post",
              "/api/main/common/ExecuteSqlTable",
              {
                table: this.$store.state.DSBC.ID ? this.$store.state.DSBC.ID : JSON.parse(localStorage.getItem("currentRow")).ID,
                moduleName: "DownloadPara",
                dynParmRules: dynParmRules
              },
              // "formdata"
            ).then((res) => { 
              if(res.data.Code == 0) {
                if(JSON.parse(localStorage.getItem("loginData")).UserName  == 'admin' || JSON.parse(localStorage.getItem("loginData")).UserName  == 'dpt' || JSON.parse(localStorage.getItem("loginData")).UserName  == 'highsor' || JSON.parse(localStorage.getItem("loginData")).UserName == 'haishuo') {
                  this.$message({
                  message: "保存成功！",
                  type: "success",
                  duration: 1500,
                });
                }
              }else {
                if(res.data.Message == 'TokenError') {
                  this.getBack()
                  return;
                }
                this.$message({
                  message: res.data.Message,
                  type: "warning",
                  duration: 1500,
                });
              }
              this.loading = false
               this.warnNewing(res.data.Message)
            }).catch(() => {this.loading = false; })
    },
  
    getBack() {
       this.$message({
        message: '该账户已在其他地方登录，请重新登录',
        type: "warning",
        duration: 1500,
      });
      this.$router.push({ path: "/" });
      localStorage.clear();
    }
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrap {
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  box-sizing: border-box;
  position: relative;
  background-color: #F4F4F4;
  font-size: 20px;
  overflow-y: scroll;
  .weigh_box {
    height: 100%;
    background-color: #fff;
    
    .box_left {
      height: 100%;
      overflow-y: scroll;
      padding: 0 48px !important;
      border-right: 1px solid #dddddd;
    }
    .box-right {
      height: 100%;
      overflow-y: scroll;
      .topbox {
        height: 59px;
        border-bottom: 1px solid #dddddd;
      }
      .bottombox {
      box-sizing: border-box;
      padding: 40px;
    }
    }
    .weigh_title {
      border-bottom: 1px solid #dddddd;
      padding: 1.5rem 2rem;
    }

    .weigh_text {
      padding: 1rem 2rem;
      vertical-align: bottom;
      p {
        margin: 0 0 10px 0;
      }
      label {
        display: inline-block;
        height: 4rem;
        width: 12rem;
        vertical-align: middle;
      }
    }
    .marbox {
      margin: 10px 0;
    }
  }
  .el-select {
    width: 200px;
    font-size: 20px;
    color: #333333;
  }
  .el-tabs--border-card {
    // border: none;
    box-shadow: none;
  }
  // .magintop {
  //   margin-top: 10px;
  // }
  .el-input,::v-deep .el-select .el-input__inner {
    font-size: 18px;
    color: #333333;
  }
  ::v-deep .el-select .el-input__inner {
    background-color: #ffffff;
  }
  .el-menu-item {
    height: 60px ;
    line-height: 60px;
    font-size: 20px;
    
    border-bottom: 1px solid #dddddd;
  }
 ::v-deep .el-tabs__item {
    width: 136px ;
    text-align: center ;
    font-size: 20px ;
    height: 48px;
    line-height: 48px;
  }
  ::v-deep .el-loading-spinner {
    margin-top: -35px;
  }
}
</style>
