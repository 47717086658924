import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Index from '../views/base/Index.vue'
import User from '../views/base/User.vue'
import Device from '../views/base/Device.vue'
import About from '../views/base/About.vue'
import Material from '../views/base/Material.vue'
import Company from '../views/base/Company.vue'
import Service from '../views/base/Service.vue'
import Register from '../views/base/register.vue'
import Product from '../views/base/Product.vue'
import Debugging from '../views/devices/Debugging.vue'
import Manage from '../views/devices/Manage.vue'
import Query from '../views/devices/Query.vue'
import Setup from '../views/devices/Setup.vue'
import Weigh from '../views/devices/Weigh.vue'
import Pages from '../views/Pages.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/Pages',
    name: 'Pages',
    component: Pages,
    redirect: "Home",
    children: [
      {
        path: '/Home',
        name: 'Home',
        component: Home,
        redirect: "Material",
        children: [
          {
            path: '/Index',
            name: 'Index',
            component: Index,
          },
          {
            path: '/User',
            name: 'User',
            component: User,
          },
          {
            path: '/About',
            name: 'About',
            component: About,
          },
          {
            path: '/Company',
            name: 'Company',
            component: Company,
          },
          {
            path: '/Material',
            name: 'Material',
            component: Material,
          },
          {
            path: '/Manage',
            name: 'Manage',
            component: Manage,
          },
          {
            path: '/Service',
            name: 'Service',
            component: Service,
          },
          {
            path: '/Register',
            name: 'Register',
            component: Register,
          },
          {
            path: '/Product',
            name: 'Product',
            component: Product,
          }

        ]
      },
      {
        path: '/Device',
        name: 'Device',
        component: Device,
        redirect: "/Weigh",
        children: [
          {
            path: '/Debugging',
            name: 'Debugging',
            component: Debugging,
          },
          {
            path: '/Query',
            name: 'Query',
            component: Query,
          },
          {
            path: '/Setup',
            name: 'Setup',
            component: Setup,
          },
          {
            path: '/Weigh',
            name: 'Weigh',
            component: Weigh,
          },
        ]
      },
    ]
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },

]

const router = new VueRouter({
  routes
})

export default router
