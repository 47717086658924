<template>
  <div class="about wrap">
    <el-row class="about_title">
      <el-col>
        版本：{{logindata.Version}}
      </el-col>
      <el-col style="margin-top:15px;">
        界面版本：2022.12.13 22.35
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'About',
  props: {
    msg: String
  },
  data() {
    return {
     logindata:JSON.parse(localStorage.getItem("loginData"))
    };
  },
  created() {

  },
  mounted() {

  },
  methods: {
  
  },
  computed: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrap {
  padding: 1.5rem;
  .about_title {
    padding: 1rem 2rem;
    font-size: 2.4rem;
    text-align: center;
    background-color: #fff;
  }
}
</style>