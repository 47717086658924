export function myaxios(method, url, data, type) {

  let hereAPI = ''

  if (url.substr(0, 1) == '/') { // 使用默认api-main
    hereAPI = ''
  } else { // 使用额外api
    hereAPI = ''
  }

  let loginData = localStorage.getItem('loginData')
  let Token = localStorage.getItem('Token')
  let _loginData = typeof loginData != 'undefined' && loginData != '' && loginData != null ? JSON.parse(loginData) : {}
  // 清除浏览器缓存，返回登录页
  if (loginData == 'undefined' || loginData == '' || loginData == null || _loginData == 'undefined' || _loginData == null || Token == 'undefined' || Token == null || Token == '') {
    this.$message({
      message: '登录信息错误！请重新登录！',
      type: 'warning'
    })
    this.$router.push({ path: '/' })
    return false
  }
  let mag = {
    '+': '%2B',
    ' ': '%20',
    '/': '%2F',
    '?': '%3F',
    '%': '%25',
    '#': '%23',
    '&': '%26',
    '=': '%3D',
  }

  // 判断data传递格式
  if (type === 'formdata') { // formdata
    data = this.$qs.stringify(data)
  } else if (type == 'querystring') { // querystring
    url += '?'
    for (let i in data) {
      data[i] = (typeof data[i] != 'undefined' && typeof data[i] != 'object') ? (data[i] + '').replace(/\+| |\/|\?|%|#|&|=/g, function (val) {
        return mag[val]
      }) : data[i]
      url += i + '=' + data[i] + '&'
    }
    url = url.substr(0, url.length - 1)
  } else { // 默认payload
  }

  return new Promise((resolve, reject) => {
  //   const loading = this.$loading({
  //     lock: true,
  //     text: '加载中...',
  //     spinner: 'el-icon-loading',
  //     background: 'rgba(0, 0, 0, 0.7)'
  // });
    this.$axios({
      method: method,
      url: hereAPI + url,
      data: type != 'querystring' ? data : '',
      headers: { // 额外传递的header值
        'Authorization': Token
      }
    }).then(res => {
      // loading.close();
      resolve(res)
    }).catch(err => {
      // loading.close();
      reject(err)
    })
  })

}
