import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    DSBC:{},//设备信息
    
    CompanyList:[],
    UserList:[],
    UserList2:[],
    ModelList:[],
    DeviceList:[],
    DeviceList2:[],
    timer:'',

    BackPapper: "",
    BaudRates: [],
    ComWays: [],
    Divisions:[],
    GoPapper:"",
    PointNum: "",
    PrintFormats:[],
    Printers: [],
    PrtUnion: "",
    ToZeroSet: "",
    Units:[],
    ZeroSets: [],
    ZeroTrace: [],
    newList:[]
  },
  mutations: {
    DSBC: (state, extra) => {
      state.DSBC = extra
    },
    CompanyList: (state, extra) => {
      state.CompanyList = extra
    },
    UserList: (state, extra) => {
      state.UserList = extra
    },
    UserList2: (state, extra) => {
      state.UserList2 = extra
    },
    ModelList: (state, extra) => {
      state.ModelList = extra
    },
    DeviceList: (state, extra) => {
      state.DeviceList = extra
    },
    DeviceList2: (state, extra) => {
      state.DeviceList2 = extra
    },
    timer: (state, extra) => {
      state.timer = extra
    },

    BackPapper: (state, extra) => {
      state.BackPapper = extra
    },
    BaudRates: (state, extra) => {
      state.BaudRates = extra
    },
    ComWays: (state, extra) => {
      state.ComWays = extra
    },
    GoPapper: (state, extra) => {
      state.GoPapper = extra
    },
    Divisions: (state, extra) => {
      state.Divisions = extra
    },
    PointNum: (state, extra) => {
      state.PointNum = extra
    },
    PrintFormats: (state, extra) => {
      state.PrintFormats = extra
    },
    Printers: (state, extra) => {
      state.Printers = extra
    },
    PrtUnion: (state, extra) => {
      state.PrtUnion = extra
    },
    ToZeroSet: (state, extra) => {
      state.ToZeroSet = extra
    },
    ZeroSets: (state, extra) => {
      state.ZeroSets = extra
    },
    ZeroTrace: (state, extra) => {
      state.ZeroTrace = extra
    },
    Units: (state, extra) => {
      state.Units = extra
    },
    newList: (state, extra) => {
      state.newList = extra
    },
  },
  actions: {

  },
  modules: {
    
  }
})
