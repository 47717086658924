<template>
  <div class="weigh wrap">
    <div class="box">
      <el-row class="weigh_box" style="margin: 0">
        <el-col class="weigh_title">状态</el-col>
        <el-col class="weigh_text">
          <p class="weigh_col weigh_p">
            <span
              class="state"
              style="background-color: #409eff"
              v-if="$store.state.DSBC.P1105 == true"
            >
              <span></span>
            </span>
            <span class="state" v-else>
              <span></span>
            </span>
            <label for="">故障</label>
          </p>
          <p class="weigh_col weigh_p">
            <span
              class="state"
              style="background-color: #409eff"
              v-if="$store.state.DSBC.P1106 == true"
            >
              <span></span>
            </span>
            <span class="state" v-else>
              <span></span>
            </span>
            <label for="">打印</label>
          </p>
          <p class="weigh_col weigh_p">
            <span
              class="state"
              style="background-color: #409eff"
              v-if="$store.state.DSBC.P1103 == true"
            >
              <span></span>
            </span>
            <span class="state" v-else>
              <span></span>
            </span>
            <label for="">毛重</label>
          </p>
          <p class="weigh_col weigh_p">
            <span
              class="state"
              style="background-color: #409eff"
              v-if="$store.state.DSBC.P1104 == true"
            >
              <span></span>
            </span>
            <span class="state" v-else>
              <span></span>
            </span>
            <label for="">净重</label>
          </p>
          <p class="weigh_col weigh_p">
            <span
              class="state"
              style="background-color: #409eff"
              v-if="$store.state.DSBC.P1102 == true"
            >
              <span></span>
            </span>
            <span class="state" v-else>
              <span></span>
            </span>
            <label for="">零位</label>
          </p>
          <p class="weigh_col weigh_p">
            <span
              class="state"
              style="background-color: #409eff"
              v-if="$store.state.DSBC.P1101 == true"
            >
              <span></span>
            </span>
            <span class="state" v-else>
              <span></span>
            </span>
            <label for="">稳定</label>
          </p>
        </el-col>
      </el-row>
      <el-row class="weigh_box">
        <el-col class="weigh_title">重量</el-col>
        <el-col class="weigh_text">
          <p class="weigh_col weigh_div">
            <label for="">毛重</label><br />
            <span>{{ this.$store.state.DSBC.P1111 }}</span>
            <!-- <el-input
            v-model="$store.state.DSBC.P1111"
            disabled
            style="margin-top: 10px"
          >
          </el-input> -->
          </p>
          <p class="weigh_col weigh_div">
            <label for="">皮重</label><br />
            <span>{{ this.$store.state.DSBC.P1112 }}</span>
            <!-- <el-input
            v-model="$store.state.DSBC.P1112"
            disabled
            style="margin-top: 10px"
          >
          </el-input> -->
          </p>
          <p class="weigh_col weigh_div">
            <label for="">净重</label><br />
            <span>{{ this.$store.state.DSBC.P1113 }}</span>
            <!-- <el-input
            v-model="$store.state.DSBC.P1113"
            disabled
            style="margin-top: 10px"
          >
          </el-input> -->
          </p>
          <p class="weigh_col weigh_div" style="padding: 0 0 20px 0px">
            <el-button @click="save(118)">置零</el-button>
            <el-button @click="save(36)">称重</el-button>
          </p>
        </el-col>
      </el-row>

      <el-row class="weigh_box">
        <el-col class="weigh_title">皮重</el-col>
        <el-col class="weigh_text" style="padding: 17px 20px">
          <el-col :span="24" class="weigh_col page_buttons">
            <el-button plain @click="save(117)">去皮</el-button>
            <el-button plain @click="openBox(1)">存皮</el-button>
            <el-button plain @click="openBox(2)">调皮</el-button>
            <el-button plain @click="openBox(3)">设皮</el-button>
            <el-button plain @click="save(0)">清皮</el-button>
            <!-- <span>去皮</span>
          <span>存皮</span>
          <span>调皮</span>
          <span>设皮</span>
          <span>清皮</span> -->
          </el-col>
          <!-- <el-col :span="6" class="weigh_col">
          <label for="">重量</label>
          <el-input
            placeholder="请输入内容"
            v-model="this.$store.state.DSBC.P1114"
            class="input-with-select"
          >
            <el-button slot="append">设皮</el-button>
          </el-input>
        </el-col> -->
        </el-col>
      </el-row>
      <el-row class="weigh_box">
        <el-col class="weigh_title">打印</el-col>
        <el-col class="weigh_text" style="height: 80px">
          <p class="weigh_col weigh_btm" style="padding: 22px 20px 27px 0">
            状态：{{ this.$store.state.DSBC.P1301 }}
          </p>
          <p class="weigh_col weigh_btm">
            车号：<el-input
              placeholder="请输入内容"
              v-model="$store.state.DSBC.P1306"
              style="width: 150px"
              @blur="save()"
              @input="open('inputP1306')"
              ref="inputP1306"
            >
            </el-input>
          </p>
          <p class="weigh_col weigh_btm">
            货号：<el-input
              placeholder="请输入内容"
              v-model="$store.state.DSBC.P1307"
              style="width: 150px"
              @blur="save()"
              @input="open('inputP1307')"
              ref="inputP1307"
            >
            </el-input>
          </p>
          <p class="weigh_col weigh_btm">
            <el-button type="primary" @click="openDa(0)">打印</el-button>
            <el-button @click="openDa(2)">补打</el-button>
            <el-button @click="openDa(3)">货打</el-button>
            <el-button @click="openDa(4)">累打</el-button>
            <el-button @click="openDa(5)">报表</el-button>
          </p>
        </el-col>
      </el-row>

      <el-dialog
        :title="title"
        :visible.sync="centerDialogVisible"
        width="20%"
        center
        @close="closed"
      >
        <div v-if="boxNum == 1">
          <label for="" style="display: inline-block; width: 80px"
            >车号：</label
          >
          <el-input placeholder="请输入内容" v-model="$store.state.DSBC.P1121">
          </el-input>
        </div>
        <div v-if="boxNum == 2">
          <label for="" style="display: inline-block; width: 80px"
            >车号：</label
          >
          <el-input placeholder="请输入内容" v-model="$store.state.DSBC.P1122">
          </el-input>
        </div>
        <div v-if="boxNum == 3">
          <label for="" style="display: inline-block; width: 80px"
            >重量：</label
          >
          <el-input placeholder="请输入内容" v-model="$store.state.DSBC.P1123">
          </el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="save">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :title="titleDa"
        :visible.sync="DialogVisibleDa"
        width="20%"
        center
        @close="closedDa"
      >
        <div v-if="NumDa == 0 || NumDa == 1">
          <p>
            <label for="" style="display: inline-block; width: 80px"
              >车号：</label
            >
            <el-input
              placeholder="请输入内容"
              v-model="$store.state.DSBC.P1306"
            >
            </el-input>
          </p>
          <p style="margin-top: 12px">
            <label for="" style="display: inline-block; width: 80px"
              >货号：</label
            >
            <el-input
              placeholder="请输入内容"
              v-model="$store.state.DSBC.P1307"
            >
            </el-input>
          </p>
        </div>
        <div v-if="NumDa == 3">
          <p>
            <label for="" style="display: inline-block; width: 80px"
              >货号：</label
            >
            <el-input
              placeholder="请输入内容"
              v-model="$store.state.DSBC.P1307"
            >
            </el-input>
          </p>
        </div>
        <div v-if="NumDa == 5">
          <p>
            <label for="" style="display: inline-block; width: 80px"
              >日期：</label
            >
            <el-date-picker
              v-model="$store.state.DSBC.P1304"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
            <!-- <el-input placeholder="请输入内容" v-model="$store.state.DSBC.P1304"> -->
            <!-- </el-input> -->
          </p>
          <p style="margin-top: 12px">
            <label for="" style="display: inline-block; width: 80px"
              >车号：</label
            >
            <el-input
              placeholder="请输入内容"
              v-model="$store.state.DSBC.P1306"
            >
            </el-input>
          </p>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveDa">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div
      style="
        width: 100%;
        height: 40px;
        position: absolute;
        bottom: 0;
        background-color: #f5f5f5;
        line-height: 40px;
        padding: 0 40px;
      "
    >
      <el-col>
        <p style="padding: 0 20px; display: inline-block">
          故障：{{ this.$store.state.DSBC.P1004 }}
        </p>
        <p style="padding: 0 20px; display: inline-block">
          错误：{{ this.$store.state.DSBC.P1005 }}
        </p>
        <p style="padding: 0 20px 0 0; display: inline-block">
          帮助：{{ this.$store.state.DSBC.P1003 }}
        </p>
      </el-col>
    </div>
    <div
      style="width: 80px; height: 80px; position: absolute; bottom: 0;right:0;"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    ></div>
  </div>
</template>

<script>
import { formatDate,formatDate2 } from "@/common/common.js";
import $ from "jquery";
export default {
  name: "Weigh",
  props: {
    msg: String,
  },
  data() {
    return {
      loading: false,
      DSBCdata: {},
      input: "",
      centerDialogVisible: false,
      title: "存皮",
      boxNum: 0,
      inputP1302: "",
      inputP1303: "",
      inputP1114: "",
      timer: "",
      titleDa:'打印',
      DialogVisibleDa:false,
      NumDa:0,
      isTime:null,
    };
  },
  created() {},
  mounted() {
    var that = this
    if(that.$store.state.DSBC.ID) {
       that.save(10080)
    }else {
    that.isTime =  setInterval(function () {
       if(that.$store.state.DSBC.ID) {
         that.save(10080)
         clearInterval(that.isTime)
         that.isTime = null
      }
    }, 1000)
    }
    
  },
  methods: {
    warnNewing(value) {
      var NewList = this.$store.state.newList
      var dateTime = new Date();
      var newS = "时间：" + formatDate2(dateTime) + ' 请求信息' + value
      NewList.unshift(newS)
      this.$store.commit("newList",NewList);
    },
    open(val) {
      console.log(val,'00000000000.................')
      if(this.$store.state.timer) {
        clearInterval(this.$store.state.timer);
        this.$store.state.timer = null;
      }
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      var that = this;

      
    },
    openBox(val) {
      if (val == 1) {
        this.title = "存皮";
        this.boxNum = 1;
      } else if (val == 2) {
        this.title = "调皮";
        this.boxNum = 2;
      } else {
        this.title = "设皮";
        this.boxNum = 3;
      }
      this.centerDialogVisible = true;
    },
    closed() {
      this.centerDialogVisible = false;
    },
    // 打印弹窗
    openDa(val) {
      this.NumDa = val
       if (val == 0) {
        this.titleDa = '打印'
        this.DialogVisibleDa = true
      } else if (val == 1) {
        this.titleDa = '二次'
        this.DialogVisibleDa = true
      } else if (val == 2) {
        this.titleDa = '补打'
         this.getSDA(this.NumDa)
      }else if (val == 3) {
        this.titleDa = '货打'
        this.DialogVisibleDa = true
      }else if (val == 4) {
        this.titleDa = '累打'
         this.getSDA(this.NumDa)
      }else if (val == 5) {
        this.titleDa = '报表'
        this.DialogVisibleDa = true
      }
      
    },
    closedDa() {
      this.DialogVisibleDa = false
    },
    // 保存
    save(val) {
      console.log(this.$store.state.DSBC,'999999999999999')
      var dynParmRules = [];
      if (val == 118 || val == 117 || val == 52 || val == 57  || val == 36) {
        dynParmRules = [
          {
            field: "P1007",
            value: val,
            type: "int",
          },
        ];
      }else if( val == 0) {
        dynParmRules = [
          {
            field: "P1123",
            value: val,
            type: "string",
          },
        ];
      } else if( val == 10080) {
        dynParmRules = [
          {
            field: "P1006",
            value: 0,
            type: "int",
          },
        ];
      } else {
        if (this.boxNum == 1) {
          dynParmRules = [
            {
              field: "P1121",
              value: this.$store.state.DSBC.P1121,
              type: "int",
            },
          ];
        } else if (this.boxNum == 2) {
          dynParmRules = [
            {
              field: "P1122",
              value: this.$store.state.DSBC.P1122,
              type: "int",
            },
          ];
        } else if (this.boxNum == 3) {
          dynParmRules = [
            {
              field: "P1123",
              value: this.$store.state.DSBC.P1123,
              type: "string",
            },
          ];
        } else {
          dynParmRules = [
            {
              field: "P1306",
              value: this.$store.state.DSBC.P1306,
              type: "int",
            },
            {
              field: "P1307",
              value: this.$store.state.DSBC.P1307,
              type: "int",
            },
          ];
        }
      }
      this.loading = true;
      this.$myaxios(
        "post",
        "/api/main/common/ExecuteSqlTable",
        {
          table: this.$store.state.DSBC.ID ? this.$store.state.DSBC.ID : JSON.parse(localStorage.getItem("currentRow")).ID,
          moduleName: "DownloadPara",
          dynParmRules: dynParmRules,
        }
      ).then((res) => {
        if (res.data.Code == 0) {
          if(JSON.parse(localStorage.getItem("loginData")).UserName  == 'admin' || JSON.parse(localStorage.getItem("loginData")).UserName  == 'dpt' || JSON.parse(localStorage.getItem("loginData")).UserName  == 'highsor' || JSON.parse(localStorage.getItem("loginData")).UserName == 'haishuo') {
            this.$message({
            message: "保存成功！",
            type: "success",
            duration: 1500,
          });
          }
          // this.$emit("fathre")
          this.closed();
        } else {
          if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
          this.$message({
            message: res.data.Message,
            type: "warning",
            duration: 1500,
          });
        }
         this.loading = false
          this.warnNewing(res.data.Message)
      }).catch(() => {this.loading = false; });
    },
    // 打印保存
    saveDa() {
      var dynParmRules = [];
        if(this.NumDa == 0 || this.NumDa == 1){
          dynParmRules = [
            {
              field: "P1306",
              value: this.$store.state.DSBC.P1306,
              type: "int",
            },
            {
              field: "P1307",
              value: this.$store.state.DSBC.P1307,
              type: "int",
            },
          ];
        }else if(this.NumDa == 3) {
          dynParmRules = [
            {
              field: "P1307",
              value: this.$store.state.DSBC.P1307,
              type: "int",
            },
          ];
        }else if(this.NumDa == 5) {
          dynParmRules = [
            {
              field: "P1304",
              value: this.$store.state.DSBC.P1304,
              type: "string",
            },
            {
              field: "P1306",
              value: this.$store.state.DSBC.P1306,
              type: "int",
            },
          ];
        }
        this.loading = true;
        this.$myaxios(
          "post",
          "/api/main/common/ExecuteSqlTable",
          {
            table: this.$store.state.DSBC.ID ? this.$store.state.DSBC.ID : JSON.parse(localStorage.getItem("currentRow")).ID,
            moduleName: "DownloadPara",
            dynParmRules: dynParmRules,
          }
        ).then((res) => {
          if (res.data.Code == 0) {
            if(JSON.parse(localStorage.getItem("loginData")).UserName  == 'admin' || JSON.parse(localStorage.getItem("loginData")).UserName  == 'dpt' || JSON.parse(localStorage.getItem("loginData")).UserName  == 'highsor' || JSON.parse(localStorage.getItem("loginData")).UserName == 'haishuo') {
            this.$message({
            message: "保存成功！",
            type: "success",
            duration: 1500,
          });
          }
           
            this.getSDA(this.NumDa)
          } else {
            if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
            this.$message({
              message: res.data.Message,
              type: "warning",
              duration: 1500,
            });
            this.loading = false
             this.warnNewing(res.data.Message)
          }
        }).catch(() => {this.loading = false; });
    }, 
    getSDA(val){
      this.$myaxios(
          "post",
          "/api/main/common/ExecuteSqlTable",
          {
            table: this.$store.state.DSBC.ID ? this.$store.state.DSBC.ID : JSON.parse(localStorage.getItem("currentRow")).ID,
            moduleName: "DownloadPara",
            dynParmRules: [
              {
                field: "P1302",
                value: val,
                type: "int",
              },
            ],
          }
        ).then((res) => {
          this.loading = false
          if (res.data.Code == 0) {
            if(JSON.parse(localStorage.getItem("loginData")).UserName  == 'admin' || JSON.parse(localStorage.getItem("loginData")).UserName  == 'dpt' || JSON.parse(localStorage.getItem("loginData")).UserName  == 'highsor' || JSON.parse(localStorage.getItem("loginData")).UserName == 'haishuo') {
            this.$message({
            message: "保存成功！",
            type: "success",
            duration: 1500,
          });
          }
            this.closedDa();
          } else {
            if(res.data.Message == 'TokenError') {
            this.getBack()
             return;
          }
            this.$message({
              message: res.data.Message,
              type: "warning",
              duration: 1500,
            });
          }
           this.warnNewing(res.data.Message)
        }).catch(() => {this.loading = false; });
    },
    getBack() {
       this.$message({
        message: '该账户已在其他地方登录，请重新登录',
        type: "warning",
        duration: 1500,
      });
      this.$router.push({ path: "/" });
      localStorage.clear();
    }
    
  },
  computed: {},
  beforeDestroy () {
    clearInterval(this.isTime)
         this.isTime = null
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrap {
  width: 100%;

  height: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: #f4f4f4;
  font-size: 20px;
  .box {
    height: calc(100% - 40px);
    overflow-y: scroll;
  }

  .weigh_box {
    background-color: #fff;
    margin-top: 16px;
    .weigh_title {
      border-bottom: 4px solid#F4F4F4;
      padding: 0 44px;
      height: 60px;
      line-height: 60px;
    }
    .weigh_text {
      padding: 0 44px;
      .weigh_col {
        padding: 18px 0;
        vertical-align: bottom;
        label {
          display: inline-block;
          vertical-align: middle;
        }
        .state {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 60%;
          vertical-align: middle;
          margin-right: 12px;
          text-align: center;
          line-height: 10px;
          border: 2px solid #dddddd;
          span {
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 3px;
            background-color: #dddddd;
          }
        }
      }
      .weigh_p {
        display: inline-block;
        margin-right: 126px;
        
      }
      .weigh_p:last-of-type {
        margin-right: 0;
      }
      .weigh_div {
        display: inline-block;
        margin-right: 116px;
        span {
          display: inline-block;
          width: 220px;
          height: 40px;
          padding: 0 15px;
          line-height: 40px;
          border: 1px solid #dcdfe6;
          border-radius: 4px;
          box-sizing: border-box;
          margin-top: 10px;
          background-color: #f5f5f5;
        }
      }
      .weigh_div:last-of-type {
        margin-right: 0;
      }
      .weigh_btm {
        display: inline-block;
        margin-right: 40px;
      }
      .weigh_btm:last-of-type {
        margin-right: 0;
      }

      .page_buttons {
        padding: 10px 20px;
        .el-button {
          margin-right: 130px;
        }
        span {
          display: inline-block;
          padding: 1rem 4rem;
          border: 1px solid #333333;
          margin: 0 4rem 0 0;
          border-radius: 0.5rem;
          background-color: #fff;
        }
        span:first-of-type {
          border-color: #b3d8ff;
          background-color: #ecf5ff;
        }
      }
    }
  }
  .el-input {
    width: 220px;
    font-size: 20px;
    color: #333333;
  }
  ::v-deep .el-loading-spinner {
    margin-top: -35px;
  }

  ::v-deep .el-button {
    width: 96px;
  }
}
</style>
